import { useContext } from "react";
import { MapContext } from "../context/MapContext";

const useMap = () => {
  const [state, setState] = useContext(MapContext);

  // Postcode shapes
  function setPostcodeShapesData(postcodeShapesData) {
    setState(state => ({ ...state, postcodeShapesData: postcodeShapesData }));
  }

  // Cached trip shapes (checks here first to avoid re-downloading)
  function setCachedTripShapesData(cachedTripShapesData) {
    setState(state => ({
      ...state,
      cachedTripShapesData: cachedTripShapesData
    }));
  }

  // same with postcode shapes
  function setCachedPostcodeShapesData(cachedPostcodeShapesData) {
    setState(state => ({
      ...state,
      cachedPostcodeShapesData: cachedPostcodeShapesData
    }));
  }

  function getCachedTripShapesData() {
    return state.cachedTripShapesData;
  }

  function tripShapeIsCached(id) {
    let exists = false;
    if (state.cachedTripShapesData) {
      for (let i = 0; i < state.cachedTripShapesData.length; i++) {
        if (
          state.cachedTripShapesData[i] !== undefined &&
          state.cachedTripShapesData[i].RitId === id
        ) {
          exists = true;
        }
      }
    }
    return exists;
  }

  // Selected Trip Shapes to show
  function setSelectedTripShapes(selectedTripShapes) {
    setState(state => ({
      ...state,
      selectedTripShapes: selectedTripShapes
    }));
  }

  function getSelectedTripShapes() {
    return state.selectedTripShapes;
  }

  function setTripShapeClicked(tripShapeClicked) {
    setState(state => ({
      ...state,
      tripShapeClicked: tripShapeClicked
    }));
  }

  function setPostCodeBlockedTripShape(postCodeBlockedTripShape) {
    setState(state => ({
      ...state,
      postCodeBlockedTripShape: postCodeBlockedTripShape
    }));
  }

  function setMapAction(mapAction) {
    setState(state => ({
      ...state,
      mapAction: mapAction
    }));
  }

  function setTripShapesOnMap(tripShapesOnMap) {
    setState(state => ({
      ...state,
      tripShapesOnMap: tripShapesOnMap
    }));
  }

  function setUnplannedLayerId(Id) {
    setState(state => ({
      ...state,
      unplannedLayerId: Id
    }));
  }

  function setUnplannedTripDetails(data) {
    setState(state => ({
      ...state,
      unplannedTripDetails: data
    }));
  }

  function setModifiedTripDetails(data) {
    setState(state => ({
      ...state,
      modifiedTripDetails: data
    }));
  }

  function setCachedUnplannedShapesData(data) {
    setState(state => ({
      ...state,
      cachedUnplannedShapesData: data
    }));
  }

  function setSelectedPostcodes(postcodeIds) {
    setState(state => ({
      ...state,
      selectedPostcodes: postcodeIds
    }));
  }

  function setPostcodesToUnplan(postcodes) {
    setState(state => ({
      ...state,
      postcodesToUnplan: postcodes
    }));
  }

  function setPostcodesToReplan(postcodes) {
    setState(state => ({
      ...state,
      postcodesToReplan: postcodes
    }));
  }

  function setMapLoading(bool) {
    setState(state => ({
      ...state,
      mapLoading: bool
    }));
  }

  return {
    mapAction: state.mapAction,
    setMapAction,
    mapLoading: state.mapLoading,
    setMapLoading,
    setCachedTripShapesData,
    setPostcodeShapesData,
    postcodeShapesData: state.postcodeShapesData,
    getCachedTripShapesData,
    cachedTripShapesData: state.cachedTripShapesData,
    setCachedPostcodeShapesData,
    cachedPostcodeShapesData: state.cachedPostcodeShapesData,
    tripShapeIsCached,
    getSelectedTripShapes,
    selectedTripShapes: state.selectedTripShapes,
    setSelectedTripShapes,
    tripShapeClicked: state.tripShapeClicked,
    setTripShapeClicked,
    postCodeBlockedTripShape: state.postCodeBlockedTripShape,
    setPostCodeBlockedTripShape,
    tripShapesOnMap: state.tripShapesOnMap,
    setTripShapesOnMap,
    unplannedLayerId: state.unplannedLayerId,
    setUnplannedLayerId,
    unplannedTripDetails: state.unplannedTripDetails,
    setUnplannedTripDetails,
    modifiedTripDetails: state.modifiedTripDetails,
    setModifiedTripDetails,
    cachedUnplannedShapesData: state.cachedUnplannedShapesData,
    setCachedUnplannedShapesData,
    selectedPostcodes: state.selectedPostcodes,
    setSelectedPostcodes,
    postcodesToUnplan: state.postcodesToUnplan,
    setPostcodesToUnplan,
    postcodesToReplan: state.postcodesToReplan,
    setPostcodesToReplan,
    getMapContext: state
  };
};

export default useMap;
