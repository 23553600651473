import React from "react";
import Icons from "../Icons";
import ReactTable from "react-table";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import configSettings from "../../settings";

import {
    canSelectNewRoute,
    clearMultiLayerShapes,
    getPostcodeShapes,
    handleTripshapeCheckbox,
    removeRenderedTripShape,
    showMapLoader,
    updateSelectedTripShapeIds,
} from "../Map/AdminMapController";

var _selectedAll;
var _checkAll = false; // for send request to LDP

class TrapRitTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [],
            loading: true,
            validationMsg: '',
            mapStatus: this.props.mapStatus, //  needed for initial state which is 0 (map off)
            isAllRowsSelected: false,
            pageSize: this.props.pageSize ? this.props.pageSize : 25,
            pageSizeOptions: this.props.pageSizeOptions
                ? this.props.pageSizeOptions
                : [5, 10, 25, 50, 100, 200],
            paginationEnabled: true,
            selected: null,
            selectedRows: [],
            selectedForTripShapes: [],
            blockedTripshapeIdForPostcode: null,
        };
    }

    componentDidMount() {
        this.makeColumns(this.state.mapStatus);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                // selected: [],
                blockedTripshapeIdForPostcode: null,
            });

            if (this.props.data === null) {
                this.deselectCheckboxes();
                this.setState({ loading: true });
                //this.setState({ validationMsg: 'data == []' });
            } else {
                this.setState({ loading: true });
                //this.setState({ validationMsg: 'data != []' });
                this.renderTable(this.props.data);
            }
        }
        if (prevProps.loading !== this.props.loading) {
            if (_selectedAll) {
                // clear select all checkboxes
                this.onSelectAllClick();
            }
        }
        if (prevProps.validationMsg !== this.props.validationMsg) {
            alert('validation message changed');
        }
        if (prevProps.scenarioId !== this.props.scenarioId) {
            //alert('scenario changed from ' + prevProps.scenarioId + ' to ' + this.props.scenarioId);
            this.setState({ validationMsg: 'scenario changed' });
        }

        if (prevProps.mapStatus !== this.props.mapStatus) {
            this.setState({ mapStatus: this.props.mapStatus });
            this.makeColumns(this.props.mapStatus);
        }
    }

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    getRitRowId = (prefix, ritId, varId) => {
        return prefix + '_' + ritId + '_' + varId;
    };

    makeColumns = (mapStatus) => {
        const columns = [
            {
                columns: [
                    {
                        //Header: "S",
                        Header: !_checkAll ? (<VisibilityOnIcon className="fade-in-fast visibility-icon visibility-on" onClick={(e) => { this.toggleSelection(); }} />) : (<VisibilityOffIcon className="fade-in-fast visibility-icon visibility-off" onClick={(e) => { this.toggleSelection(); }} />),
                        //Header: <VisibilityOnIcon className="fade-in-fast visibility-icon visibility-on" onClick={(e) => { this.toggleSelection(); }} />,
                        //Header: _selectedAll ? (<VisibilityOnIcon className="fade-in-fast visibility-icon visibility-on" onClick={(e) => { this.onSelectAllClick(e); }} />) : (<VisibilityOffIcon className="fade-in-fast visibility-icon visibility-off" onClick={(e) => { this.onSelectAllClick(e); }} />),
                        Cell: (row) => !row.original.Var_PostNL ? (null) : (<input checked={row.original.Var_Selected} type="checkbox" defaultChecked={row.original.Var_Selected} defaultValue={row.original.Var_Selected} id={this.getRitRowId('cs', row.original.Id, row.original.Var_Id)} onClick={(event) => this.handleOnBlur(event)} />),
                        width: 50,
                        sortable: false,
                        show: mapStatus === 0,
                    },
                    {
                        Header: "Werktijd",
                        Cell: (row) => !row.original.Var_PostNL ? (null) : (<input type="text" maxLength="4" defaultValue={row.original.Var_Werktijd} id={this.getRitRowId('cwt', row.original.Id, row.original.Var_Id)} className="input-trap-variable" onBlur={(event) => this.handleOnBlur(event)} />),
                        width: 110,
                        show: mapStatus === 0,
                    },
                    {
                        Header: "Laadvolume",
                        Cell: (row) => !row.original.Var_PostNL ? (null) : (<input type="text" maxLength="5" defaultValue={row.original.Var_Laadvolume} id={this.getRitRowId('clv', row.original.Id, row.original.Var_Id)} className="input-trap-variable" onBlur={(event) => this.handleOnBlur(event)} />),
                        width: 110,
                        show: mapStatus === 0,
                    },
                    {
                        Header: "Stops",
                        Cell: (row) => !row.original.Var_PostNL ? (null) : (<input type="text" maxLength="3" inputMode="text" defaultValue={row.original.Var_Stops} id={this.getRitRowId('cst', row.original.Id, row.original.Var_Id)} className="input-trap-variable" onBlur={(event) => this.handleOnBlur(event)} />),
                        width: 110,
                        show: mapStatus === 0,
                    },
                    {
                        Header: "% N. Gebied",
                        Cell: (row) => !row.original.Var_PostNL ? (null) : (<input type="text" maxLength="3" defaultValue={row.original.Var_NieuwGebied} id={this.getRitRowId('cng', row.original.Id, row.original.Var_Id)} className="input-trap-variable" onBlur={(event) => this.handleOnBlur(event)} />),
                        width: 110,
                        show: mapStatus === 0,
                    },
                    {
                        Header: "Electrisch",
                        Cell: (row) => !row.original.Var_PostNL ? (null) : (<input type="checkbox" defaultChecked={row.original.Var_Electrisch} defaultValue={row.original.Var_Electrisch} id={this.getRitRowId('ce', row.original.Id, row.original.Var_Id)} onClick={(event) => this.handleOnBlur(event)} />),
                        width: 80,
                        show: mapStatus === 0,
                    },
                    {
                        Header: "Verwijderen",
                        Cell: (row) => !row.original.Var_PostNL ? (null) : (<input type="checkbox" defaultChecked={row.original.Var_Verwijderbaar} defaultValue={row.original.Var_Verwijderbaar} id={this.getRitRowId('cd', row.original.Id, row.original.Var_Id)} onClick={(event) => this.handleOnBlur(event)} />),
                        width: 80,
                        show: mapStatus === 0,
                    },
                    {
                        Header: _selectedAll ? (<VisibilityOnIcon className="fade-in-fast visibility-icon visibility-on" onClick={(e) => { this.onSelectAllClick(e); }} />) : (<VisibilityOffIcon className="fade-in-fast visibility-icon visibility-off" onClick={(e) => { this.onSelectAllClick(e); }} />),
                        Cell: (row) =>
                            row.original.Route.indexOf("000 Ongepland") > -1 ? (
                                ""
                            ) : (
                                <div onClick={this.stopPropagation}>
                                    {row.original.isChecked ? (
                                        <VisibilityOnIcon
                                            className="fade-in-fast visibility-icon visibility-on"
                                            onClick={(e) => {
                                                this.selectRowCheckbox(e, row);
                                            }}
                                        />
                                    ) : (
                                        <VisibilityOffIcon
                                            className="fade-in-fast visibility-icon visibility-off"
                                            onClick={(e) => {
                                                this.selectRowCheckbox(e, row);
                                            }}
                                        />
                                    )}
                                </div>
                            ),
                        width: 40,
                        sortable: false,
                    },
                    {
                        Header: "Rit",
                        id: "Route",
                        accessor: "Route",
                        width: 145,
                        className: "column-align-left",
                    },
                    {
                        Header: "m3",
                        Cell: (row) =>
                            row.original.Volume_m3,
                        width: 40,
                    },
                    {
                        Header: "TI",
                        id: "Norm",
                        accessor: (d) => d.Norm,
                        width: 50,
                    },
                    {
                        Header: "Real.",
                        id: "Real",
                        accessor: (d) => d.Real,
                        width: 50,
                    },
                    {
                        Header: "",
                        id: "ViewMore",

                        Cell: (row) => (
                            <div
                                className="route-details"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.showTripDetails(e, row.original.Id);
                                }}
                            >
                                <Icons.ViewMore className="p2" />
                            </div>
                        ),
                        width: 30,
                        className: "column-align-left",
                    },
                    {
                        Header: "Id",
                        id: "Id",
                        accessor: (d) => d.Id,
                        width: 50,
                        show: false,
                    },
                    {
                        Header: "isSelected",
                        id: "isSelected",
                        accessor: (d) => d.isSelected,
                        show: false,
                    },
                    {
                        Header: "isChecked",
                        id: "isChecked",
                        accessor: (d) => d.isChecked,
                        show: false,
                    },
                ],
            },
        ];

        this.setState({ columns });
    };

    toggleSelection = () => {
        let tripData = [...this.state.data];

        var trapVariables = [];

        tripData.forEach((trip, index) => {
            trapVariables.push(tripData[index].Var_Id);
            tripData[index].Var_Selected = !_checkAll;
        //    if (tripData[index].Var_Selected) {
        //        trapVariables.push(tripData[index].Var_Id);
        //    }
        });

        _checkAll = !_checkAll;

        var selectionData = { Enable: _checkAll, Ids: trapVariables };

        fetch(`${configSettings.TrapToggleSelectedUrl}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(selectionData),
        })
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ validationMsg: 'Fout bij opslaan. - ' + response.statusText });
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((httpObj) => {
                var result = httpObj.Value;
                this.setState({ validationMsg: result.Text });
            })
            .catch((err) => {
                alert('error: ' + err.toString());
                this.setState({ validationMsg: 'Fout bij opslaan. - ' + err.toString() });
            });

    };

    renderTable = (tripData) => {
        const dataObject = [];

        this.setState({ loading: true });

        tripData.forEach((entry, index) => {
            dataObject.push({
                Id: entry.Id,
                Route: entry.Ritcode + " " + entry.Ritnaam,
                Volume_m3: entry.Volume_m3,
                Norm: entry.Norm,
                Real: entry.Realisatie,
                isSelected: entry.isSelected ? true : false,
                isChecked: entry.isChecked ? true : false,
                Var_Id: entry.Var_Id,
                Var_Werktijd: entry.Var_Werktijd,
                Var_Laadvolume: entry.Var_Laadvolume,
                Var_Stops: entry.Var_Stops,
                Var_NieuwGebied: entry.Var_NieuwGebied,
                Var_Electrisch: entry.Var_Electrisch,
                Var_Verwijderbaar: entry.Var_Verwijderbaar,
                Var_Selected: entry.Var_Selected,
                Var_PostNL: entry.Var_PostNL
            });
        });

        this.setState({
            data: dataObject,
        });

        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    };

    clearSelected = () => {
        this.setState({ selected: null });
    };

    // Called from Admin Map Controller in order
    // to update trip Details after a postcode transfer
    updateModifiedTripDetails = (
        modifiedUnplannedTripDetails,
        modifiedTripDetails
    ) => {
        this.setState({ loading: true });

        let tripData = [...this.state.data];

        // Update unplanned entry
        //tripData[0].Stops = modifiedUnplannedTripDetails.Stops;
        //tripData[0].Parcels = modifiedUnplannedTripDetails.Stuks;
        tripData[0].Norm = modifiedUnplannedTripDetails.Norm;
        tripData[0].Real = modifiedUnplannedTripDetails.Realisatie;

        tripData.forEach((trip, index) => {
            if (trip.Id === modifiedTripDetails.Id) {
                // Update modified trip entry
                //tripData[index].Stops = modifiedTripDetails.Stops;
                //tripData[index].Parcels = modifiedTripDetails.Stuks;
                tripData[index].Norm = modifiedTripDetails.Norm;
                tripData[index].Real = modifiedTripDetails.Realisatie;
            }
        });
    };

    deselectCheckboxes = () => {
        const _data = this.state.data;
        let selectedForTripShapes = this.state.selectedForTripShapes;
        let idChanged;
        let action;

        _data.forEach((entry) => {
            entry.isChecked = false;
            selectedForTripShapes = [];
            idChanged = "deselectedAll";
            action = "deselectedAll";
        });

        this.props.sendSelectedForTripshapes(
            selectedForTripShapes,
            idChanged,
            action
        );
        this.setState({ selectedForTripShapes });
    };

    // select to show region on map from checkbox (trip shape on map)
    selectRowCheckbox = (e, row, rowClicked = false) => {
        const _data = this.state.data;
        let selectedForTripShapes = this.state.selectedForTripShapes;
        // let idClicked;

        // let action;
        // const tripShapesToAdd = [];

        if (rowClicked) {
            // Clicked row not checkbox (eye)
            _data.forEach((entry, index) => {
                if (row.index === index) {
                    // idClicked = entry.Id;
                    // the clicked index
                    if (!row.original.isSelected) {
                        let tripShapeIdExists = false;
                        selectedForTripShapes.forEach((tripShapeId) => {
                            if (tripShapeId === entry.Id) {
                                tripShapeIdExists = true;
                            }
                        });
                        if (!tripShapeIdExists) {
                            selectedForTripShapes.push(entry.Id);
                        }

                        this.setBlockedTripshapeForPostcode(entry.Id);

                        entry.isChecked = true;
                        entry.isSelected = true;
                    }
                } else {
                    entry.isSelected = false;
                }
            });
        } else {
            if (this.state.mapStatus === 1) {
                // Clicked checkbox (eye) not row
                _data.forEach((entry, index) => {
                    if (row.index === index) {
                        // idClicked = entry.Id;
                        // the clicked index
                        if (row.original.isChecked && row.original.isSelected) {
                            this.setBlockedTripshapeForPostcode({});
                        }
                        if (row.original.isChecked) {
                            // action = "remove";
                            entry.isChecked = false;

                            selectedForTripShapes.forEach((tripShapeId, index) => {
                                if (tripShapeId === entry.Id) {
                                    selectedForTripShapes.splice(index, 1);
                                    removeRenderedTripShape(entry.Id);
                                }
                            });
                        } else {
                            let tripShapeIdExists = false;
                            selectedForTripShapes.forEach((tripShapeId) => {
                                if (tripShapeId === entry.Id) {
                                    tripShapeIdExists = true;
                                }
                            });
                            if (!tripShapeIdExists) {
                                selectedForTripShapes.push(entry.Id);
                                handleTripshapeCheckbox(entry.Id);
                            }
                            // action = "add";
                            entry.isChecked = true;
                        }
                    }
                });
            }
        }

        if (this.state.mapStatus === 1) {
            updateSelectedTripShapeIds(selectedForTripShapes);
        }

        this.setState({ data: _data, selectedForTripShapes });
    };

    setBlockedTripshapeForPostcode = (tripShapeId) => {
        this.setState({
            blockedTripshapeIdForPostcode: tripShapeId,
        });
    };

    // select to show all regions on map from checkbox
    onSelectAllClick = () => {
        let selectedForTripShapes = this.state.selectedForTripShapes;

        this.setState({ isAllRowsSelected: !this.state.isAllRowsSelected });

        _selectedAll = !_selectedAll;
        this.makeColumns(this.state.mapStatus);

        const _data = this.state.data;

        _data.forEach((entry) => {
            if (this.state.isAllRowsSelected) {
                entry.isChecked = false;
                selectedForTripShapes = [];
            } else {
                entry.isChecked = true;
                if (entry.Id !== this.state.data[0].Id) {
                    selectedForTripShapes.push(entry.Id);
                }
            }
        });

        if (this.state.mapStatus === 1) {
            updateSelectedTripShapeIds(selectedForTripShapes);
            if (!_selectedAll) {
                clearMultiLayerShapes("TripShapes");
            } else {
                this.props.getAllTripShapes();
            }
            this.setState({ selectedForTripShapes });
        }
    };

    // select row (click row not checkbox) to show postcodes shape on map
    selectRowForPostcodes = (e, row) => {
        if (canSelectNewRoute()) {
            if (row.original.Id !== this.state.blockedTripshapeIdForPostcode) {
                if (this.state.mapStatus === 1) {
                    showMapLoader();
                }
                this.props.getPostcodes(row.original.Id);

                if (this.state.mapStatus === 1) {
                    let unplannedSelected;
                    this.state.data[0].Id === row.original.Id
                        ? (unplannedSelected = true)
                        : (unplannedSelected = false);

                    getPostcodeShapes(
                        row.original.Id,
                        unplannedSelected,
                        this.state.data[0].Id
                    );
                }
                this.setState({
                    selected: row.index,
                });

                this.selectRowCheckbox(e, row, true);
            }
        }
    };

    getPostcodes = (Id) => {
        this.props.getPostcodes(Id);
    };

    allowDrop = (e) => {
        e.preventDefault();
    };

    validateVariableFormValues = (valueList) => {
        var result = false;

        if (valueList[0].wt === 'ok') {
            result = true;
        } else {
            result = false;
        }

        if (result) {
            this.setState({ validationMsg: 'valid' });
        } else {
            this.setState({ validationMsg: 'invalid' });
        }

        return result;
    };

    handleOnBlur = (trapElement) => {
        //console.log(trapElement);
        //console.log(trapElement.target);
        var el = trapElement.target;
        var item = { ritid: null, varid: null, cat: null, val: null };

        //alert(el.id);

        var arr = el.id.split('_');
        var cat = arr[0];
        var ritid = arr[1];
        var varid = arr[2];
        //alert('cat: ' + cat + ', ritId: ' + ritid + ', varId: ' + varid);
        //var cat = genericfunctions.getStartFromIndex(el.id, '_');
        //var id = genericfunctions.getEndFromIndex(el.id, '_');

        item.ritid = ritid;
        item.varid = varid;
        item.cat = cat;
        if (cat === 'ce' || cat === 'cd' || cat === 'cs') {
            if (el.checked) {
                item.val = 'true';
            } else {
                item.val = 'false';
            }
        } else {
            item.val = el.value;
        }

        //let tripData = [...this.state.data];

        // error cannot set individual checkboxes due to checked={row.original.Var_Selected}
        /*
        if (cat === 'cs') {
            tripData.forEach((trip, index) => {
                if (trip.Id === ritid) {
                    tripData[index].Var_Selected = !el.checked;
                }
            });
        }
        */


        var msg = 'onblur - cat: ' + item.cat + ', ritId: ' + item.ritid + ', varId: ' + item.varid + ', val: ' + item.val;
        console.log(msg);

        fetch(`${configSettings.TrapSaveVarInputUrl}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(item),
        })
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ validationMsg: 'Fout bij opslaan. - ' + response.statusText });
                    throw new Error(response.statusText);
                }
            })
            .then(() => {
                this.setState({ validationMsg: 'Variabele opgeslagen. ' + new Date().getDate() });
            })
            .catch((err) => {
                alert('error: ' + err.toString());
                this.setState({ validationMsg: 'Fout bij opslaan. - ' + err.toString() });
            });
    };

    GetStartFromIndex = (value, char) => {
        var indexEnd = value.indexOf(char);
        return value.substring(0, indexEnd);
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <p>{this.state.validationMsg} | {this.state.mapStatus === 1 ? "map on" : "map off"}</p>
                <ReactTable
                    data={this.state.data}
                    columns={this.state.columns}
                    loading={this.state.loading}
                    validationMsg={this.state.validationMsg}
                    loadingText={
                        <div className="grid-custom-loader">
                            <Icons.Loading />
                            <b>Loading</b>
                        </div>
                    }
                    pageSize={this.props.data.length}
                    className="-striped -highlight"
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => this.selectRowForPostcodes(e, rowInfo),
                                id: rowInfo.original.Id,
                                style: {
                                    background:
                                        rowInfo.index === this.state.selected ? "#2196f3" : null,
                                    color: rowInfo.index === this.state.selected ? "white" : null,
                                },
                                className:
                                    rowInfo.index === this.state.selected
                                        ? "selected trip-row"
                                        : "trip-row",

                                onDragOver: (e) => this.allowDrop(e),
                            };
                        } else {
                            return {};
                        }
                    }}
                />
            </form>
        );
    }
}

export default TrapRitTable;
