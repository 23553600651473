import React from "react";
import Icons from "../../components/Icons";

export default function ContractDetailsGridLoader() {
  const arrayLoader = [1, 2, 3];

  return (
    <div className="grid-loader">
      <div className="grid-loader-header flex">
        <div className="grid-skeleton-loader">
          <Icons.Loading />
        </div>
        <div className="skeleton-line cdgrid-field" />
        <div className="skeleton-line cdgrid-field" />
        <div className="skeleton-line cdgrid-field" />
        <div className="skeleton-line cdgrid-field" />
        <div className="skeleton-line cdgrid-field" />
        <div className="skeleton-line cdgrid-field" />
        <div className="skeleton-line cdgrid-field" />
        <div className="skeleton-line cdgrid-field" />
      </div>

      <div className="grid-loader-rows">
        {arrayLoader.map((el, index) => {
          return (
            <div key={index} className="flex">
              <div className="grid-skeleton-loader">
                <Icons.Loading />
              </div>
              <div className="skeleton-row cdgrid-field " />
            </div>
          );
        })}
      </div>
    </div>
  );
}
