// General helper functions
//

import configSettings from "../settings";
import Api from "./http";

const functions = {
  objsArraySortByProp: (array, prop, direction = "ascending") => {
    let sortedArr = [];
    if (direction === "descending") {
      sortedArr = array.sort((a, b) =>
        a[prop] < b[prop] ? 1 : b[prop] < a[prop] ? -1 : 0
      );
    } else {
      sortedArr = array.sort((a, b) =>
        a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0
      );
    }
    return sortedArr;
  },
  getColor: () => {
      const tripShapeColors = [
      // disabled blue colors because of IndicatieInactiviteit
      //"#003fff",
      //"#35A6FF",
      "#49E9FF",
      "#906ac1",
      "#ff6600",
      "#FF9966",
      "#fa00ff",
      "#B7521E",
      "#A97D5D",
      "#990066",
      "#FF2A9C",
      "#ff66ff",
      "#c6e000",
      "#2C6700",
      "#92CD00",
      "#40FF70",
      "#666666",
      "#7E7AE2",
      "#ffff00",
      "#ffc700"
    ];

    const fillColor =
      tripShapeColors[Math.floor(Math.random() * tripShapeColors.length)];

    return fillColor;
  },
  getColorIndicatieInactiviteit: () => {
    return "#0000ff";
  },
  getHtmlCollection: classSelected => {
    const htmlCollection = document.getElementsByClassName(classSelected);
    return htmlCollection;
  },
  getImportStatusText: status => {
    switch (status) {
      case 1:
        return "Klaar om te verwerken";
      case 2:
        return "Wacht op verwerken";
      case 3:
        return "Wacht op verwerken";
      case 4:
        return "Verwerken";
      case 5:
        return "Berekenen";
      case 6:
        return "Gereed en berekend";
      case 9:
        return "Mislukt";
      default:
        return "Onbekende status";
    }
  },
  getVersion: async () => {
    const version = await Api.getText(`${configSettings.VersionUrl}`);

    return version;
  },
  hexToRgb: hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  },

  highlightAdded: () => {
    const htmlCollection = functions.getHtmlCollection("rt-tr-group");
    if (htmlCollection.length) {
      htmlCollection[0].classList.add("highlight-added-row");

      setTimeout(() => {
        htmlCollection[0].classList.remove("highlight-added-row");
      }, 2000);
    }
  },
  highlightEdited: rowIndex => {
    const htmlCollection = functions.getHtmlCollection("rt-tr-group");
    if (htmlCollection.length) {
      htmlCollection[rowIndex].classList.add("highlight-edited-row");

      setTimeout(() => {
        htmlCollection[rowIndex].classList.remove("highlight-edited-row");
      }, 2000);
    }
  },
  highlightDeleted: rowIndex => {
    const htmlCollection = functions.getHtmlCollection("rt-tr-group");
    if (htmlCollection.length) {
      htmlCollection[rowIndex].classList.add("highlight-deleted-row");
    }
  },
  isUserAuthorized: user => {
    let authorized;

    if (user.Email) {
      if (user.Role.RoleName === "TI" || user.Role.RoleName === "Depot") {
        // if TI or Depot user check for Depots List
        if (user.Depots) {
          authorized = true;
        } else {
          authorized = false;
        }
      } else {
        // not TI/Depot user
        authorized = true;
      }
    } else {
      // wrong credentials
      authorized = false;
    }

    return authorized;
  }
};
export default functions;
