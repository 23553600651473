import Api from "../../helpers/http";
import configSettings from "../../settings";
import functions from "../../helpers/functions";
import featureStyles from "./featureStyles";

///////////////////////////////
/*** Initialize OpenLayers ***/
///////////////////////////////

const ol = window.ol;
const proj4 = window.proj4;

///////////////////////////////
/*** Declare Map Variables ***/
///////////////////////////////

const GEODAN_MAPS_NLD_BASE_URL =
    "https://services.geodan.nl/data/geodan/gws/nld";
// const GEODAN_BATCHROUTE = "https://services.geodan.nl/routing/batchroute";

var view = new ol.View({
    center: ol.proj.fromLonLat([4.91142800421774, 52.3837847076631]),
    zoom: 7,
    minZoom: 7,
});

const GEODAN_MAPS_LAYERS = {
    BASE: {
        Topographical: "/topo/tms/1.0.0/topo/EPSG%3A28992/{z}/{x}/{-y}.png",
    },
};

proj4.defs(
    "EPSG:28992",
    "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs"
);
var EPSG_28992 = new ol.proj.Projection({
    code: "EPSG:28992",
    extent: [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999],
    units: "m",
});
ol.proj.addProjection(EPSG_28992);

// init postcode shape layer
var PostcodeShapesVectorLayer;
var PostcodeShapesVectorSource;
var postcodeFeature;

// init unplanned shape layer
var UnplannedShapesVectorLayer;
var UnplannedShapesVectorSource;
var UnplannedFeature;

// init MarkerLayer
var MarkerVectorSource = new ol.source.Vector({});
window.MarkerVectorSource = MarkerVectorSource;
var MarkerVectorLayer = new ol.layer.Vector({
    source: MarkerVectorSource,
});
MarkerVectorLayer.set("name", "MarkerLayer");
MarkerVectorLayer.setZIndex(99);

////////////////
/*** STYLES ***/
////////////////

const selectStyle = new ol.style.Style({
    zIndex: 15,
    stroke: new ol.style.Stroke({
        color: `rgba(${0},${255},${0}, 1)`,
        width: 1,
    }),
    fill: new ol.style.Fill({
        color: `rgba(${0},${255},${0}, 0.2)`,
    }),
});

const postcodeStyle = new ol.style.Style({
    zIndex: 15,
    stroke: new ol.style.Stroke({
        color: `rgba(${255},${0},${0}, 1)`,
        width: 1,
    }),
    fill: new ol.style.Fill({
        color: `rgba(${255},${0},${0}, 0.2)`,
    }),
});

const postcodeInactiveStyle = new ol.style.Style({
    zIndex: 15,
    stroke: new ol.style.Stroke({
        color: `rgba(${0},${0},${255}, 1)`,
        width: 1,
    }),
    fill: new ol.style.Fill({
        color: `rgba(${0},${0},${255}, 0.2)`,
    }),
});

const unplannedStyle = new ol.style.Style({
    zIndex: 15,
    stroke: new ol.style.Stroke({
        color: `rgba(${0},${0},${0}, 1)`,
        width: 1,
    }),
    fill: new ol.style.Fill({
        color: `rgba(${0},${0},${0}, 0.2)`,
    }),
});

const retailerFeatureStyleInactive = new ol.style.Style({
    zIndex: 25,
    stroke: new ol.style.Stroke({
        color: 'rgba(0,0,0, 0)',
        width: 0,
    })
});

const retailerFeatureStyleActive = new ol.style.Style({
    zIndex: 25,
    stroke: new ol.style.Stroke({
        color: 'rgba(0,0,0, 2)',
        width: 1,
    })
});

/////////////////////////
/*** Local Variables ***/
/////////////////////////

let UnplannedShapeRendered = false;
let UnplannedLayerId;
let ActivePostcodeShapeId;
let SelectedTripShapeIds = [];
let RenderedTripShapeIds = [];
let TripShapesCache = [];
let RetailerShapesCache = [];
let selectedFromPostcodeTable = [];
let previousPostcodeShapeId = null;
let currentPostcodeShapeId = null;
let activeTripLayerId;
let activePostcode;
let initialDepot = true;
let servicekey;
let currentMapStyle, previousMapStyle;
let canSelect = true;
let mapIsLoading;
let DepotMarker = {};

let retailerPopupContainer;
let retailerPopupContent;
let retailerPopupExists;
let activeRetailerLayer = null;

/////////////////
/*** Methods ***/
/////////////////

// Initializing Map components variables
export const initMap = async () => {
    UnplannedShapeRendered = false;

    servicekey = await getServiceKey();

    let BaseVectorSource = new ol.source.XYZ({
        projection: EPSG_28992,
        url:
            GEODAN_MAPS_NLD_BASE_URL +
            "/streets/tms/1.0.0/streets/EPSG%3A28992/{z}/{x}/{-y}.png" +
            "?at=s2&servicekey=" +
            servicekey,
    });
    BaseVectorSource.set("name", "Default");

    let BaseVectorSourceSatellite = new ol.source.XYZ({
        projection: EPSG_28992,
        url:
            GEODAN_MAPS_NLD_BASE_URL +
            "/imagery/tms/1.0.0/aerial/EPSG%3A28992/{z}/{x}/{-y}.jpeg" +
            "?at=s2&servicekey=" +
            servicekey,
    });
    BaseVectorSourceSatellite.set("name", "Satellite");

    let BaseLayer = Object.keys(GEODAN_MAPS_LAYERS.BASE).map(function () {
        return new ol.layer.Tile({
            source: BaseVectorSource,
            type: "base",
        });
    });

    window.olmap = new ol.Map({
        target: "map",
        controls: ol.control.defaults().extend([new ol.control.FullScreen()]),
        view: view,
        layers: BaseLayer,
        logo: false,
    });
    window.olmap.updateSize();

    // init postcode shape layer
    PostcodeShapesVectorLayer = new ol.layer.Vector();
    PostcodeShapesVectorSource = new ol.source.Vector();
    postcodeFeature = new ol.Feature();
    PostcodeShapesVectorLayer.setSource(PostcodeShapesVectorSource);
    PostcodeShapesVectorLayer.set("name", "PostcodeShapes");

    // init unplanned shape layer
    UnplannedShapesVectorLayer = new ol.layer.Vector();
    UnplannedShapesVectorSource = new ol.source.Vector();
    UnplannedFeature = new ol.Feature();
    UnplannedShapesVectorLayer.setSource(UnplannedShapesVectorSource);
    UnplannedShapesVectorLayer.set("name", "UnplannedShapes");

    MarkerVectorSource = new ol.source.Vector({});
    window.MarkerVectorSource = MarkerVectorSource;
    MarkerVectorLayer = new ol.layer.Vector({
        source: MarkerVectorSource,
    });
    MarkerVectorLayer.set("name", "MarkerLayer");
    MarkerVectorLayer.setZIndex(99);

    BaseVectorSource = new ol.source.XYZ({});
    BaseVectorSource.set("name", "Default");

    BaseVectorSourceSatellite = new ol.source.XYZ({});
    BaseVectorSourceSatellite.set("name", "Satellite");

    window.olmap.addLayer(UnplannedShapesVectorLayer);
    window.olmap.addLayer(PostcodeShapesVectorLayer);

    window.olmap.addLayer(MarkerVectorLayer);

    const el = document.getElementById("map");
    el.mapData = window.olmap;

    retailerPopupContainer = document.getElementById('retailerInfo');
    retailerPopupContent = document.getElementById('retailerInfoContent');

    setListeners();
};

export const handleDepotCoords = (coords) => {
    //alert('unwanted handleDepotCoords');
    setMapPosition(coords);
};

const setMapPosition = (coords) => {
    // window.olmap.setTarget("map");
    mapIsLoading = true;

    if (coords !== null && initialDepot) {
        // INITIAL DEPOT
        initialDepot = false;

        view.animate(
            { duration: 3000 },
            {
                center: ol.proj.transform(
                    [coords.Lon, coords.Lat],
                    "EPSG:4326",
                    "EPSG:3857"
                ),
            },
            {
                zoom: 10,
            }
        );
    } else if (coords !== null && !initialDepot) {
        // select new depot
        // reset layers and caches

        PostcodeShapesVectorSource.clear();
        UnplannedShapesVectorSource.clear();
        clearMultiLayerShapes("TripShapes");
        clearMultiLayerShapes("RetailerShapes");

        if (window.TripsTableComponent) {
            window.TripsTableComponent.clearSelected();
        }
        RetailerShapesCache = [];
        TripShapesCache = [];
        RenderedTripShapeIds = [];
        previousPostcodeShapeId = null;
        currentPostcodeShapeId = null;
        UnplannedShapeRendered = false;

        view.animate(
            { duration: 3000 },
            {
                center: ol.proj.transform(
                    [coords.Lon, coords.Lat],
                    "EPSG:4326",
                    "EPSG:3857"
                ),
            }
        );

        if (window.olmap.getView().getZoom() !== 10) {
            view.animate(
                { duration: 3000 },
                {
                    zoom: 10,
                }
            );
        }
    }
};

export const resetMap = () => {
    //alert('!! resetMap');
    PostcodeShapesVectorSource.clear();
    UnplannedShapesVectorSource.clear();
    //clearMultiLayerShapes(); // DE15835 replaced with separate calls, because it makes the map disapear permanently
    clearMultiLayerShapes("TripShapes"); // todo:TRAP only if map is present
    clearMultiLayerShapes("RetailerShapes");
    window.TripsTableComponent.clearSelected();
    TripShapesCache = [];
    RenderedTripShapeIds = [];
    previousPostcodeShapeId = null;
    currentPostcodeShapeId = null;
    UnplannedShapeRendered = false;
};

// Change Map Tiles from Map controls (Kaart, Satelite)
export const setMapStyle = async (style) => {
    let layers = window.olmap.getLayers();
    let baseLayer;
    layers.forEach((layer) => {
        if (layer.getProperties().type === "base") {
            baseLayer = layer;
        }
    });

    if (currentMapStyle) {
        previousMapStyle = currentMapStyle;
    }
    currentMapStyle = style;

    if (previousMapStyle !== currentMapStyle) {
        switch (style) {
            case "Default":
                let BaseVectorSource = new ol.source.XYZ({
                    projection: EPSG_28992,
                    url:
                        GEODAN_MAPS_NLD_BASE_URL +
                        "/streets/tms/1.0.0/streets/EPSG%3A28992/{z}/{x}/{-y}.png" +
                        "?at=s2&servicekey=" +
                        servicekey,
                });
                baseLayer.setSource(BaseVectorSource);
                break;
            case "Satellite":
                let BaseVectorSourceSatellite = new ol.source.XYZ({
                    projection: EPSG_28992,
                    url:
                        GEODAN_MAPS_NLD_BASE_URL +
                        "/imagery/tms/1.0.0/aerial/EPSG%3A28992/{z}/{x}/{-y}.jpeg" +
                        "?at=s2&servicekey=" +
                        servicekey,
                });
                baseLayer.setSource(BaseVectorSourceSatellite);
                break;

            default:
                break;
        }
    }
};

export const updateSelectedTripShapeIds = (selectedTripShapeIds) => {
    SelectedTripShapeIds = selectedTripShapeIds;
};

export const handleSelectAllTripShapes = (allTripShapes) => {
    allTripShapes.forEach((tripShape) => {
        if (tripShape.RitId !== currentPostcodeShapeId) {
            if (isTripShapeCached(tripShape.RitId)) {
                renderCachedTripShape(tripShape.RitId);
            } else {
                tripShape.Color = tripShape.IndicatieInactiviteit ? functions.getColorIndicatieInactiviteit() : functions.getColor();
                TripShapesCache.push(tripShape);
                renderCachedTripShape(tripShape.RitId);
            }
        }
    });

    hideMapLoader();
};

export const clearMultiLayerShapes = (layerName) => {
    while (shapeLayerExists(layerName)) {
        const layers = window.olmap.getLayers();
        layers.forEach((layer) => {
            if (layer.get("name") === layerName) {
                window.olmap.removeLayer(layer);
            }
        });
    }
    RenderedTripShapeIds = [];
};

// Checks if Layer is on the Map Object
const shapeLayerExists = (layerName) => {
    let exists = false;
    const layers = window.olmap.getLayers();
    layers.forEach((layer) => {
        if (layer.get("name") === layerName) {
            exists = true;
        }
    });
    return exists;
};

// Trip (Rit) shape endpoint
export const getTripShape = async (shapeId) => {
    const tripShapesData = await Api.get(
        `${configSettings.TripShapesUrl}/${shapeId}`
    );
    if (tripShapesData !== undefined) {
        tripShapesData.Color = tripShapesData.IndicatieInactiviteit ? functions.getColorIndicatieInactiviteit() : functions.getColor();
        TripShapesCache.push(tripShapesData);
        // Render trip shape only if it's row is deselected.
        // Checking it here too because the tripshape is requested
        // when clicking the row as well
        if (tripShapesData.RitId !== currentPostcodeShapeId) {
            renderCachedTripShape(tripShapesData.RitId);
        }
    }
};

const renderCachedTripShape = (tripShapeId) => {
    let shapeArr;
    let fillColor;
    let tripCode;

    // console.log(TripShapesCache);

    RenderedTripShapeIds.push(tripShapeId);

    let TripsShapesVectorSource = new ol.source.Vector();
    let TripsShapesVectorLayer = new ol.layer.Vector({
        source: TripsShapesVectorSource,
    });
    TripsShapesVectorLayer.set("name", "TripShapes");

    for (let i = 0; i < TripShapesCache.length; i++) {
        if (TripShapesCache[i].RitId === tripShapeId) {
            fillColor = TripShapesCache[i].Color;
            shapeArr = TripShapesCache[i].Shape;
            tripCode = TripShapesCache[i].RitCode;
        }
    }

    fillColor = functions.hexToRgb(fillColor);
    TripsShapesVectorLayer.setProperties({ fillColor, tripShapeId, tripCode });

    const r = fillColor.r;
    const g = fillColor.g;
    const b = fillColor.b;

    const tripFeatureStyle = new ol.style.Style({
        zIndex: 15,
        stroke: new ol.style.Stroke({
            color: `rgba(${r},${g},${b}, 1)`,
            width: 1,
        }),
        fill: new ol.style.Fill({
            color: `rgba(${r},${g},${b}, 0.2)`,
        }),
    });


    const featureArr = [];
    for (let j = 0; j < shapeArr.length; j++) {
        let coords = shapeArr[j].Coordinates;
        let points = [];
        for (let k = 0; k < coords.length; k++) {
            let latlng = coords[k];
            points.push(
                ol.proj.transform([latlng.Lng, latlng.Lat], "EPSG:4326", "EPSG:3857")
            );
        }
        let feature = new ol.Feature({
            geometry: new ol.geom.Polygon([points]),
        });
        feature.setStyle(tripFeatureStyle);
        feature.set("geomType", 1); // 1 = standalone shape
        featureArr.push(feature);
    }

    featureArr.sort(function (a, b) {
        return a.get("geomType") - b.get("geomType");
    });

    featureArr.forEach((feature) => {
        TripsShapesVectorSource.addFeature(feature);
    });

    window.olmap.addLayer(TripsShapesVectorLayer);
};

export const handleTripshapeCheckbox = (tripShapeId) => {
    if (isTripShapeCached(tripShapeId)) {
        // Render trip shape only if it's row is deselected.
        if (tripShapeId !== currentPostcodeShapeId) {
            renderCachedTripShape(tripShapeId);
        }
    } else {
        getTripShape(tripShapeId);
    }
};

export const removeRenderedTripShape = (tripShapeId) => {
    const layers = window.olmap.getLayers();

    layers.forEach((layer) => {
        if (layer.get("name") === "TripShapes") {
            if (layer.getProperties().tripShapeId === tripShapeId) {
                window.olmap.removeLayer(layer);
                RenderedTripShapeIds.forEach((renderedTripShapeId, index) => {
                    if (tripShapeId === renderedTripShapeId) {
                        RenderedTripShapeIds.splice(index, 1);
                    }
                });
            }
        }
    });
};

// Check if trip shape exists in cache
const isTripShapeCached = (tripShapeId) => {
    let isCached = false;
    if (TripShapesCache.length) {
        TripShapesCache.forEach((trip) => {
            if (trip.RitId === tripShapeId) {
                isCached = true;
            }
        });
    }
    return isCached;
};

// Check if trip shape is shown on Map
const isTripShapeRendered = (tripShapeId) => {
    let isRendered = false;
    if (RenderedTripShapeIds.length) {
        RenderedTripShapeIds.forEach((renderedTripShapeId) => {
            if (renderedTripShapeId === tripShapeId) {
                isRendered = true;
            }
        });
    }
    return isRendered;
};

// Postcode Shapes (Planned and Unplanned) endpoints
export const getPostcodeShapes = async (
    shapeId,
    isUnplannedId,
    unplannedShapeId
) => {
    canSelect = false;

    if (currentPostcodeShapeId) {
        previousPostcodeShapeId = currentPostcodeShapeId;

        if (previousPostcodeShapeId !== UnplannedLayerId) {
            SelectedTripShapeIds.forEach((tripShapeId) => {
                if (tripShapeId === previousPostcodeShapeId) {
                    renderCachedTripShape(tripShapeId);
                }
            });
        }
    }
    currentPostcodeShapeId = shapeId;
    if (!UnplannedShapeRendered) {
        const unplannedShapeData = await Api.get(
            `${configSettings.PostcodeShapesUrl}/${unplannedShapeId}`
        );
        if (unplannedShapeData.length) {
            renderUnplannedPostcodeShape(unplannedShapeData);
            UnplannedShapeRendered = true;
        }
    }

    if (isUnplannedId) {
        ActivePostcodeShapeId = null;
        PostcodeShapesVectorSource.clear();
    } else {
        if (!isTripShapeCached(shapeId)) {
            getTripShape(shapeId);
        }

        const postcodeShapesData = await Api.get(
            `${configSettings.PostcodeShapesUrl}/${shapeId}`
        );
        if (postcodeShapesData.length) {
            renderPlannedPostcodeShape(postcodeShapesData);
        }
    }
    hideMapLoader();
    canSelect = true;
};

export const renderUnplannedPostcodeShape = (postcodeShape) => {
    UnplannedShapesVectorSource.clear();

    let shapeArr;
    if (postcodeShape.length) {
        for (let i = 0; i < postcodeShape.length; i++) {
            let upData = postcodeShape[i];
            shapeArr = upData.Shape;
            let postCodeDetails = {
                ShapeType: "UnplannedShape",
                PostcodeId: upData.PostcodeId,
                Postcode: upData.Postcode,
                Tripname: upData.RitNaam,
                TripnameOrg: upData.RitNaam_Org,
                Ritcode: upData.RitCode,
                RitcodeOrg: upData.RitCode_Org,
                StreetName: upData.Straatnaam,
                PostcodeTripname: upData.Postcode + " " + upData.RitNaam_Org,
                NumberOfStops: `${upData.Stops}`,
                NumberOfParcels: `${upData.Stuks}`,
                TI: upData.Norm,
                Real: upData.Realisatie,
                Retailer: upData.Retailer,
            };
            for (let j = 0; j < shapeArr.length; j++) {
                let coords = shapeArr[j].Coordinates;
                let points = [];
                for (let k = 0; k < coords.length; k++) {
                    let latlng = coords[k];
                    points.push(
                        ol.proj.transform(
                            [latlng.Lng, latlng.Lat],
                            "EPSG:4326",
                            "EPSG:3857"
                        )
                    );
                }
                UnplannedFeature = new ol.Feature({
                    geometry: new ol.geom.Polygon([points]),
                });
                UnplannedFeature.setStyle(unplannedStyle);
                UnplannedFeature.setProperties({ postCodeDetails });
                UnplannedShapesVectorSource.addFeature(UnplannedFeature);
            }
        }
    }
};

export const renderPlannedPostcodeShape = (postcodeShape) => {
    PostcodeShapesVectorSource.clear();
    clearHighlightedUnplannedShapes();

    if (isTripShapeRendered(postcodeShape[0].RitId)) {
        removeRenderedTripShape(postcodeShape[0].RitId);
    }

    selectedFromPostcodeTable = [];

    ActivePostcodeShapeId = postcodeShape[0].RitId;

    let shapeArr;
    for (let i = 0; i < postcodeShape.length; i++) {
        let pcData = postcodeShape[i];
        shapeArr = pcData.Shape;
        let postCodeDetails = {
            ShapeType: "PostcodeShape",
            StreetName: pcData.Straatnaam,
            Tripname: pcData.RitNaam,
            TripnameOrg: pcData.RitNaam_Org,
            PostcodeId: pcData.PostcodeId,
            Postcode: pcData.Postcode,
            PostcodeTripname: pcData.Postcode + " " + pcData.RitNaam_Org,
            Ritcode: pcData.RitCode,
            NumberOfStops: `${pcData.Stops}`,
            NumberOfParcels: `${pcData.Stuks}`,
            TI: pcData.Norm,
            Real: pcData.Realisatie,
            RitcodeOrg: pcData.RitCode_Org,
            Retailer: pcData.Retailer,
            Pauzetijd: pcData.Pauzetijd, // US139076- added 15-08-2022
            IndicatieInactiviteit: pcData.IndicatieInactiviteit,
        };
        for (let j = 0; j < shapeArr.length; j++) {
            let coords = shapeArr[j].Coordinates;
            let points = [];
            for (let k = 0; k < coords.length; k++) {
                let latlng = coords[k];
                points.push(
                    ol.proj.transform([latlng.Lng, latlng.Lat], "EPSG:4326", "EPSG:3857")
                );
            }
            postcodeFeature = new ol.Feature({
                geometry: new ol.geom.Polygon([points]),
            });
            postcodeFeature.setStyle(postCodeDetails.IndicatieInactiviteit ? postcodeInactiveStyle : postcodeStyle);
            postcodeFeature.setProperties({ postCodeDetails });
            PostcodeShapesVectorSource.addFeature(postcodeFeature);
        }
    }

    PostcodeShapesVectorLayer.set("layerId", postcodeShape[0].RitId);
};

const setListeners = () => {
    // Listen to map changes
    // window.olmap.on("moveend", () => {
    // let _center = window.olmap.getView().getCenter();
    // let _zoom = window.olmap.getView().getZoom();
    // console.log(_zoom, _center);
    // });

    window.olmap.on("click", function (evt) {
        evt.preventDefault();
        window.olmap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            // move shapes to unplanned
            if (layer.get("name") === "PostcodeShapes") {
                const postcodeId = [feature.getProperties().postCodeDetails.PostcodeId];
                unplanPostcodeShapes(postcodeId);
                // move shapes from unplanned to a postcode
            } else if (layer.get("name") === "UnplannedShapes") {
                if (ActivePostcodeShapeId) {
                    const postcodeId = [
                        feature.getProperties().postCodeDetails.PostcodeId,
                    ];
                    planPostcodeShapes(postcodeId);
                    unplannedHighlightedFeatures = [];
                }
            }
        });
    });

    let highlightedLayer;
    let prevHighlightedLayer;
    let layerFeatures;
    let postcodeLayerFeatures = [];
    let unplannedHighlightedFeatures = [];

    window.olmap.on("pointermove", function (evt) {
        if (highlightedLayer) {
            const featuresHighlighted = highlightedLayer.getSource().getFeatures();
            let fillColor = highlightedLayer.getProperties().fillColor;
            const r = fillColor.r;
            const g = fillColor.g;
            const b = fillColor.b;
            featuresHighlighted.forEach((feature) => {
                feature.setStyle(
                    new ol.style.Style({
                        stroke: new ol.style.Stroke({
                            color: `rgba(${r},${g},${b}, 1)`,
                            width: 0.1,
                        }),

                        fill: new ol.style.Fill({
                            color: `rgba(${r},${g},${b}, 0.3)`,
                        }),
                    })
                );
            });

            prevHighlightedLayer = highlightedLayer;
        }

        if (highlightedLayer === null) {
            hideInfoBox();
        }

        highlightedLayer = null;
        layerFeatures = [];
        postcodeLayerFeatures.forEach((feature) => {
            if (feature.get("featureType") !== "Unplanned") {
                if (
                    selectedFromPostcodeTable.indexOf(
                        feature.getProperties().postCodeDetails.PostcodeId
                    ) === -1
                ) {
                    feature.setStyle(feature.getProperties().postCodeDetails.IndicatieInactiviteit ? postcodeInactiveStyle : postcodeStyle);
                }
            }
        });
        postcodeLayerFeatures = [];

        unplannedHighlightedFeatures.forEach((_feature) => {
            _feature.setStyle(unplannedStyle);
        });
        unplannedHighlightedFeatures = [];

        if (retailerPopupExists && activeRetailerLayer) {
            retailerPopupContainer.classList.add('hidden');
            retailerPopupExists = false;
            const retailerShapesFeatures = activeRetailerLayer.getSource().getFeatures();
            retailerShapesFeatures.forEach(_feat => {
                _feat.setStyle(retailerFeatureStyleInactive)
            });
            activeRetailerLayer = null;
        }
        //
        window.olmap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            if (layer.get("name") === "TripShapes") {
                layerFeatures = layer.getSource().getFeatures();
                if (highlightedLayer) {
                    prevHighlightedLayer = highlightedLayer;
                    const featuresHighlighted = prevHighlightedLayer
                        .getSource()
                        .getFeatures();
                    let fillColor = prevHighlightedLayer.getProperties().fillColor;
                    const r = fillColor.r;
                    const g = fillColor.g;
                    const b = fillColor.b;
                    featuresHighlighted.forEach((feature) => {
                        feature.setStyle(
                            new ol.style.Style({
                                stroke: new ol.style.Stroke({
                                    color: `rgba(${r},${g},${b}, 1)`,
                                    width: 0.1,
                                }),

                                fill: new ol.style.Fill({
                                    color: `rgba(${r},${g},${b}, 0.3)`,
                                }),
                            })
                        );
                    });
                }
                highlightedLayer = layer;
                // set style for each feature
                layerFeatures.forEach((feature) => {
                    feature.setStyle(selectStyle);
                });
                displayTripInfo(layer);
            } else if (layer.get("name") === "PostcodeShapes") {
                postcodeLayerFeatures = layer.getSource().getFeatures();
                postcodeLayerFeatures.forEach((selectedFeature) => {
                    if (
                        selectedFromPostcodeTable.indexOf(
                            selectedFeature.getProperties().postCodeDetails.PostcodeId
                        ) === -1
                    ) {
                        selectedFeature.setStyle(selectedFeature.getProperties().postCodeDetails.IndicatieInactiviteit ? postcodeInactiveStyle : postcodeStyle);
                    }
                    if (
                        selectedFeature.getProperties().postCodeDetails.PostcodeId ===
                        feature.getProperties().postCodeDetails.PostcodeId
                    ) {
                        selectedFeature.setStyle(selectStyle);
                    }
                });
                displayPostcodeInfo(feature.getProperties().postCodeDetails);
            } else if (layer.get("name") === "UnplannedShapes") {
                unplannedHighlightedFeatures = layer.getSource().getFeatures();
                let highlightedFeature;
                unplannedHighlightedFeatures.forEach((_feature) => {
                    if (
                        _feature.getProperties().postCodeDetails.PostcodeId ===
                        feature.getProperties().postCodeDetails.PostcodeId
                    ) {
                        _feature.setStyle(selectStyle);
                        highlightedFeature = _feature;
                    }
                });
                displayUnplannedTripInfo(
                    highlightedFeature.getProperties().postCodeDetails
                );
            }

            if (feature && feature.get("featureType") === "RetailerMarker") {
                // hovering over Retailer icons (Retailers Tonen)
                //
                retailerPopupExists = true;
                retailerPopupContainer.classList.remove('hidden');
                retailerPopupContent.innerHTML = feature.get("name");

                const featureId = feature.get("id");

                let layers = window.olmap.getLayers();
                layers.forEach((layer) => {
                    if (layer.getProperties().name === "RetailerShapes") {
                        if (layer.getProperties().retailerId === featureId) {
                            activeRetailerLayer = layer;
                            const retailerShapesFeatures = activeRetailerLayer.getSource().getFeatures();
                            retailerShapesFeatures.forEach(_feat => {
                                _feat.setStyle(retailerFeatureStyleActive);
                            });
                        } else {
                            const inactiveRetailerShapesFeatures = layer.getSource().getFeatures();
                            inactiveRetailerShapesFeatures.forEach(_feat => {
                                _feat.setStyle(retailerFeatureStyleInactive);
                            });
                        }
                    }
                });

            }

        });
    });
};

export const saveUnplannedLayerId = (Id) => {
    UnplannedLayerId = Id;
};

// Update Trip (Rit) shapes and Trips (Rit) table after postcode transfer
const getUpdatedTrips = async (PlannedPostcodeId = ActivePostcodeShapeId) => {
    const modifiedTripShape = await Api.get(
        `${configSettings.TripShapesUrl}/${PlannedPostcodeId}`
    );

    let indexToModify;
    const tempCachedTripShapes = [...TripShapesCache];

    tempCachedTripShapes.forEach((trip, index) => {
        if (trip.RitId === modifiedTripShape.RitId) {
            indexToModify = index;
        }
    });

    if (indexToModify !== undefined) {
        delete tempCachedTripShapes[indexToModify].Shape;
        tempCachedTripShapes[indexToModify].Shape = modifiedTripShape.Shape;
        TripShapesCache = tempCachedTripShapes;
    } else {
        modifiedTripShape.Color = !modifiedTripShape.IndicatieInactiviteit ? functions.getColorIndicatieInactiviteit() : functions.getColor();
        tempCachedTripShapes.push(modifiedTripShape);
        TripShapesCache = tempCachedTripShapes;
    }

    RenderedTripShapeIds.forEach((shapeId) => {
        if (PlannedPostcodeId === shapeId) {
            removeRenderedTripShape(PlannedPostcodeId);
            renderCachedTripShape(PlannedPostcodeId);
        }
    });

    const unplannedTripRegionDetails = await Api.get(
        `${configSettings.TripsTableUrl}/${UnplannedLayerId}`
    );

    const modifiedTripRegionDetails = await Api.get(
        `${configSettings.TripsTableUrl}/${PlannedPostcodeId}`
    );

    // update Trips Table
    window.TripsTableComponent.updateModifiedTripDetails(
        unplannedTripRegionDetails,
        modifiedTripRegionDetails
    );
};

const clearHighlightedUnplannedShapes = () => {
    let unplannedLayer;

    window.olmap.getLayers().forEach(function (layer) {
        if (
            layer.get("name") !== undefined &&
            layer.get("name") === "UnplannedShapes"
        ) {
            unplannedLayer = layer;
        }
    });

    if (unplannedLayer) {
        const unplannedFeatures = unplannedLayer.getSource().getFeatures();
        unplannedFeatures.forEach((feature) => {
            feature.setStyle(unplannedStyle);
        });
    }
};

// highlight postcodes by selecting rows in postcode table
export const selectPostcodeShapes = (selectedPostcodes) => {
    let _postCodeLayer;

    selectedFromPostcodeTable = selectedPostcodes;

    if (ActivePostcodeShapeId) {
        window.olmap.getLayers().forEach(function (layer) {
            if (
                layer.get("name") !== undefined &&
                layer.get("name") === "PostcodeShapes"
            ) {
                _postCodeLayer = layer;
            }
        });
    } else {
        window.olmap.getLayers().forEach(function (layer) {
            if (
                layer.get("name") !== undefined &&
                layer.get("name") === "UnplannedShapes"
            ) {
                _postCodeLayer = layer;
            }
        });
    }

    if (_postCodeLayer) {
        const _postcodeFeatures = _postCodeLayer.getSource().getFeatures();
        _postcodeFeatures.forEach((feature) => {
            if (feature.getStyle().getStroke().getColor() === "rgba(0,255,0, 1)") {
                feature.setStyle(feature.getProperties().postCodeDetails.IndicatieInactiviteit ? postcodeInactiveStyle : postcodeStyle);
            }
        });

        if (selectedPostcodes.length) {
            selectedPostcodes.forEach((postcodeId) => {
                _postcodeFeatures.forEach((feature) => {
                    if (
                        postcodeId === feature.getProperties().postCodeDetails.PostcodeId
                    ) {
                        feature.setStyle(selectStyle);
                    }
                });
            });
        }
    }
};

export const postcodeTransferByDragHandler = (
    fromTripId,
    toTripId,
    postCodeIds
) => {
    // toTripId is not rendered postcode shape just remove features

    if (fromTripId === UnplannedLayerId) {
        planPostcodeShapes(postCodeIds, toTripId);
    } else if (toTripId === UnplannedLayerId) {
        unplanPostcodeShapes(postCodeIds);
    } else {
        console.log("Transfer between planned");
        replanPostcodeShapes(postCodeIds, toTripId);
    }
};

// replan postcodes to another postcode group
const replanPostcodeShapes = (postcodeIdsArr, toTripId) => {
    const postData = {
        postcodeIds: postcodeIdsArr,
        fromRitId: ActivePostcodeShapeId,
        toRitId: toTripId,
    };

    fetch(`${configSettings.PostcodesUrl}`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => {
            const plannedFeatures = PostcodeShapesVectorLayer.getSource().getFeatures();
            if (res.ok) {
                // update postcodes table
                window.TripsTableComponent.getPostcodes(ActivePostcodeShapeId);
                // commented because Norm and Real are different in Postcodes endpoint
                // than in Geodatas endpoint
                // window.PostcodesTableComponent.addTransferedPostcode(postcodeDetails);

                postcodeIdsArr.forEach((postcodeId) => {
                    plannedFeatures.forEach((_feature) => {
                        if (
                            postcodeId === _feature.getProperties().postCodeDetails.PostcodeId
                        ) {
                            _feature.setStyle(postcodeStyle);
                            _feature.set("featureType", "PostcodeShape");
                            PostcodeShapesVectorLayer.getSource().removeFeature(_feature);
                        }
                    });
                });

                getUpdatedTrips(ActivePostcodeShapeId);
                getUpdatedTrips(toTripId);
                return res;
            } else {
                throw Error(`Request rejected with status ${res.status}`);
            }
        })
        .catch(console.error);
};

export const unplanPostcodesAfterDrag = (postcodesToUnplan) => {
    const postcodeSource = PostcodeShapesVectorLayer.getSource();
    const features = postcodeSource.getFeatures();

    const unplannedSource = UnplannedShapesVectorLayer.getSource();

    postcodesToUnplan.forEach((postCode) => {
        features.forEach((feature) => {
            if (postCode === feature.getProperties().postCodeDetails.PostcodeId) {
                postcodeSource.removeFeature(feature);
                feature.setStyle(unplannedStyle);
                const _postCodeDetails = feature.get("postCodeDetails");
                _postCodeDetails.ShapeType = "UnplannedShape";
                feature.set("postCodeDetails", _postCodeDetails);
                unplannedSource.addFeature(feature);
            }
        });
    });

    unplanPostcodeShapes(postcodesToUnplan);
};

export const replanPostcodesAfterDrag = (postcodesToReplan) => {
    const unplannedSource = UnplannedShapesVectorLayer.getSource();
    const features = unplannedSource.getFeatures();

    const postcodeSource = PostcodeShapesVectorLayer.getSource();

    postcodesToReplan.forEach((postCode) => {
        features.forEach((feature) => {
            if (postCode === feature.getProperties().postCodeDetails.PostcodeId) {
                unplannedSource.removeFeature(feature);
                if (ActivePostcodeShapeId) {
                    feature.setStyle(postcodeStyle);
                    const _postCodeDetails = feature.get("postCodeDetails");
                    _postCodeDetails.ShapeType = "PostcodeShape";
                    feature.set("postCodeDetails", _postCodeDetails);
                    postcodeSource.addFeature(feature);
                }
            }
        });
    });
    planPostcodeShapes(postcodesToReplan);
};

// set postcode(s) to unplanned
const unplanPostcodeShapes = (postcodeIdsArr) => {
    let _layerFeatures = PostcodeShapesVectorLayer.getSource().getFeatures();

    const featuresToUnplan = [];
    const postData = {
        postcodeIds: postcodeIdsArr,
        fromRitId: ActivePostcodeShapeId,
        toRitId: UnplannedLayerId,
    };

    fetch(`${configSettings.PostcodesUrl}`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => {
            if (res.ok) {
                // update postcodes table
                window.TripsTableComponent.getPostcodes(ActivePostcodeShapeId);
                // window.PostcodesTableComponent.deleteTransferedPostcode(_postcodeId);

                postcodeIdsArr.forEach((postcodeId) => {
                    _layerFeatures.forEach((_feature) => {
                        if (
                            postcodeId === _feature.getProperties().postCodeDetails.PostcodeId
                        ) {
                            featuresToUnplan.push(_feature);
                            PostcodeShapesVectorLayer.getSource().removeFeature(_feature);
                        }
                    });
                });

                featuresToUnplan.forEach((_feature, index) => {
                    _feature.getProperties().postCodeDetails.Ritcode = "000";
                    _feature.getProperties().postCodeDetails.Tripname = "Ongepland";
                    _feature.getProperties().postCodeDetails.ShapeType = "UnplannedShape";
                    delete _feature.getProperties().postCodeDetails.PostcodeTripname;
                    _feature.set("featureType", "Unplanned");
                    _feature.setStyle(unplannedStyle);
                    UnplannedShapesVectorLayer.getSource().addFeature(_feature);
                });

                getUpdatedTrips();
                return res;
            } else {
                throw Error(`Request rejected with status ${res.status}`);
            }
        })
        .catch(console.error);
};

// set postcode to planned (remove from unplanned)
const planPostcodeShapes = (postcodeIdsArr, toTripId) => {
    const toRitId = ActivePostcodeShapeId ? ActivePostcodeShapeId : toTripId;

    const postData = {
        postcodeIds: postcodeIdsArr,
        fromRitId: UnplannedLayerId,
        toRitId: toRitId,
    };

    fetch(`${configSettings.PostcodesUrl}`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => {
            const unplannedFeatures = UnplannedShapesVectorLayer.getSource().getFeatures();
            if (res.ok) {
                if (ActivePostcodeShapeId) {
                    // update postcodes table
                    window.TripsTableComponent.getPostcodes(toRitId);
                }
                // commented because Norm and Real are different in Postcodes endpoint
                // than in Geodatas endpoint
                // window.PostcodesTableComponent.addTransferedPostcode(postcodeDetails);
                console.log('zipcode loop start', postcodeIdsArr);
                postcodeIdsArr.forEach((postcodeId) => {
                    unplannedFeatures.forEach((_feature) => {
                        if (
                            postcodeId === _feature.getProperties().postCodeDetails.PostcodeId
                        ) {
                            _feature.setStyle(postcodeStyle);
                            _feature.set("featureType", "PostcodeShape");
                            UnplannedShapesVectorLayer.getSource().removeFeature(_feature);
                            if (ActivePostcodeShapeId) {
                                const activePostcodeDetails = PostcodeShapesVectorLayer.getSource()
                                    .getFeatures()[0]
                                    .getProperties().postCodeDetails;

                                _feature.set("featureType", "Postcode");
                                _feature.getProperties().postCodeDetails.Ritcode =
                                    activePostcodeDetails.Ritcode;
                                _feature.getProperties().postCodeDetails.Tripname =
                                    activePostcodeDetails.Tripname;
                                _feature.getProperties().postCodeDetails.ShapeType =
                                    "PostcodeShape";

                                PostcodeShapesVectorLayer.getSource().addFeature(_feature);
                            }
                        }
                    });
                });

                getUpdatedTrips(toRitId);
                return res;
            } else {
                throw Error(`Request rejected with status ${res.status}`);
            }
        })
        .catch(console.error);
};

const showPostcodeInfo = () => {
    showInfoBox();

    const postcodeInfoBoxContainer = document.getElementById(
        "postcodeInfoBoxContainer"
    );
    if (postcodeInfoBoxContainer.classList.contains("hidden")) {
        postcodeInfoBoxContainer.classList.remove("hidden");
    }

    const tripInfoBoxContainer = document.getElementById("tripInfoBoxContainer");
    if (!tripInfoBoxContainer.classList.contains("hidden")) {
        tripInfoBoxContainer.classList.add("hidden");
    }
    const unplannedInfoBoxContainer = document.getElementById(
        "unplannedInfoBoxContainer"
    );
    if (!unplannedInfoBoxContainer.classList.contains("hidden")) {
        unplannedInfoBoxContainer.classList.add("hidden");
    }
};

const showUnplannedPostcodeInfo = () => {
    showInfoBox();

    const unplannedInfoBoxContainer = document.getElementById(
        "unplannedInfoBoxContainer"
    );
    if (unplannedInfoBoxContainer.classList.contains("hidden")) {
        unplannedInfoBoxContainer.classList.remove("hidden");
    }

    const tripInfoBoxContainer = document.getElementById("tripInfoBoxContainer");
    if (!tripInfoBoxContainer.classList.contains("hidden")) {
        tripInfoBoxContainer.classList.add("hidden");
    }
    const postcodeInfoBoxContainer = document.getElementById(
        "postcodeInfoBoxContainer"
    );
    if (!postcodeInfoBoxContainer.classList.contains("hidden")) {
        postcodeInfoBoxContainer.classList.add("hidden");
    }
};

const showTripInfo = () => {
    showInfoBox();

    const tripInfoBoxContainer = document.getElementById("tripInfoBoxContainer");
    if (tripInfoBoxContainer.classList.contains("hidden")) {
        tripInfoBoxContainer.classList.remove("hidden");
    }

    const postcodeInfoBoxContainer = document.getElementById(
        "postcodeInfoBoxContainer"
    );
    if (!postcodeInfoBoxContainer.classList.contains("hidden")) {
        postcodeInfoBoxContainer.classList.add("hidden");
    }
    const unplannedInfoBoxContainer = document.getElementById(
        "unplannedInfoBoxContainer"
    );
    if (!unplannedInfoBoxContainer.classList.contains("hidden")) {
        unplannedInfoBoxContainer.classList.add("hidden");
    }
};

const showInfoBox = () => {
    const tripInfoElement = document.getElementById("tripInfoBox");
    if (tripInfoElement.classList.contains("hidden")) {
        tripInfoElement.classList.remove("hidden");
    }
};

const hideInfoBox = () => {
    const tripInfoElement = document.getElementById("tripInfoBox");
    if (!tripInfoElement.classList.contains("hidden")) {
        tripInfoElement.classList.add("hidden");
    }
};

const displayPostcodeInfo = (data) => {
    let currentPostcode = data.Postcode;
    activeTripLayerId = null;
    showPostcodeInfo();

    if (activePostcode !== currentPostcode) {
        if (data.Postcode) {
            document.getElementById("postcodeInfoBoxPostcode").innerHTML =
                data.Postcode;
        }
        if (data.Tripname) {
            document.getElementById("postcodeInfoBoxRitnaam").innerHTML =
                data.Ritcode + " " + data.Tripname;
        }
        if (data.TripnameOrg) {
            document.getElementById("postcodeInfoBoxRitnaamOrg").innerHTML =
                data.RitcodeOrg + " " + data.TripnameOrg;
        }
        if (data.StreetName) {
            document.getElementById("postcodeInfoBoxStreetName").innerHTML =
                data.StreetName;
        }
        if (data.NumberOfStops) {
            document.getElementById("postcodeInfoBoxStops").innerHTML =
                data.NumberOfStops;
        }
        if (data.NumberOfParcels) {
            document.getElementById("postcodeInfoBoxParcels").innerHTML =
                data.NumberOfParcels;
        }
        if (data.TI) {
            document.getElementById("postcodeInfoBoxTI").innerHTML = data.TI;
        }
        if (data.Real) {
            document.getElementById("postcodeInfoBoxReal").innerHTML = data.Real;
        }
        if (data.Retailer) {
            document.getElementById("postcodeInfoBoxRetailer").innerHTML =
                data.Retailer;
        }
        if (data.Pauzetijd) {
            document.getElementById("postcodeInfoBoxPauzetijd").innerHTML = data.Pauzetijd;
        }

        activePostcode = currentPostcode;
    }
};

const displayTripInfo = (layer) => {
    let Id = layer.getProperties().tripShapeId;
    const _activeTripLayerId = activeTripLayerId;
    activePostcode = null;
    showTripInfo();

    if (Id !== _activeTripLayerId) {
        TripShapesCache.forEach((tripShape) => {
            if (Id === tripShape.RitId) {
                activeTripLayerId = Id;

                if (tripShape.RitCode) {
                    document.getElementById("tripInfoBoxTripname").innerHTML =
                        tripShape.RitCode + " " + tripShape.RitNaam;
                }
                if (tripShape.Type) {
                    document.getElementById("tripInfoBoxType").innerHTML = tripShape.Type;
                }
                if (tripShape.Uitvoerder) {
                    document.getElementById("tripInfoBoxDistributor").innerHTML =
                        tripShape.Uitvoerder;
                }
                if (tripShape.Stops) {
                    document.getElementById(
                        "tripInfoBoxStops"
                    ).innerHTML = tripShape.Stops.toFixed(1);
                }
                if (tripShape.Stuks) {
                    document.getElementById(
                        "tripInfoBoxParcels"
                    ).innerHTML = tripShape.Stuks.toFixed(1);
                }
                if (tripShape.Norm) {
                    document.getElementById("tripInfoBoxTI").innerHTML = tripShape.Norm;
                }
                if (tripShape.Realisatie) {
                    document.getElementById("tripInfoBoxReal").innerHTML =
                        tripShape.Realisatie;
                }
            }
        });
    }
};

const displayUnplannedTripInfo = (data) => {
    let currentPostcode = data.Postcode;
    activeTripLayerId = null;
    showUnplannedPostcodeInfo();
    // setShowInfoBox(true);

    if (activePostcode !== currentPostcode) {
        // setInfoBoxData(data);
        if (data.Postcode) {
            document.getElementById("unplannedInfoBoxPostcode").innerHTML =
                data.Postcode;
        }
        if (data.Tripname) {
            document.getElementById("unplannedInfoBoxRitnaam").innerHTML =
                data.Ritcode + " " + data.Tripname;
        }
        if (data.TripnameOrg) {
            document.getElementById("unplannedInfoBoxRitnaamOrg").innerHTML =
                data.RitcodeOrg + " " + data.TripnameOrg;
        }
        if (data.StreetName) {
            document.getElementById("unplannedInfoBoxStreetName").innerHTML =
                data.StreetName;
        }
        if (data.NumberOfStops) {
            document.getElementById("unplannedInfoBoxStops").innerHTML =
                data.NumberOfStops;
        }
        if (data.NumberOfParcels) {
            document.getElementById("unplannedInfoBoxParcels").innerHTML =
                data.NumberOfParcels;
        }
        if (data.TI) {
            document.getElementById("unplannedInfoBoxTI").innerHTML = data.TI;
        }
        if (data.Real) {
            document.getElementById("unplannedInfoBoxReal").innerHTML = data.Real;
        }
        if (data.Retailer) {
            document.getElementById("unplannedInfoBoxRetailer").innerHTML =
                data.Retailer;
        }

        activePostcode = null;
    }
};

export const canSelectNewRoute = () => {
    return canSelect;
};

export const addDepotMarker = (lon, lat, id) => {
    window.MarkerVectorSource.clear();

    DepotMarker = { lon, lat, id };

    var styles = featureStyles.depotMarkerStyle();
    var iconGeometry = new ol.geom.Point(
        ol.proj.transform([lon, lat], "EPSG:4326", "EPSG:3857")
    );

    var iconFeature = new ol.Feature({
        type: "icon",
        geometry: iconGeometry,
    });

    iconFeature.id = id;
    iconFeature.lon = lon;
    iconFeature.lat = lat;

    iconFeature.setStyle(styles);
    window.MarkerVectorSource.addFeature(iconFeature);
    mapIsLoading = false;
};

export const addRetailerMarker = (lon, lat, id, name) => {

    var styles = featureStyles.retailerMarkerStyle();
    var iconGeometry = new ol.geom.Point(
        ol.proj.transform([lon, lat], "EPSG:4326", "EPSG:3857")
    );

    var iconFeature = new ol.Feature({
        type: "icon",
        geometry: iconGeometry,
        name: name
    });

    iconFeature.lon = lon;
    iconFeature.lat = lat;
    iconFeature.set("id", id);
    iconFeature.set("name", name);
    iconFeature.set("featureType", "RetailerMarker");

    iconFeature.setStyle(styles);
    window.MarkerVectorSource.addFeature(iconFeature);
    mapIsLoading = false;
};

const getServiceKey = async () => {
    const serviceKey = await Api.getText(`${configSettings.ServiceKeyUrl}`);
    return serviceKey;
};

export const showMapLoader = () => {
    mapIsLoading = true;
    const loadingElem = document.getElementById("mapLoadingOverlay");
    loadingElem.classList.remove("hidden");
};
export const hideMapLoader = () => {
    mapIsLoading = false;
    const loadingElem = document.getElementById("mapLoadingOverlay");
    loadingElem.classList.add("hidden");
};

export const markRetailers = async (depotId) => {
    if (!RetailerShapesCache.length) {
        showMapLoader();
        const retailersData = await Api.get(
            `${configSettings.RetailerLocations}/${depotId}`
        );


        retailersData.forEach((retailer, index) => {
            addRetailerMarker(retailer.Longitude, retailer.Latitude, retailer.Id, retailer.Naam);
            // save retailers to a cache
            if (RetailerShapesCache.length < retailersData.length) {
                // inactivity color does not apply for retailer
                retailer.Color = functions.getColor();
                RetailerShapesCache[index] = retailer;
            }
        });
    } else {
        RetailerShapesCache.forEach((retailer) => {
            addRetailerMarker(retailer.Longitude, retailer.Latitude, retailer.Id);
        });
    }

    setMultipleLayersVisible("RetailerShapes", true);

    RetailerShapesCache.forEach((retailer) => {
        renderRetailerShape(retailer);
    });

    setTimeout(() => {
        hideMapLoader();
    }, 500);
};

export const removeRetailerMarkers = () => {
    // show non retailer layers
    PostcodeShapesVectorLayer.setVisible(true);
    UnplannedShapesVectorLayer.setVisible(true);
    setMultipleLayersVisible("TripShapes", true);
    setMultipleLayersVisible("RetailerShapes", false);

    addDepotMarker(DepotMarker.lon, DepotMarker.lat, DepotMarker.id);
};

const setMultipleLayersVisible = (layerName, visible) => {
    const layers = window.olmap.getLayers();

    layers.forEach((layer) => {
        if (layer.get("name") === layerName) {
            layer.setVisible(visible);
        }
    });
};

const renderRetailerShape = (retailer) => {
    let shapeArr;
    let fillColor;

    let RetailersShapesVectorSource = new ol.source.Vector();
    let RetailersShapesVectorLayer = new ol.layer.Vector({
        source: RetailersShapesVectorSource,
    });
    RetailersShapesVectorLayer.set("name", "RetailerShapes");

    for (let i = 0; i < RetailerShapesCache.length; i++) {
        if (RetailerShapesCache[i].Id === retailer.Id) {
            fillColor = RetailerShapesCache[i].Color;
            shapeArr = RetailerShapesCache[i].Shape;
        }
    }

    fillColor = functions.hexToRgb(fillColor);
    RetailersShapesVectorLayer.setProperties({
        fillColor,
        retailerId: retailer.Id,
    });

    const retailerFeatureStyle = retailerFeatureStyleInactive;
    const allCoords = [];

    for (let j = 0; j < shapeArr.length; j++) {
        let coords = shapeArr[j].Coordinates;
        var points = [];

        for (let k = 0; k < coords.length; k++) {
            let latlng = coords[k];
            points.push(
                ol.proj.transform([latlng.Lng, latlng.Lat], "EPSG:4326", "EPSG:3857")
            );
        }

        allCoords.push(points);
    }

    let geometryObjects = [];
    let usedIndexes = [];

    for (let i = 0; i < allCoords.length; i++) {
        const geometry = new ol.geom.Polygon([allCoords[i]]);

        for (let j = 0; j < allCoords.length; j++) {
            if (j !== i) {
                // check the intersecting coordinates and setProperties for the Polygons
                const intersectCheckGeom = new ol.geom.Polygon([allCoords[j]]);
                const firstCoord = intersectCheckGeom.getFirstCoordinate();

                if (geometry.intersectsCoordinate(firstCoord)) {
                    intersectCheckGeom.setProperties({
                        Type: "InnerShape",
                        ChildOf: i,
                        index: j,
                    });
                    geometryObjects.push(intersectCheckGeom);
                    if (!usedIndexes.includes(j)) {
                        usedIndexes.push(j);
                    }

                    if (!usedIndexes.includes(i)) {
                        usedIndexes.push(i);
                        geometry.setProperties({
                            Type: "OuterShape",
                            Parent: i,
                            index: i,
                        });
                        geometryObjects.push(geometry);
                    }
                }
            }
        }
    }

    // declare non intersecting geometries as 'StandaloneShape'
    if (geometryObjects.length === 0) {
        for (let i = 0; i < allCoords.length; i++) {
            const geometry = new ol.geom.Polygon([allCoords[i]]);
            geometry.setProperties({
                Type: "StandaloneShape",
            });
            geometryObjects.push(geometry);
        }
    } else {
        if (geometryObjects.length < allCoords.length) {
            for (let i = 0; i < allCoords.length; i++) {
                if (usedIndexes.includes(i)) {
                    //
                } else {
                    const geometry = new ol.geom.Polygon([allCoords[i]]);
                    geometry.setProperties({
                        Type: "StandaloneShape",
                    });
                    geometryObjects.push(geometry);
                }
            }
        }
    }

    const featureArr = [];

    // create features
    geometryObjects.forEach((geometry) => {
        if (geometry.getProperties().Type === "StandaloneShape") {
            let feature = new ol.Feature({
                geometry: geometry,
            });
            feature.setStyle(retailerFeatureStyle);
            feature.set("geomType", 1); // 1 = standalone shape
            featureArr.push(feature);
        } else if (geometry.getProperties().Type === "OuterShape") {
            // if shape has InnerShapes append them to it's geometry
            geometryObjects.forEach((geometryInner) => {
                if (geometryInner.getProperties().Type === "InnerShape") {
                    if (
                        geometry.getProperties().Parent ===
                        geometryInner.getProperties().ChildOf
                    ) {
                        const aHole = geometryInner.getLinearRing();
                        geometry.appendLinearRing(aHole);
                    }
                }
            });
            let feature = new ol.Feature({
                geometry: geometry,
            });
            feature.setStyle(retailerFeatureStyle);
            feature.set("geomType", 2); // 2 = outershape
            featureArr.push(feature);
        }
    });

    featureArr.sort(function (a, b) {
        return a.get("geomType") - b.get("geomType");
    });

    featureArr.forEach((feature) => {
        RetailersShapesVectorSource.addFeature(feature);
    });

    window.olmap.addLayer(RetailersShapesVectorLayer);
};

export const isMapLoading = () => mapIsLoading;
