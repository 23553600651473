import React, { useState, useEffect } from "react";
import ConfirmationDialog from "../../components/Utils/ConfirmationDialog";
import Api from "../../helpers/http";
import configSettings from "../../settings";
import { saveAs } from "file-saver";
import ErrorSnackbar from "../../components/Utils/ErrorSnackbar";
import LoadingModal from "../../components/Modals/LoadingModal";
import { withRouter } from "react-router-dom";

const ExportFinish = props => {
  const exportLoaderText = "Bezig met exporteren...";

  const [ConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [LoadingText, setLoadingText] = useState("");
  const [AanmaakdatumTiRequest, setAanmaakdatumTiRequest] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");

  useEffect(() => {
      setAanmaakdatumTiRequest(new Date(props.aanmaakdatumTiRequest));
  }, []);

  const Finish = async () => {
    let resp;
    setConfirmationDialogOpen(false);

    await fetch(`${configSettings.Finish}/${props.contractId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(results => results.text())
      .then(data => {
        console.log(typeof data, data);
        if (data === "true") {
          resp = true;
        }
      })
      .catch(error => {
        console.log(error);
      });

    if (resp) {
      props.history.push("/subcontractors-overzicht/");
    } else {
      setErrorSnackbarMsg("Het contract kan niet worden afgesloten");
      setErrorSnackbarOpen(true);
    }
  };

  const getExport = async url => {
    setLoadingText(exportLoaderText);
    setShowLoadingModal(true);

    const response = await Api.getFile(url);

    setShowLoadingModal(false);

    if (response === "Error") {
      setErrorSnackbarMsg("Er is een fout opgetreden bij het exporteren");
      setErrorSnackbarOpen(true);
    } else {
      const { blob, filename } = response;
      saveAs(blob, filename);
    }
  };

  return (
    <div id="exportFinishSection">
      <div id="exportFinishContainer" className="flex">
        <div id="exportContainer">
          <p>
            Aanmaakdatum tariefindicatie aanvraag : {
              new Intl.DateTimeFormat("nl-NL", {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            }).format(AanmaakdatumTiRequest)}          
          </p>
          <div className="flex">
            <button
              onClick={() =>
                getExport(`${configSettings.ExportInterneVerantwoording}/${props.contractId}`)
              }
              className="export-finish-button"
            >
              Interne verantwoording
            </button>
            <p className="button-description">Bedoeld voor intern gebruik</p>
          </div>
          <div className="flex">
            <button
              onClick={() =>
                getExport(`${configSettings.ExportExterneVerantwoording}/${props.contractId}`)
              }
              className="export-finish-button"
            >
              Externe verantwoording
            </button>
            <p className="button-description">Bedoeld voor extern gebruik</p>
          </div>
          <div className="flex">
            <button
              onClick={() =>
                getExport(`${configSettings.ExportSpringBoss}/${props.contractId}`)
              }
              className="export-finish-button"
            >
              Spring/Boss export
            </button>
            <p className="button-description">Alle relevante BOSS informatie</p>
          </div>
        </div>
        <div id="finishContainer">
          <p>
            Let op, na het afsluiten van deze TI aanvraag is verdwijnt deze
            aanvraag uit het overzicht.
          </p>
          <p>
            Na het afsluiten van het contract kunnen deze bestanden niet meer
            worden gedownload.
          </p>
          <p>Sluit de aanvraag alleen af als het tarief geaccordeerd is.</p>
          <div className="flex invisible">
            <button className="export-finish-button ">I</button>
          </div>
          <div className="flex">
            <button
              className="export-finish-button lg"
              id="finishButton"
              onClick={() => setConfirmationDialogOpen(true)}
            >
              Afronden tariefindicatie aanvraag
            </button>
          </div>
        </div>
      </div>
      {ConfirmationDialogOpen && (
        <ConfirmationDialog
          open={ConfirmationDialogOpen}
          dialogTitle={"Contract afronden"}
          dialogText={"U staat op het punt om dit contract af te ronden"}
          dialogTextSecondary={
            "Weet u zeker dat alle taken, zoals downloads, voor de tariefindicatie aanvraag zijn afgerond?"
          }
          dialogTextThird={
            "Na afronding is het niet meer mogelijk om deze taken alsnog uit te voeren!"
          }
          confirm={Finish}
          cancel={() => setConfirmationDialogOpen(false)}
          showWarningIcon={false}
          confirmationText={"Ja"}
          cancelText={"Nee"}
        />
      )}
      <LoadingModal
        showLoadingModal={showLoadingModal}
        loadingText={LoadingText}
      />
      <ErrorSnackbar
        open={errorSnackbarOpen}
        errorMessage={errorSnackbarMsg}
        closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
      />
    </div>
  );
};
export default withRouter(ExportFinish);
