import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Tooltip,
  Select,
  MenuItem,
  Button
} from "@material-ui/core";
import Icons from "../../components/Icons";
import configSettings from "../../settings";
import ErrorSnackbar from "../../components/Utils/ErrorSnackbar";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";

export default function Hours(props) {
  const editableFieldsPerRow = 3;

  const [ContractId, setContractId] = useState(0);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMsg] = useState(
    "Er is iets fout gegaan bij het opslaan van de gegevens"
  );

  // Fields
  const [HoursData, setHoursData] = useState([]);
  // Textareas
  const [TotalParcelsAllRoutes, setTotalParcelsAllRoutes] = useState("");
  const [
    TotalRealizedHoursAllRoutes,
    setTotalRealizedHoursAllRoutes
  ] = useState("");
  const [TotalConsequenceParcelsAllRoutes, setTotalConsequenceParcelsAllRoutes] = useState("");
  const [ExplanationNormalTextarea, setExplanationNormalTextarea] = useState(
    ""
  );
  const [ExplanationExtraTextarea, setExplanationExtraTextarea] = useState("");
  const [ShowOptionalFields, setShowOptionalFields] = useState(false);

  const [IsSnackbarAutoClosing, setIsSnackbarAutoClosing] = useState(true);
  const [TabIndexBase, setTabIndexBase] = useState(0);
  const [TextareaValueOnFocus, setTextareaValueOnFocus] = useState("");

  const [FieldValueOnFocusStart, setFieldValueOnFocusStart] = useState(null);
  // eslint-disable-next-line
  const [FocusedIndex, setFocusedIndex] = useState(null);
  // eslint-disable-next-line
  const [FocusedInputName, setFocusedInputName] = useState(null);
  const focusedFieldValue = useRef("");
  const lastBlurredInputName = useRef(null);
  const lastBlurredInputValue = useRef(null);
  const lastBlurredInputIndex = useRef(null);

  useEffect(() => {
    setContractId(props.contractId);
    setTabIndexBase(props.tabIndexBase);
  }, []);

  useEffect(() => {
    const hoursCategory = props.hoursCategory;
    const _HoursData = [...hoursCategory.Hours];

    // textareas
    const toelichtingNormNietGebruikt = hoursCategory.ToelichtingNormNietGebruikt
      ? hoursCategory.ToelichtingNormNietGebruikt
      : "";
    const toelichtingExtraTijdToegekend = hoursCategory.ToelichtingExtraTijdToegekend
      ? hoursCategory.ToelichtingExtraTijdToegekend
      : "";
    const TotaalNormWerkpakketAlleRitten = hoursCategory.TotaalNormWerkpakketAlleRitten
      ? hoursCategory.TotaalNormWerkpakketAlleRitten
      : "";
    const TotaalGerealiseerdeUrenAlleRitten = hoursCategory.TotaalGerealiseerdeUrenAlleRitten
      ? hoursCategory.TotaalGerealiseerdeUrenAlleRitten
      : "";
    const TotaalGevolgWerkpakketAlleRitten = hoursCategory.TotaalGevolgWerkpakketAlleRitten
      ? hoursCategory.TotaalGevolgWerkpakketAlleRitten
      : "";
    setTotalParcelsAllRoutes(TotaalNormWerkpakketAlleRitten);
    setTotalRealizedHoursAllRoutes(TotaalGerealiseerdeUrenAlleRitten);
    setTotalConsequenceParcelsAllRoutes(TotaalGevolgWerkpakketAlleRitten);
    setExplanationExtraTextarea(toelichtingExtraTijdToegekend);
    setExplanationNormalTextarea(toelichtingNormNietGebruikt);

    if (lastBlurredInputValue.current) {
      _HoursData[lastBlurredInputIndex.current][lastBlurredInputName.current] =
        lastBlurredInputValue.current;
    }

    setHoursData(_HoursData);
  }, [props.hoursCategory]);

  const handleNumericFieldChange = (e, index, row) => {
    const name = e.target.name;
    let value = e.target.value;
    const hoursData = [...HoursData];
    let correctedValue;

    if (value !== "") {
      // eslint-disable-next-line
      if (value.match(/^\s*-?(\d+(\,\d{1,2})?|\,\d{1,2})\s*$/)) {
        correctedValue = value;
        focusedFieldValue.current = correctedValue;
      } else {
        correctedValue = focusedFieldValue.current;
      }
    } else if (value === "") {
      correctedValue = 0;
      focusedFieldValue.current = correctedValue;
    }

    if (value[value.length - 1] === ",") {
      correctedValue = value;
      focusedFieldValue.current = value;
    }

    switch (name) {
      case "ExtraHoursField":
        hoursData[index].ExtraAanAfrijUren = correctedValue;
        break;
      case "ExtraDeliveryHoursField":
        hoursData[index].ExtraBesteluren = correctedValue;
        break;
      default:
        break;
    }

    setHoursData(hoursData);
  };

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const handleSelectChange = (e, index, row) => {
    row.MetNormtijdGerekend = e.target.value;
    const selectedValue = e.target.value ? "true" : "false";
    updateHoursCall(e, index, row, selectedValue);
  };

  const handleTextAreaChange = e => {
    const name = e.target.name;

    switch (name) {
      case "ExplanationNormalTextarea":
        setExplanationNormalTextarea(e.target.value);
        break;
      case "ExplanationExtraTextarea":
        setExplanationExtraTextarea(e.target.value);
        break;

      default:
        break;
    }
  };

  const onBlurField = (e, index, row) => {
    const name = e.target.name;
    let field;

    setFocusedInputName(null);
    setFocusedIndex(null);

    switch (name) {
      case "ExtraHoursField":
        field = "ExtraAanAfrijUren";
        break;
      case "ExtraDeliveryHoursField":
        field = "ExtraBesteluren";
        break;
      default:
        break;
    }

    if (`${FieldValueOnFocusStart}` !== `${focusedFieldValue.current}`) {
      lastBlurredInputName.current = field;
      lastBlurredInputValue.current = focusedFieldValue.current;
      lastBlurredInputIndex.current = index;

      updateHoursCall(e, index, row);
    }
  };

  const onFocusField = (e, index) => {
    // select all text
    e.target.select();
    setFocusedInputName(e.target.name);
    setFocusedIndex(index);
    focusedFieldValue.current = e.target.value;
    // Storing the FieldValue before any changes
    // to prevent unnecessary POSTs
    setFieldValueOnFocusStart(e.target.value);
  };

  const onFocusTextarea = e => {
    // Storing the FieldValue before any changes
    // to prevent unnecessary POSTs
    setTextareaValueOnFocus(e.target.value);
  };

  const revertValueOnBadRequest = (name, _index, prevFieldValueOnFocus) => {
    const revertedData = HoursData.map((entry, index) => {
      if (index === _index) {
        switch (name) {
          case "NormalHoursField":
            entry.MetNormtijdGerekend =
              prevFieldValueOnFocus === "true" ? false : true;
            break;
          case "ExtraHoursField":
            entry.ExtraAanAfrijUren = prevFieldValueOnFocus;
            break;
          case "ExtraDeliveryHoursField":
            entry.ExtraBesteluren = prevFieldValueOnFocus;
            break;
          case "StandardWorkPackage":
            entry.NormWerkpakket = prevFieldValueOnFocus;
            break;
          case "RealizedHours":
            entry.GerealiseerdeUren = prevFieldValueOnFocus;
            break;
          default:
            break;
        }
      }
      return entry;
    });

    setHoursData(revertedData);
  };

  // const updateGrid = (data, index, row) => {
  //   const hoursData = [...HoursData];
  //   hoursData[index] = data;
  //   hoursData[index].RitName = row.RitName;
  //   setHoursData(hoursData);
  // };

  const updateHoursCall = async (e, index, row, selectedValue = null) => {
    const prevFieldValueOnFocus = selectedValue
      ? selectedValue
      : FieldValueOnFocusStart;
    setFieldValueOnFocusStart(null);

    const name = e.target.name;

    const loaders = document.querySelectorAll(
      "#hoursFieldsContainer .grid-custom-loader"
    );
    const loader = loaders[index + 1];
    loader.classList.add("visible");

    fetch(configSettings.UpdateRouteHoursUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Id: row.Id,
        RitId: row.RitId,
        ContractHoursId: row.ContractHoursId,
        MetNormtijdGerekend: row.MetNormtijdGerekend,
        ExtraAanAfrijUren:
          lastBlurredInputName.current === "ExtraAanAfrijUren"
            ? parseFloat(
                lastBlurredInputValue.current.toString().replace(",", "."),
                10
              )
            : parseFloat(
                row.ExtraAanAfrijUren.toString().replace(",", "."),
                10
              ),
        ExtraBesteluren:
          lastBlurredInputName.current === "ExtraBesteluren"
            ? parseFloat(
                lastBlurredInputValue.current.toString().replace(",", "."),
                10
              )
            : parseFloat(row.ExtraBesteluren.toString().replace(",", "."), 10)
      })
    })
      .then(function(res) {
        return res.json();
      })
      .then(function(data) {
        props.updateCategories();

        // updateGrid(data, index, row);
      })
      .catch(error => {
        console.log(error);
        setIsSnackbarAutoClosing(true);
        setErrorSnackbarOpen(true);
        if (prevFieldValueOnFocus) {
          revertValueOnBadRequest(name, index, prevFieldValueOnFocus);
        }
      })
      .finally(() => {
        loader.classList.remove("visible");
      });
  };

  const updateHoursExplanationCall = async e => {
    if (TextareaValueOnFocus !== e.target.value) {
      const loaderElem = document.getElementById("textareaHoursLoader");
      loaderElem.classList.add("visible");

      fetch(configSettings.UpdateHoursExplanationUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ContractId: ContractId,
          ToelichtingNormNietGebruikt: ExplanationNormalTextarea,
          ToelichtingExtraTijdToegekend: ExplanationExtraTextarea
        })
      })
        .then(function(res) {
          return res.text();
        })
        .then(function(data) {
          const isTrueSet = data === "true";
          if (!isTrueSet) {
            throw new Error(errorSnackbarMsg);
          }
        })
        .catch(error => {
          console.log(error);
          setIsSnackbarAutoClosing(false);
          setErrorSnackbarOpen(true);
        })
        .finally(() => {
          loaderElem.classList.remove("visible");
        });
    }
  };

  return (
    <div id="hoursSection">
      <div id="hoursFieldsContainer" className="fields-container">
        <div id="hoursTextareaContainerTop" className="flex textarea-container">
          <div id="hourTotals">
            <label>
              Totaal gevolg werkpakket: <span>{TotalConsequenceParcelsAllRoutes}</span>
            </label>
            <label className="ml1">
              Totaal norm werkpakket: <span>{TotalParcelsAllRoutes}</span>
            </label>
            <label className="ml1">
              Totaal gerealiseerde uren:{" "}
              <span>{TotalRealizedHoursAllRoutes}</span>
            </label>
          </div>
        </div>
        <div id="hoursFieldsHeader" className="flex fields-header">
          <div className="normalinputs flex">
            <div className="grid-custom-loader">
              <Icons.Loading />
            </div>
            <div className="column-header route-field">Ritnaam</div>
            <div className="column-header normal-hours-field">
              <Tooltip title="Werken met normuren" placement="top-end">
                <p>Normuren</p>
              </Tooltip>
            </div>
            <div className="column-header extra-hours-field">
              <Tooltip
                title="Extra uren aan, afrij en voorbereiding per week"
                placement="top-end"
              >
                <p>Extra uren Aan/Afrij</p>
              </Tooltip>
            </div>
            <div className="column-header extra-delivery-hours-field">
              <Tooltip title="Extra besteluren per week" placement="top-end">
                <p>Extra besteluren</p>
              </Tooltip>
            </div>
          </div>
          <div className="readonly-inputs flex">
            <div className="column-header extra-delivery-hours-field">
              <Tooltip title="NormWerkpakket" placement="top-end">
                <p>Norm werkpakket</p>
              </Tooltip>
            </div>
            <div className="column-header extra-delivery-hours-field">
              <Tooltip title="GerealiseerdeUren" placement="top-end">
                <p>Gerealiseerde uren</p>
              </Tooltip>
            </div>
            <div className="column-header efficiency-field">
              <Tooltip
                title="Efficiency waarmee wordt berekend"
                placement="top-end"
              >
                <p>Efficiency</p>
              </Tooltip>
            </div>
            <div className="column-header followup-hours-field">
              <Tooltip title="Gevolg besteluren" placement="top-end">
                <p>Gevolg besteluren</p>
              </Tooltip>
            </div>
            <div className="column-header followup-package-field">
              <Tooltip title="Gevolg Werkpakket" placement="top-end">
                <p>Gevolg werkpakket</p>
              </Tooltip>
            </div>
            <div className="column-header hourly-rate-field">
              <Tooltip title="Basis uurtarief * uren" placement="top-end">
                <p>Basis uurtarief</p>
              </Tooltip>
            </div>
          </div>
        </div>
        <div id="hoursFieldsBody" className="fields-body">
          {HoursData.map((row, index) => {
            return (
              <div
                key={index}
                className={`flex contract-details-row row-${index}`}
              >
                <div className="normalinputs flex">
                  <div className="grid-custom-loader">
                    <Icons.Loading />
                  </div>
                  <p className="cdgrid-field route-field">{row.RitName}</p>
                  <Select
                    value={row.MetNormtijdGerekend}
                    name={"NormalHoursField"}
                    className="cdgrid-field normal-hours-field"
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 1)}`
                    }}
                    onChange={e => handleSelectChange(e, index, row)}
                  >
                    <MenuItem value={true}>Ja</MenuItem>
                    <MenuItem value={false}>Nee</MenuItem>
                  </Select>
                  <TextField
                    variant="outlined"
                    className="cdgrid-field extra-hours-field"
                    name={"ExtraHoursField"}
                    value={
                      FocusedInputName === "ExtraHoursField" &&
                      index === FocusedIndex
                        ? focusedFieldValue.current
                        : row.ExtraAanAfrijUren.toString().replace(".", ",")
                    }
                    onChange={e => handleNumericFieldChange(e, index, row)}
                    onFocus={e => onFocusField(e, index)}
                    onBlur={e => onBlurField(e, index, row)}
                    onKeyDown={e => handleKeyPress(e)}
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 2)}`,
                      min: 0
                    }}
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field extra-delivery-hours-field"
                    name={"ExtraDeliveryHoursField"}
                    value={
                      FocusedInputName === "ExtraDeliveryHoursField" &&
                      index === FocusedIndex
                        ? focusedFieldValue.current
                        : row.ExtraBesteluren.toString().replace(".", ",")
                    }
                    onChange={e => handleNumericFieldChange(e, index, row)}
                    onFocus={e => onFocusField(e, index)}
                    onBlur={e => onBlurField(e, index, row)}
                    onKeyDown={e => handleKeyPress(e)}
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 3)}`,
                      min: 0
                    }}
                  />
                </div>
                <div className="readonly-inputs flex">
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced"
                    value={row.NormWerkpakket}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced"
                    value={row.GerealiseerdeUren}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field efficiency-field calced"
                    value={row.Efficiency}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field followup-hours-field calced"
                    value={row.GevolgBesteluren}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field followup-package-field calced"
                    value={row.GevolgWerkpakket}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field hourly-rate-field calced currency"
                    value={row.BasisUurtarief}
                    readOnly
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div id="hoursTextareaContainer" className="flex textarea-container">
          <div id="textareaHoursLoader" className="textarea-loader">
            <Icons.Loading />
          </div>
          <div id="hoursTextareaNorm">
            <label className="textarea-title">
              Toelichting norm niet gebruikt
            </label>
            <TextField
              className="big-textbox"
              aria-label="Toelichting norm niet gebruikt"
              variant="outlined"
              name="ExplanationNormalTextarea"
              value={ExplanationNormalTextarea}
              onFocus={e => onFocusTextarea(e)}
              onChange={e => handleTextAreaChange(e)}
              onBlur={e => updateHoursExplanationCall(e)}
              tabIndex={TabIndexBase + (HoursData.length + 1 + 2 * 1)}
            />
          </div>
          <div id="hoursTextareaExtra">
            <label className="textarea-title">
              Toelichting extra tijd toegekend
            </label>
            <TextField
              className="big-textbox"
              aria-label="Toelichting extra tijd toegekend"
              variant="outlined"
              name="ExplanationExtraTextarea"
              value={ExplanationExtraTextarea}
              onFocus={e => onFocusTextarea(e)}
              onChange={e => handleTextAreaChange(e)}
              onBlur={e => updateHoursExplanationCall(e)}
              tabIndex={TabIndexBase + (HoursData.length + 1 + 2 * 2)}
            />
          </div>
        </div>
        <Button
          className="mt1 primary-btn"
          variant="contained"
          onClick={() => setShowOptionalFields(!ShowOptionalFields)}
        >
          {ShowOptionalFields ? <RemoveCircleOutline /> : <AddCircleOutline />}
          <span className="toggler-text">
            {ShowOptionalFields ? "Verberg" : "Toon"} extra velden
          </span>
        </Button>

        {ShowOptionalFields ? (
          <>
            <div id="hoursFieldsHeaderOptional" className="flex fields-header">
              <div className="grid-custom-loader">
                <Icons.Loading />
              </div>
              <div className="normal-inputs flex">
                <div className="column-header route-field">Ritnaam</div>
                <div className="column-header field-md">
                  <Tooltip title="AantalPauzes" placement="top-end">
                    <p>Aantal pauzes</p>
                  </Tooltip>
                </div>
                <div className="column-header field-md">
                  <Tooltip title="AanAfrijtijdPerWeek" placement="top-end">
                    <p>Aan-afrij tijd per week</p>
                  </Tooltip>
                </div>
                <div className="column-header field-md">
                  <Tooltip title="BesteltijdPerWeek" placement="top-end">
                    <p>Besteltijd per week</p>
                  </Tooltip>
                </div>
                <div className="column-header field-md">
                  <Tooltip
                    title="AantalGeplandeStopsPerBesteluur"
                    placement="top-end"
                  >
                    <p>Stops p/uur realisatie</p>
                  </Tooltip>
                </div>
                <div className="column-header field-md">
                  <Tooltip
                    title="NormGeplandeStopsPerBesteluur"
                    placement="top-end"
                  >
                    <p>Stops p/uur norm</p>
                  </Tooltip>
                </div>
                <div className="column-header field-md">
                  <Tooltip title="NormBesteltijd" placement="top-end">
                    <p>Norm besteltijd</p>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div id="hoursFieldsBodyOptional" className="fields-body">
              {HoursData.map((row, index) => {
                return (
                  <div
                    key={index}
                    className={`flex contract-details-row row-${index}`}
                  >
                    <div className="normalinputs flex">
                      <div className="grid-custom-loader">
                        <Icons.Loading />
                      </div>
                      <p className="cdgrid-field route-field">{row.RitName}</p>

                      <TextField
                        variant="outlined"
                        className="cdgrid-field field-md readOnly"
                        value={row.AantalPauzes}
                        readOnly
                      />
                      <TextField
                        variant="outlined"
                        className="cdgrid-field field-md readOnly"
                        value={row.AanAfrijtijdPerWeek}
                        readOnly
                      />
                      <TextField
                        variant="outlined"
                        className="cdgrid-field field-md readOnly"
                        value={row.BesteltijdPerWeek}
                        readOnly
                      />
                      <TextField
                        variant="outlined"
                        className="cdgrid-field field-md readOnly"
                        value={row.AantalGeplandeStopsPerBesteluur}
                        readOnly
                      />
                      <TextField
                        variant="outlined"
                        className="cdgrid-field field-md readOnly"
                        value={row.NormGeplandeStopsPerBesteluur}
                        readOnly
                      />
                      <TextField
                        variant="outlined"
                        className="cdgrid-field field-md readOnly"
                        value={row.NormBesteltijd}
                        readOnly
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
      {errorSnackbarOpen && (
        <ErrorSnackbar
          open={errorSnackbarOpen}
          errorMessage={errorSnackbarMsg}
          closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
          autoCloseSeconds={IsSnackbarAutoClosing ? 3 : false}
        />
      )}
    </div>
  );
}
