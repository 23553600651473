import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useAuth = () => {
  const [state, setState] = useContext(AuthContext);

  function setAuthStatus(status) {
    setState(state => ({
      ...state,
      authStatus: status
    }));
  }

  function setCurrentUser(userAttributes) {
    setState(state => ({
      ...state,
      currentUser: userAttributes
    }));
  }

  function setGlobalSelectedDepotId(id) {
    setState(state => ({
      ...state,
      globalSelectedDepotId: id
    }));
  }

  function setGlobalSelectedDepot(depot) {
    setState(state => ({
      ...state,
      globalSelectedDepot: depot
    }));
    sessionStorage.setItem("GlobalSelectedDepot", JSON.stringify(depot));
  }

  return {
    authStatus: state.authStatus,
    setAuthStatus,
    currentUser: state.currentUser,
    setCurrentUser,
    globalSelectedDepotId: state.globalSelectedDepotId,
    setGlobalSelectedDepotId,
    globalSelectedDepot: state.globalSelectedDepot,
    setGlobalSelectedDepot
  };
};

export default useAuth;
