import React, { Component } from "react";
import ReactTable from "react-table";
import Icons from "../Icons";
import Button from "@material-ui/core/Button";
import "react-table/react-table.css";

export default class TMRoutesTable extends Component {
  state = {
    selectedRows: [],
    selectedRowsData: [],
    // persist deselectedIds when uitvoerders change
    deselectedIds: [],
    selectedAll: false,
    depotId: 0,
    data: []
  };

  componentDidMount() {
    this.onSelectAllClick();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ selectedRows: [], selectedRowsData: [] });
      // set current depotId
      if (this.props.data.length) {
        this.setState({ depotId: this.props.data[0].DepotId });
      }
      // check if depot changed to clear deselectedIds
      if (prevProps.data.length && this.props.data.length) {
        if (prevProps.data[0].DepotId !== this.props.data[0].DepotId) {
          if (this.state.deselectedIds.length) {
            this.setState({ deselectedIds: [] });
          }
        }
      }
    }
    // update selected routes
    if (prevProps.selectedRoutes !== this.props.selectedRoutes) {
      const selectedRowsData = [
        ...this.updateSelectedRowsData(this.props.selectedRoutes)
      ];

      const selectedRows = [
        ...this.updateSelectedRows(this.props.selectedRoutes)
      ];

      if (this.props.data.length) {
        let selectAllCheckboxElem = document.getElementById(
          "selectAllCheckbox"
        );

        if (selectedRowsData.length === this.props.data.length) {
          selectAllCheckboxElem.checked = true;
          this.setState({ selectedAll: true });
        } else {
          this.setState({ selectedAll: false });
          selectAllCheckboxElem.checked = false;
        }
      }
      this.setState({
        selectedRows,
        selectedRowsData
      });
    }
  }

  updateSelectedRowsData = selectedRoutes => {
    const deselectedIds = [...this.state.deselectedIds];

    const selectedRowsData = [];
    this.props.data.forEach((route, index) => {
      if (!deselectedIds.includes(route.Id)) {
        if (selectedRoutes.indexOf(index) > -1) {
          selectedRowsData.push(route);
        }
      }
    });
    return selectedRowsData;
  };

  // update array that controls selected row style
  updateSelectedRows = selectedRoutes => {
    const selectedRows = [...selectedRoutes];
    const deselectedIds = [...this.state.deselectedIds];

    // remove selected routes if they exist in deselectedIds
    this.props.data.forEach((route, index) => {
      let deselectedIdIndex = deselectedIds.indexOf(route.Id);
      if (deselectedIdIndex > -1) {
        selectedRows.splice(selectedRows.indexOf(index), 1);
      }
    });

    return selectedRows;
  };

    buttonAccessTariefAanvraag() {
        // only NL depots
        return this.state.selectedRows.length ? [27, 28, 29, 30, 31, 32, 33, 34, 35, 38, 39, 40, 42].includes(this.state.depotId) : true;
    };

    buttonAccessPostcodeExport() {
        // only BE depots
        return this.state.selectedRows.length ? ![27, 28, 29, 30, 31, 32, 33, 34, 35, 38, 39, 40, 42].includes(this.state.depotId) : true;
    };


  selectRow(e, row) {
    const selectedRows = this.state.selectedRows;
    const deselectedIds = [...this.state.deselectedIds];

    if (selectedRows.includes(row.index)) {
      let index = selectedRows.indexOf(row.index);
      deselectedIds.push(row.original.Id);
      selectedRows.splice(index, 1);
    } else {
      selectedRows.push(row.index);
      let deselectedIdIndex = deselectedIds.indexOf(row.original.Id);
      if (deselectedIdIndex > -1) {
        deselectedIds.splice(deselectedIdIndex, 1);
      }
    }

    const selectedRowsData = [...this.updateSelectedRowsData(selectedRows)];

    this.setState({
      deselectedIds,
      selectedRows,
      selectedRowsData
    });
  }

  onSelectAllClick = () => {
    let tempSelectedRows = [];

    if (!this.state.selectedAll) {
      this.props.data.forEach((row, index) => {
        tempSelectedRows.push(index);
      });
    }

    const selectedRowsData = [...this.updateSelectedRowsData(tempSelectedRows)];

    this.setState({
      selectedRows: tempSelectedRows,
      selectedAll: !this.state.selectedAll,
      selectedRowsData
    });
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  saveSelectedRows = () => {
    const selectedRowsData = [...this.state.selectedRowsData];

    const ritIds = selectedRowsData.map(row => row.Id);
    this.props.onTIRequest(ritIds);
  };

    exportPostcodeRange = () => {
        const selectedRowsData = [...this.state.selectedRowsData];

        const ritIds = selectedRowsData.map(row => row.Id);
        this.props.onExportPostcodeRangeRequest(ritIds);
    };

  deselectRows = () => {
    this.setState({
      selectedRows: [],
      selectedRowsData: [],
      selectedAll: false
    });

    const selectAllCheckbox = document.getElementById("selectAllCheckbox");
    selectAllCheckbox.checked = false;
  };

  render() {
    const data = this.props.data;
    return (
      <div id="TMRoutesTable" className="compact-table hide-pagination">
        <ReactTable
          data={data}
          resolveData={data => data.map(row => row)}
          loading={this.props.loading}
          loadingText={
            <div className="grid-custom-loader">
              <Icons.Loading />
              <b>Loading</b>
            </div>
          }
          columns={[
            {
              Header: (
                <input
                  type="checkbox"
                  id="selectAllCheckbox"
                  onChange={
                    this.state.selectedAll
                      ? this.deselectRows
                      : this.onSelectAllClick
                  }
                />
              ),
              Cell: row => (
                <div>
                  <input
                    type="checkbox"
                    checked={this.state.selectedRows.includes(row.index)}
                    readOnly
                  />
                </div>
              ),
              width: 40,
              sortable: false
            },
            {
              Header: "Ritcode",
              id: "Ritcode",
              accessor: d => d.Ritcode,
              show: true,
              width: 70,
              className: "column-align-left"
            },
            {
              Header: "Ritnaam",
              id: "Ritnaam",
              accessor: d => d.Ritnaam,
              show: true,
              width: 220,
              className: "column-align-left"
            },
            {
              Header: "Uitvoerder",
              id: "Uitvoerder",
              accessor: d => d.Uitvoerder,
              show: true,
              className: "column-align-left"
            }
          ]}
          pageSize={data.length}
          noDataText="Geen Ritten"
          className="-striped -highlight"
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: e => this.selectRow(e, rowInfo),
                style: {
                  background: this.state.selectedRows.includes(rowInfo.index)
                    ? "#2196f3"
                    : null,
                  color: this.state.selectedRows.includes(rowInfo.index)
                    ? "white"
                    : null
                },
                className: this.state.selectedRows.includes(rowInfo.index)
                  ? "selected "
                  : ""
              };
            } else {
              return {};
            }
          }}
        />
            <div className="table-actions flex-reverse">
             <Button
            variant="contained"
            color="primary"
            className="btn btn-primary ml1"
            onClick={() => this.saveSelectedRows()}
            disabled={this.buttonAccessTariefAanvraag()}
          >
            Vraag tariefindicatie aan
          </Button>
          <Button
          variant="contained"
          color="primary"
          className="btn btn-primary ml1"
          onClick={() => this.exportPostcodeRange()}
          disabled={this.buttonAccessPostcodeExport() }
          >
          Export postcodes
          </Button>
        </div>
      </div>
    );
  }
}
