import React from "react";
import TextField from "@material-ui/core/TextField";

const UseInput = ({ type, name, onChange, value, ...rest }) => (
  <TextField
    name={name}
    type={type}
    value={value}
    onChange={event => {
      event.preventDefault();
      onChange(name, event.target.value);
    }}
    {...rest}
  />
);

export default UseInput;
