import React from "react";
import logo from "./postnl_logo.svg";

import "./styles/app.scss";

const fullscreenCenterStyle = {
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const Loading = () => {
  return (
    <div style={fullscreenCenterStyle}>
      <div className="fade-in-out">
        <img width="100" src={logo} alt="PostNLlogo" />
      </div>
    </div>
  );
};

export default Loading;
