import React from 'react';
import { Input, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icons from "../Icons";

class TrapScenario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scenarioValue: '{"id":0}',
            scenarioName: '',
            trapStepDescription: '',
            validationMessage: 'init',
        }
    }

    componentDidMount() {
    }

    handleChange = (event) => {
        this.setState({ scenarioName: event.target.value });
    }

    handleSelectChange = (event) => {
        this.setState({ scenarioValue: event.target.value });
        this.props.handleFilterChange(event);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scenarioName !== this.props.scenarioName) {
            //alert('prop change scenario name')
            this.setState({ scenarioName: this.props.scenarioName })
        }

        if (prevProps.scenarioValue !== this.props.scenarioValue) {
            //alert('prop change scenario value - prev: ' + prevProps.scenarioValue + ' - new: ' + this.props.scenarioValue)
            this.setState({ scenarioValue: this.props.scenarioValue })
        }
    }

    render() {
        return <div><Select
            native
            autoFocus
            disabled={this.props.scenarios.length > 0 ? false : true}
            onChange={e => this.handleSelectChange(e)}
            value={this.state.scenarioValue}
            id="scenarioSelect"
        >
            <option value='{"id":0}' disabled>
                Selecteer een scenario
            </option>
            {this.props.scenarios}
        </Select>
            <Input type="text" value={this.state.scenarioName} onChange={this.handleChange} placeholder="scenario naam"></Input>
            <div className="actions flex">
                <Button
                    size="small"
                    className="normalcase"
                    variant="outlined"
                    onClick={() => this.props.createScenario(this.state.scenarioName)}
                >
                    <Icons.Upload />
                    Aanmaken
                </Button></div>
            {/*<p>{this.props.validationMessage} - {this.state.scenarioName}</p>*/}
            <p>{this.props.validationMessage}</p>
        </div>;
    }
}

export default TrapScenario;