import React, { useEffect } from "react";
import {
  // Redirect,
  // Route,
  Switch,
  withRouter
} from "react-router-dom";
import LoginRequiredRoute from "./LoginRequiredRoute";
import Loading from "./Loading";
// import Unauthorized from "./screens/Unauthorized";
import App from "./App";
import useAuth from "./hooks/useAuth";
import configSettings from "./settings";
import Api from "./helpers/http";
// import functions from "./helpers/functions";

const Main = () => {
  const { authStatus, setAuthStatus, currentUser, setCurrentUser } = useAuth();

  useEffect(() => {
    getCurentUser();
  }, []);

  const getCurentUser = async () => {
    const currentUser = await Api.get(`${configSettings.GetCurrentUserUrl}`);
    setCurrentUser(currentUser);
  };

  useEffect(() => {
    if (Object.entries(currentUser).length !== 0) {
      setTimeout(() => {
        // for the Loading screen to show
        setAuthStatus("loggedIn");
      }, 200);
    }
  }, [currentUser]);

  if (authStatus === "checkingAuthStatus") {
    return <Loading />;
  }

  return (
    <Switch>
      {authStatus === "loggedIn" && <LoginRequiredRoute component={App} />}
      {/* {authStatus === "unauthorizedUser" && <Redirect to="/unauthorized" />} */}
    </Switch>
  );
};

export default withRouter(Main);
