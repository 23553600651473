import React, { Component } from "react";
import Icons from "../Icons";
import ReactTable from "react-table";
import ConfirmationDialog from "../Utils/ConfirmationDialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Form from "../Modals/DynamicForm.tsx";
import configSettings from "../../settings";
import LoadingModal from "../Modals/LoadingModal";
import SuccessSnackbar from "../Utils/SuccessSnackbar";
import ErrorSnackbar from "../Utils/ErrorSnackbar";
import functions from "../../helpers/functions";

import "../../styles/app.scss";

export default class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValues: {},
      formModel: [],
      columns: [],
      usersData: [],
      usersLoading: true,
      depotsSelected: [],
      selectedUserGroup: {},
      confirmationDialogOpen: false,
      dialogText: "",
      showLoadingModal: false,
      successSnackbarOpen: false,
      errorSnackbarOpen: false,
      snackbarMsg: "",
      deletingRow: {},
      currentUserRole: "",
      formErrorMessage: ""
    };
  }

  componentDidMount() {
    this.initColumns();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.usersData !== this.props.usersData) {
      this.tableDataProcessing(this.props.usersData);
    }
    if (prevProps.usersLoading !== this.props.usersLoading) {
      this.setState({ usersLoading: this.props.usersLoading });
    }
    if (prevProps.currentUser !== this.props.currentUser) {
      if (this.props.currentUser.Role) {
        this.setState({
          currentUserRole: this.props.currentUser.Role.RoleName
        });
      }
    }
  }

  initColumns = () => {
    const columns = [
      {
        Header: (
          <div className="add-icon" onClick={this.openAddRowForm}>
            <Icons.Add />
          </div>
        ),
        Cell: row => (
          <div className="flex space-e">
            <div
              className="edit-icon"
              onClick={() => this.openEditRowForm(row)}
            >
              <EditIcon />
            </div>
            <div
              className="delete-icon"
              onClick={() => this.confirmDeleteRow(row)}
            >
              <DeleteIcon />
            </div>
          </div>
        ),
        width: 60,
        sortable: false
      },
      {
        Header: "Emailadres",
        id: "Email",
        accessor: "Email"
      },
      {
        Header: "Rol",
        id: "Role",
        accessor: d => d.Role
      },
      {
        Header: "Gebruikersnaam",
        id: "UserName",
        accessor: d => d.UserName,
        show: false
      },
      {
        Header: "Id",
        id: "Id",
        accessor: d => d.Id,
        show: false
      },
      {
        Header: "Depots",
        id: "DepotList",
        accessor: d => d.DepotList,
        style: { whiteSpace: "normal" }
        // Cell: row => (
        //   <div title={row.original.DepotList} className="relative text-center">
        //     {row.original.DepotList}
        //   </div>
        // )
      },
      {
        Header: "Aanmaakdatum",
        id: "CreatedDate",
        accessor: d => d.CreatedDate
      },
      {
        Header: "Last Login",
        id: "LastLogin",
        accessor: d => d.LastLogin,
        show: false
      },
      {
        Header: "Aangepast op",
        id: "ModifiedDate",
        accessor: d => d.ModifiedDate,
        show: false
      },
      {
        Header: "",
        id: "DepotListLength",
        accessor: d => d.DepotListLength,
        show: false
      }
    ];

    this.setState({ columns });
  };

  // Render data after get request
  tableDataProcessing = data => {
    const usersData = [];

    data.forEach(entry => {
      const CreatedDate = entry.CreatedDate.split("T")[0];

      usersData.push({
        Id: entry.Id,
        Email: entry.Email,
        UserName: entry.UserName,
        Role: entry.Role.RoleName,
        DepotList: entry.Depots.map(depot => depot.Naam).join(),
        DepotListLength: entry.Depots.length,
        CreatedDate
      });
    });

    this.setState({ usersLoading: false, usersData });
  };

  openEditRowForm = row => {
    this.setState({ editingRow: row, formErrorMessage: "" });

    // get/init current role value
    const roleOptions = [];
    let rowRoleValue;
    this.props.userRoles.forEach(role => {
      roleOptions.push({
        key: role.Id,
        label: role.RoleName,
        value: JSON.stringify(role)
      });
      if (role.RoleName === row.original.Role) {
        rowRoleValue = JSON.stringify(role);
      }
    });
    const roleValue = rowRoleValue ? rowRoleValue : roleOptions[0].value;
    this.setState({
      selectedUserGroup: roleValue
    });

    const RoleNameValue = JSON.parse(roleValue).RoleName;

    if (RoleNameValue === "Beheerder" || RoleNameValue === "Hoofdkantoor") {
      this.setState({ multiSelectDisabled: true });
    } else {
      this.setState({ multiSelectDisabled: false });
    }

    // get/init depot values
    const depotsOptions = [];
    this.props.depots.forEach(depot => {
      depotsOptions.push({
        key: depot.Blscode,
        label: depot.Naam,
        value: depot.Blscode
      });
    });
    const depotsSelected = row.original.DepotList.length
      ? [...row.original.DepotList.split(",")]
      : [];
    this.setState({ depotsSelected });

    // form render objects
    const formModel = [
      {
        key: "email",
        name: "email",
        value: row.original.Email,
        type: "email",
        label: "Email:",
        props: {
          disabled: true,
          required: true,
          autoComplete: "off",
          "aria-label": "email",
          autoFocus: true
        }
      },
      {
        key: "userGroup",
        type: "advancedSelect",
        value: roleValue,
        name: "userGroup",
        label: "Gebruikersrol:",
        props: {
          required: true,
          "aria-label": "userGroup"
        },
        options: roleOptions
      },
      {
        key: "Depots",
        type: "multipleSelect",
        value: depotsOptions[0].value,
        name: "Depots",
        label: "Depots:",
        props: {
          required: true,
          "aria-label": "Depots"
        },
        options: depotsOptions
      },
      {
        key: "Id",
        name: "Id",
        value: row.original.Id,
        type: "hidden",
        label: "",
        props: {
          autoComplete: "off",
          "aria-label": "Id",
          autoFocus: true
        }
      }
    ];

    this.setState({
      formMode: "editRowMode",
      formModel,
      showForm: true
    });

    // Hide depot selector when Users are Admin / Beheerder / Hoofdkantoor
    const RoleName = JSON.parse(roleValue).RoleName;

    if (
      RoleName === "Administrator" ||
      RoleName === "Beheerder" ||
      RoleName === "Hoofdkantoor"
    ) {
      this.hideSelectDepotsinForm();
    }
  };

  openAddRowForm = () => {
    const roleOptions = [];
    const depotsOptions = [];

    this.props.userRoles.forEach(role => {
      roleOptions.push({
        key: role.Id,
        label: role.RoleName,
        value: JSON.stringify(role)
      });
    });
    this.setState({
      selectedUserGroup: roleOptions[0].value,
      multiSelectDisabled: true,
      depotsSelected: [],
      formErrorMessage: ""
    });

    this.props.depots.forEach(depot => {
      depotsOptions.push({
        key: depot.Blscode,
        label: depot.Naam,
        value: depot.Blscode
      });
    });

    const formModel = [
      {
        key: "email",
        name: "email",
        value: "",
        type: "email",
        label: "Email:",
        props: {
          required: true,
          autoComplete: "off",
          "aria-label": "email",
          autoFocus: true
        }
      },
      {
        key: "userGroup",
        type: "advancedSelect",
        value: roleOptions[0].value,
        name: "userGroup",
        label: "Gebruikersrol:",
        props: {
          required: true,
          "aria-label": "userGroup"
        },
        options: roleOptions
      },

      {
        key: "Depots",
        type: "multipleSelect",
        value: depotsOptions[0].value,
        name: "Depots",
        label: "Depots:",
        className: "invisible",
        props: {
          required: true,
          "aria-label": "Depots"
        },
        options: depotsOptions
      }
    ];

    this.setState({
      formMode: "addRowMode",
      formModel,
      showForm: true
    });

    // Hide depot selector when Users are Admin / Beheerder / Hoofdkantoor
    const RoleName = JSON.parse(roleOptions[0].value).RoleName;
    if (
      RoleName === "Administrator" ||
      RoleName === "Beheerder" ||
      RoleName === "Hoofdkantoor"
    ) {
      this.hideSelectDepotsinForm();
    }
  };

  // Hide depot selector when Users are Admin / Beheerder / Hoofdkantoor
  hideSelectDepotsinForm = () => {
    let checkDepotSelectExists = setInterval(() => {
      if (document.getElementById("select-Depots")) {
        const depotFormGroup = document.getElementById("select-Depots")
          .parentElement.parentElement;

        depotFormGroup.classList.add("invisible");
        clearInterval(checkDepotSelectExists);
      }
    }, 100);
  };

  closeForm = () => {
    this.setState({ showForm: false });
  };

  confirmDeleteRow = row => {
    this.setState({
      dialogText: `Verwijder gebruiker met email adres ${
        row.original.Email.split("@")[0]
      }?`,
      deletingRow: row,
      confirmationDialogOpen: true
    });
  };

  deleteRow = () => {
    this.setState({ showLoadingModal: true });
    const deletingRow = this.state.deletingRow;
    const userId = deletingRow.original.Id;

    fetch(`${configSettings.DeleteUserUrl}?userId=${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(res => {
        this.setState({
          snackbarMsg: res,
          successSnackbarOpen: true,
          showLoadingModal: false
        });

        // delete animation
        functions.highlightDeleted(deletingRow.viewIndex);

        setTimeout(() => {
          const dataArr = this.state.usersData;

          dataArr.splice(deletingRow.index, 1);
          const newData = [...dataArr];
          this.setState({
            usersData: newData,
            deletingRow: null
          });
        }, 1000);
      })
      .catch(error => {
        this.setState({
          snackbarMsg: JSON.stringify(error),
          errorSnackbarOpen: true,
          showLoadingModal: false,
          deletingRow: null
        });
      });
  };

  // Add or update user && show changes
  onSubmit = async (formModel, formMode) => {
    let validationsOk = true;

    let userGroup = this.state.selectedUserGroup;
    const depotsSelected = this.state.depotsSelected;

    if (typeof userGroup === "string") {
      userGroup = JSON.parse(userGroup);
    }

    if (userGroup.RoleName === "Depot") {
      if (depotsSelected.length === 0) {
        validationsOk = false;
        this.setState({
          formErrorMessage:
            "Selecteer minstens n depot voor een depot-gebruiker"
        });
      }
    }

    if (validationsOk) {
      this.setState({ showLoadingModal: true });
      const requestBody = this.prepRequestBody(formModel, formMode);
      const updateTable = await this.updateTable(requestBody, formMode);
      const [statusOk, response] = updateTable;

      this.setState({
        snackbarMsg: response,
        showLoadingModal: false,
        showForm: false
      });

      if (statusOk) {
        this.setState({
          successSnackbarOpen: true
        });

        // Show changes in ReactTable
        if (formMode === "addRowMode") {
          // data.unshift({
          //   Email: formModel.email,
          //   UserName: formModel.UserName,
          //   Role: Role.RoleName,
          //   DepotList: this.state.depotsSelected.join(),
          //   DepotListLength: this.state.depotsSelected.length
          // });
          // const newData = [...data];
          // this.setState({ usersData: newData });

          // no id in user add response... refreshing
          this.props.refreshTable();
        } else if (formMode === "editRowMode") {
          this.props.refreshTable();
          // update animation
          setTimeout(() => {
            functions.highlightEdited(this.state.editingRow.viewIndex);
          }, 500);
        }
      } else {
        this.setState({
          errorSnackbarOpen: true
        });
      }
    }
  };

  prepRequestBody = (formModel, formMode) => {
    const depotsSelected = this.state.depotsSelected;
    let depotsBLScodes = [];

    const userGroup = this.state.selectedUserGroup;

    if (
      userGroup.RoleName === "Administrator" ||
      userGroup.RoleName === "Beheerder" ||
      userGroup.RoleName === "Hoofdkantoor"
    ) {
      // depotsBLScodes = [];
    } else {
      depotsSelected.forEach(depot => {
        const index = this.props.depots.findIndex(x => x.Naam === depot);
        depotsBLScodes.push({ Blscode: this.props.depots[index].Blscode });
      });
    }

    const requestBody = {
      Email: formModel.email,
      Role: JSON.parse(formModel.userGroup),
      Depots: depotsBLScodes
    };

    if (formMode === "editRowMode") {
      requestBody.Id = formModel.Id;
    }

    return requestBody;
  };

  updateTable = async (requestBody, formMode) => {
    let url;

    if (formMode === "addRowMode") {
      url = configSettings.AddUserUrl;
    } else if (formMode === "editRowMode") {
      url = configSettings.UpdateUserUrl;
    }

    let response;
    let responseOk;

    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          responseOk = true;
        }
        return response.json();
      })
      .then(res => {
        if (responseOk) {
          response = [responseOk, res];
        } else {
          response = [responseOk, res];
        }
      })
      .catch(function(error) {
        console.log(error);
        response = [responseOk, "error"];
      });

    return response;
  };

  onChangeMultiple = (e, value) => {
    const depotsSelected = [...e.target.value];

    if (this.state.formErrorMessage.indexOf("minstens n depot")) {
      if (depotsSelected.length) {
        this.setState({ formErrorMessage: "" });
      }
    }

    this.setState({ depotsSelected });
  };

  onChangeAdvanced = e => {
    const selectedUserGroup = JSON.parse(e.target.value);
    const depotFormGroup = document.getElementById("select-Depots")
      .parentElement.parentElement;

    if (
      selectedUserGroup.RoleName === "Administrator" ||
      selectedUserGroup.RoleName === "Beheerder" ||
      selectedUserGroup.RoleName === "Hoofdkantoor"
    ) {
      this.setState({ multiSelectDisabled: true });
      // hide depot select
      depotFormGroup.classList.add("invisible");
    } else {
      this.setState({ multiSelectDisabled: false });
      // show depot select
      depotFormGroup.classList.remove("invisible");
    }
    this.setState({ selectedUserGroup });
  };

  dialogConfirm = () => {
    this.deleteRow();
    this.setState({ confirmationDialogOpen: false });
  };

  dialogCancel = () => {
    this.setState({ confirmationDialogOpen: false, deletingRow: null });
  };

  render() {
    const data = this.state.usersData;
    return (
      <>
        <div className=".fade-in-slow compact-table-no-height">
          <ReactTable
            data={data}
            resolveData={data => data.map(row => row)}
            columns={this.state.columns}
            loading={this.state.usersLoading}
            previousText={"Vorige"}
            nextText={"Volgende"}
            pageText={"Pagina"}
            ofText={"van"}
            rowsText={"Rijen"}
            loadingText={
              <div className="grid-custom-loader">
                <Icons.Loading />
                <b>Laden</b>
              </div>
            }
            defaultPageSize={20}
            className="-striped -highlight"
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.original.Role === "Administrator") {
                return {
                  className: "hide-row-actions"
                };
              }

              if (
                rowInfo &&
                rowInfo.original.Role === "Beheerder" &&
                this.state.currentUserRole === "Beheerder"
              ) {
                return {
                  className: "hide-edit-action"
                };
              } else {
                return 1;
              }
            }}
          />
        </div>
        {this.state.showForm && (
          <div className="fade-in-slow">
            <Form
              model={this.state.formModel}
              showForm={this.state.showForm}
              defaultValues={this.state.currentValues}
              closeForm={this.closeForm}
              onChangeAdvanced={this.onChangeAdvanced}
              onSubmit={this.onSubmit}
              formMode={this.state.formMode}
              multiSelectDisabled={this.state.multiSelectDisabled}
              multipleSelectValue={this.state.depotsSelected} // optimization needed
              onChangeMultiple={this.onChangeMultiple}
              // onChange={this.onChange}
              errorMessage={this.state.formErrorMessage}
              // groupSelectExpanded={this.state.groupSelectExpanded}
              // groupSelected={this.state.groupSelected}
              // activeGroups={this.state.activeGroups}
              // groupSelectText="Select user groups"
            />
          </div>
        )}
        {this.state.confirmationDialogOpen && (
          <ConfirmationDialog
            open={this.state.confirmationDialogOpen}
            dialogText={this.state.dialogText}
            confirm={this.dialogConfirm}
            cancel={this.dialogCancel}
            showWarningIcon={true}
            confirmationText={"Ja, verwijder."}
            cancelText={"Annuleren"}
          />
        )}
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
          loadingText={"Verwerken..."}
        />

        <ErrorSnackbar
          open={this.state.errorSnackbarOpen}
          errorMessage={this.state.snackbarMsg}
          closeErrorSnackbar={() => this.setState({ errorSnackbarOpen: false })}
        />

        <SuccessSnackbar
          open={this.state.successSnackbarOpen}
          successMessage={this.state.snackbarMsg}
          closeSuccessSnackbar={() =>
            this.setState({ successSnackbarOpen: false })
          }
        />
      </>
    );
  }
}
