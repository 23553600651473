import React, { useState } from "react";

const MapContext = React.createContext();

const MapProvider = props => {
  const [state, setState] = useState({
    postcodeShapesData: [],
    cachedTripShapesData: [],
    cachedPostcodeShapesData: [],
    cachedUnplannedShapesData: [],
    selectedTripShapes: [],
    prevSelectedTripShapes: [],
    tripShapeClicked: null,
    postCodeBlockedTripShape: [],
    tripShapesToShow: [],
    tripShapesOnMap: [],
    unplannedLayerId: null,
    mapAction: null,
    unplannedTripDetails: {},
    modifiedTripDetails: {},
    selectedPostcodes: [],
    postcodesToUnplan: [],
    postcodesToReplan: [],
    mapLoading: null
  });
  return (
    <MapContext.Provider value={[state, setState]}>
      {props.children}
    </MapContext.Provider>
  );
};

export { MapContext, MapProvider };
