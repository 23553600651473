import React, { useState, useEffect } from "react";
import TripInfoBox from "../Modals/TripInfoBox";
import { setMapStyle, initMap } from "./AdminMapController";
import CircularProgress from '@material-ui/core/CircularProgress';

const AdminMap = props => {

  const [depMapStatus, setDepMapStatus] = useState();

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {


    if (depMapStatus === null)
      alert('null');
    if (depMapStatus === 0) {
      //alert('0');
    }
    else if (depMapStatus === 1) {
      //initMap();
    }
    else {
      alert('other: ' + depMapStatus);
    }
  },[depMapStatus]);

if (props.mapStatus !== depMapStatus) {
  setDepMapStatus(props.mapStatus);
}


return (
  <>
    <div className="map-loading-overlay hidden" id="mapLoadingOverlay">
      <div className="flex-hvcenter">
        <CircularProgress size={20} />
        <span className="ml05">
          Loading...
        </span>
      </div>
    </div>

    <div id="map" className={props.mapStatus === 0 ? "hidden" : ""} />

    <div id="retailerInfo" className="ol-retailer-info hidden">
      <div className="retailer-info-header">
        <p>Retailer</p>
      </div>
      <div id="retailerInfoContent" className={props.mapStatus === 0 ? "hidden" : ""}></div>
    </div>
    <TripInfoBox />
    <div id="mapStyleControls" className={props.mapStatus === 0 ? "hidden" : "flex shadow-light"}>
      <p onClick={() => setMapStyle("Default")}>Kaart</p>
      <p onClick={() => setMapStyle("Satellite")}>Satelliet</p>
    </div>
  </>
);
};

export default AdminMap;
