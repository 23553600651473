import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import Icons from "../Icons";
import UseInput from "../../hooks/useInput";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import configSettings from "../../settings";
import CircularProgress from "@material-ui/core/CircularProgress";
import Api from "../../helpers/http";
import functions from "../../helpers/functions";
import useMap from "../../hooks/useMap";
import ModalMap from "../Map/ModalMap";

import { saveAs } from "file-saver";

const TripDetailsModal = props => {
  const [values, setValues] = useState({
    depotId: props.rData.DepotId,
    personeelsType: props.rData.Personeelstype,
    ritCode: props.rData.Ritcode,
    ritId: props.rData.Id,
    ritNaam: props.rData.Ritnaam,
    subCoId: props.rData.SubCoId ? props.rData.SubCoId : configSettings.EmptyGuid,
    volumeRit: props.rData.VolumeRit
  });

  const [tripShapesData, setTripShapeData] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [mapPreviewReady, setMapPreviewReady] = useState(false);

  const { getCachedTripShapesData, setCachedTripShapesData } = useMap();

  useEffect(() => {
    getTripShape();
  }, []);

  const getTripShape = async () => {
    let _tripShapesData;
    let _cachedTripShapesArr = [...getCachedTripShapesData()];
    let id = props.rData.Id;

    // if (!tripShapeIsCached(props.rData.Id)) {
    _tripShapesData = await Api.get(`${configSettings.TripShapesUrl}/${id}`);
    // }

    if (_tripShapesData !== undefined) {
      _tripShapesData.Color = functions.getColor();
      setTripShapeData(_tripShapesData);
      _cachedTripShapesArr.push(_tripShapesData);
      setCachedTripShapesData(_cachedTripShapesArr);
      setShowMap(true);
    }
  };

  const handleCheckBoxChange = name => event => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const onChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const makeTripPDF = () => {
    const canvas = document.querySelector("#modalMap canvas");
    let filename = "";

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(canvas.msToBlob(), "map.png");
    } else {
      canvas.toBlob(function(blob) {
        let fd = new FormData();
        fd.append("imageFile", blob);
        fetch(`${configSettings.ExportPDFUrl}/${props.rData.Id}`, {
          method: "POST",
          body: fd
        })
          .then(response => {
            response.headers.forEach(function(val, key) {
              if (val && val.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(val);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
                }
              }
            });
            return response.blob();
          })
          .then(blob => {
            saveAs(blob, filename);
          })
          .catch(function(error) {
            console.log("Error getting PDF: ", error.message);
          });
      });
    }
  };

  const mapRendered = () => {
    const modalElem = document.querySelector(".SimpleModal-paper-1");

    if (modalElem) {
          modalElem.style.overflowY = "scroll";
    }

    setMapPreviewReady(true);
  };

  return (
    <div className="Form route-details-form">
      <form>
        <div className="form-header flex jcsb">
          <p className="bold">Riteigenschappen</p>
          <p className="close-icon" onClick={() => props.closeModal()}>
            <Icons.Close />
          </p>
        </div>
        <div className="form-body flex">
          <div className="cell-left">
            <div className="row flex jcsb">
              <div className="col">
                <p className="label">
                  <FormLabel>Ritnaam</FormLabel>
                </p>
              </div>
              <div className="col">
                <UseInput
                  id="tripcode"
                  name="ritCode"
                  className="input-one-third mt0"
                  value={values.ritCode}
                  margin="normal"
                  onChange={onChange}
                  variant="outlined"
                />
                <UseInput
                  id="tripname"
                  name="ritNaam"
                  className="input-two-third mt0"
                  value={values.ritNaam}
                  onChange={onChange}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row flex jcsb">
              <div className="col">
                <p className="label">
                  <FormLabel>Uitvoerder</FormLabel>
                </p>
              </div>
              <div className="col">
                <Select
                  native
                  name="subCoId"
                  value={values.subCoId}
                  id="typeSelect"
                  className="input-whole mt0"
                  onChange={e => onChange(e.target.name, e.target.value)}
                >
                <option value={configSettings.EmptyGuid}>
                  Onbekend
                </option>
                {props.subContractors}
                </Select>
              </div>
            </div>
            <div className="row flex jcsb">
              <div className="col">
                <p className="label">
                  <FormLabel>Type</FormLabel>
                </p>
              </div>
              <div className="col">
                <Select
                  native
                  name="personeelsType"
                  value={values.personeelsType}
                  id="typeSelect"
                  className="input-whole mt0"
                  onChange={e => onChange(e.target.name, e.target.value)}
                >
                  <option value="" />
                  <option value="EP">EP</option>
                  <option value="SUB">SUB</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="cell-right">
            <div className="row">
              <FormControlLabel
                  control={
                    <Checkbox checked={values.volumeRit} onChange={handleCheckBoxChange('volumeRit')} value={values.volumeRit} />
                  }
                  label="Volumerit"
              />
            </div>
            <div className="row flex jcsb">
              <TextField
                className="input-whole mt0 invisible"
                defaultValue="Bare"
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className="row flex jcsb">
              <TextField
                id="outlined-bare"
                className="input-whole mt0 invisible"
                defaultValue="Bare"
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className="row flex">
              <Button
                size="small"
                className="normalcase"
                color="primary"
                variant="contained"
                onClick={() => props.submit(values)}
              >
                Opslaan
              </Button>
              <Button
                size="small"
                className="normalcase"
                variant="outlined"
                onClick={() => props.closeModal()}
              >
                Annuleren
              </Button>
            </div>
          </div>
        </div>
      </form>
      <div className="flex route-info">
        <div className="cell-left">
          <div className="row flex jcsb">
            <p className="invisible">-</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Stops</p>
            <p id="stops">{props.rData.Stops}</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Stuks</p>
            <p>{props.rData.Stuks}</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Dropduplicatie</p>
            <p>{props.rData.Dropduplicatie}</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Hitrate</p>
            <p>{+(props.rData.Hitratev * 100).toFixed(2)}%</p>
          </div>
          <br />
          <div className="row flex jcsb">
            <p className="bold">
              Volume (m<sup>3</sup>)
            </p>
            <p>{props.rData.Volume_m3}</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Gewicht (kg)</p>
            <p>{props.rData.Gewicht_kg}</p>
          </div>
          <br />
          <div className="row flex jcsb">
            <p className="bold">Efficiency totaaltijd</p>
            <p>{props.rData.Efficiency_totaaltijd}</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Efficiency besteltijd</p>
            <p>{props.rData.NormPc6}</p>
          </div>
          <br />
          <div className="row flex jcsb">
            <p className="bold">Interdrop (m)</p>
            <p>{+(props.rData.Interdrop * 1000).toFixed(2)}</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Bestelafstand (km)</p>
            <p>{props.rData.Bestelafstand}</p>
          </div>
          <div className="row flex jcsb">
            <p className="bold">Aanafafstand (km)</p>
            <p>{props.rData.Aanafafstand}</p>
          </div>
        </div>
        <div className="cell-right">
          <div className="row flex">
            <p className="col-55p bold" />
            <p className="col-20p ta-right bold">TI</p>
            <p className="col-25p ta-right bold">Realisatie</p>
          </div>
          <div className="row flex">
            <p className="col-55p bold">Laadtijd</p>
            <p className="col-20p ta-right">{props.rData.Voorbereidingstijd}</p>
            <p className="col-25p ta-right" />
          </div>
          <div className="row flex">
            <p className="col-55p bold">Aanaftijd</p>
            <p className="col-20p ta-right">{props.rData.Aanaftijd}</p>
            <p className="col-25p ta-right" />
          </div>
          <div className="row flex">
            <p className="col-55p bold">Besteltijd</p>
            <p className="col-20p ta-right">{props.rData.NormBesteltijdPc6}</p>
            <p className="col-25p ta-right">{props.rData.BesteltijdPc6}</p>
          </div>
          <div className="row flex">
            <p className="col-55p bold">Debrieftijd</p>
            <p className="col-20p ta-right">{props.rData.Afhandeltijd}</p>
            <p className="col-25p ta-right" />
          </div>
          <div className="row flex">
            <p className="col-55p bold">Overige tijd</p>
            <p className="col-20p ta-right">{props.rData.Overige_tijd}</p>
            <p className="col-25p ta-right" />
          </div>
          <hr />
          <div className="row flex">
            <p className="col-55p bold">Werkpakket dag</p>
            <p className="col-20p ta-right">{props.rData.Norm}</p>
            <p className="col-25p ta-right">{props.rData.Realisatie}</p>
          </div>
          <br />
          <div className="row flex">
            <p className="col-55p bold">Periode van inactiviteit</p>
            <p className="col-20p ta-right">{props.rData.Pauzetijd}</p>
            <p className="col-25p ta-right" />
          </div>
          <div className="row flex">
            <p className="col-55p bold">Aantal perioden van inactiviteit</p>
            <p className="col-20p ta-right">{props.rData.Pauze}</p>
            <p className="col-25p ta-right" />
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="flex">
            <Button
              id="export-png"
              size="small"
              className="normalcase"
              color="primary"
              variant="contained"
              disabled={!mapPreviewReady}
              onClick={() => makeTripPDF()}
            >
              Maak rit PDF
            </Button>
            {mapPreviewReady ? null : (
              <div className="flex mt05">
                <CircularProgress size={15} />
                <p className="preview-loading-text"> Preparing PDF...</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={` ${mapPreviewReady ? "modal-map-rdy" : "modal-map-notrdy"}`}
      >
        <div className="modal-map-container">
          <div className="flex-center">
            <h2 className="preview-header">Rit Preview</h2>
          </div>
          <div className="flex-center">
            {showMap ? (
              <ModalMap
                coords={{ Lon: 4.91372, Lat: 52.33296, Id: 21 }}
                shapeData={tripShapesData}
                mapRendered={() => mapRendered()}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TripDetailsModal;
