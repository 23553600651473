import React from "react";
import { Redirect, Route } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const LoginRequiredRoute = ({ component: Component, ...rest }) => {
  const { authStatus } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        (() => {
          switch (authStatus) {
            case "loggedIn":
              return <Component {...props} />;
            case "unknownUser":
              return <Redirect to="/login" />;

            default:
              return <Component {...props} />;
          }
        })()
      }
    />
  );
};

export default LoginRequiredRoute;
