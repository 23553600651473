import React from "react";

import "../../styles/app.scss";

const TripInfoBox = () => {
  return (
    <div className="TripInfoBox .fade-in-slow hidden" id="tripInfoBox">
      <div className="trip-info-box-container" id="tripInfoBoxContainer">
        <div className="info-box-header">
          <p>Rit</p>
        </div>
        <div className="info-box-content flex">
          <div className="info-box-content-left">
            <p>Ritnaam</p>
            <p>Type</p>
            <p>Uitvoerder</p>
            <p>Aantal stops</p>
            <p>Aantal stuks</p>
            <p>TI</p>
            <p>Realisatie</p>
          </div>
          <div className="info-box-content-right">
            <p id="tripInfoBoxTripname">-</p>
            <p id="tripInfoBoxType">-</p>
            <p id="tripInfoBoxDistributor">-</p>
            <p id="tripInfoBoxStops">-</p>
            <p id="tripInfoBoxParcels">-</p>
            <p id="tripInfoBoxTI">-</p>
            <p id="tripInfoBoxReal">-</p>
          </div>
        </div>
      </div>

      <div
        className="postcode-info-box-container"
        id="postcodeInfoBoxContainer"
      >
        <div className="info-box-header">
          <p>Postcode</p>
        </div>
        <div className="info-box-content flex">
          <div className="info-box-content-left">
            <p>Postcode</p>
            <p>Ritnaam</p>
            <p>Ritnaam org</p>
            <p>Straatnaam</p>
            <p>Aantal stops</p>
            <p>Aantal stuks</p>
            <p>TI</p>
            <p>Realisatie besteltijd</p>
            <p>Pauzetijd</p>
            <p>Retailer</p>
          </div>
          <div className="info-box-content-right">
            <p id="postcodeInfoBoxPostcode">-</p>
            <p id="postcodeInfoBoxRitnaam">-</p>
            <p id="postcodeInfoBoxRitnaamOrg">-</p>
            <p id="postcodeInfoBoxStreetName">-</p>
            <p id="postcodeInfoBoxStops">-</p>
            <p id="postcodeInfoBoxParcels">-</p>
            <p id="postcodeInfoBoxTI">-</p>
            <p id="postcodeInfoBoxReal">-</p>
            <p id="postcodeInfoBoxPauzetijd">-</p>
            <p id="postcodeInfoBoxRetailer">-</p>
          </div>
        </div>
      </div>

      <div
        className="unplanned-info-box-container"
        id="unplannedInfoBoxContainer"
      >
        <div className="info-box-header">
          <p>Postcode</p>
        </div>
        <div className="info-box-content flex">
          <div className="info-box-content-left">
            <p>Postcode</p>
            <p>Ritnaam</p>
            <p>Ritnaam org</p>
            <p>Straatnaam</p>
            <p>Aantal stops</p>
            <p>Aantal stuks</p>
            <p>TI</p>
            <p>Realisatie besteltijd</p>
            <p>Retailer</p>
          </div>
          <div className="info-box-content-right">
            <p id="unplannedInfoBoxPostcode">-</p>
            <p id="unplannedInfoBoxRitnaam">000 Ongepland</p>
            <p id="unplannedInfoBoxRitnaamOrg">-</p>
            <p id="unplannedInfoBoxStreetName">-</p>
            <p id="unplannedInfoBoxStops">-</p>
            <p id="unplannedInfoBoxParcels">-</p>
            <p id="unplannedInfoBoxTI">-</p>
            <p id="unplannedInfoBoxReal">-</p>
            <p id="unplannedInfoBoxRetailer">-</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripInfoBox;
