import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import Icons from "../Icons";
import UseInput from "../../hooks/useInput";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import configSettings from "../../settings";

const CreateTripModal = props => {
  const [values, setValues] = useState({
    depotId: props.depotSelected,
    scenarioId: props.scenarioId,
    personeelsType: "",
    ritCode: "",
    ritId: -1,
    ritNaam: "",
    uitvoerder: ""
  });

  const onChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <div className="Form route-details-form">
      <form>
        <div className="form-header flex jcsb">
          <p className="bold">Rit toevoegen</p>
          <p className="close-icon" onClick={() => props.closeModal()}>
            <Icons.Close />
          </p>
        </div>
        <div className="form-body flex">
          <div className="cell">
            <div className="row flex jcsb">
              <div className="col">
                <p className="label">
                  <FormLabel>Ritnaam</FormLabel>
                </p>
              </div>
              <div className="col">
                <UseInput
                  id="tripcode"
                  name="ritCode"
                  className="input-one-third mt0"
                  value={values.ritCode}
                  margin="normal"
                  onChange={onChange}
                  variant="outlined"
                />
                <UseInput
                  id="tripname"
                  name="ritNaam"
                  className="input-two-third mt0"
                  value={values.ritNaam}
                  onChange={onChange}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row flex jcsb">
              <div className="col">
                <p className="label">
                  <FormLabel>Uitvoerder</FormLabel>
                </p>
              </div>
              <div className="col">
                <Select
                  native
                  name="subCoId"
                  value={values.subCoId}
                  id="typeSelect"
                  className="input-whole mt0"
                  onChange={e => onChange(e.target.name, e.target.value)}
                >
                <option value={configSettings.EmptyGuid}>
                  Onbekend
                </option>
                {props.subContractors}
                </Select>
              </div>
            </div>
            <div className="row flex jcsb">
              <div className="col">
                <p className="label">
                  <FormLabel>Type</FormLabel>
                </p>
              </div>
              <div className="col">
                <Select
                  native
                  name="personeelsType"
                  value={values.personeelsType}
                  id="typeSelect"
                  className="input-whole mt0"
                  onChange={e => onChange(e.target.name, e.target.value)}
                >
                  <option value="" />
                  <option value="EP">EP</option>
                  <option value="SUB">SUB</option>
                </Select>
              </div>
            </div>
          </div>

          <div className="cell">
            <div className="row flex jcsb">
              <TextField
                className="input-whole mt0 invisible"
                defaultValue="Bare"
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className="row flex jcsb">
              <TextField
                id="outlined-bare"
                className="input-whole mt0 invisible"
                defaultValue="Bare"
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className="row flex">
              <Button
                size="small"
                className="normalcase"
                color="primary"
                variant="contained"
                onClick={() => props.submit(values)}
              >
                Opslaan
              </Button>
              <Button
                size="small"
                className="normalcase"
                variant="outlined"
                onClick={() => props.closeModal()}
              >
                Annuleren
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default CreateTripModal;
