import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";

import "../../styles/app.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    position: "absolute",
    backgroundColor: "white",
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

export default function PostcodeInfoModal(props) {
  useEffect(() => {
    // console.log(props);
  }, [props.data]);

  return (
    <Modal
      aria-labelledby="postcodeInfo-details-modal-title"
      aria-describedby="postcodeInfo-details-modal-description"
      open={props.postcodeInfoModalVisible}
      onClose={props.closePostcodeInfoModal}
    >
      <div style={getModalStyle()}>
        <div tabIndex={-1} className="modal-outer">
          <Paper
            className="PostcodeInfoModal .fade-in-slow "
            id="postcodeInfoModal"
          >
            <div className="modal-header flex">
              <p className="bold">Postcode Details</p>
            </div>
            <div className="modal-content">
              <div className="flex">
                <div className="info-names bold">
                  <p>Straatnaam:</p>
                  <p>Stops:</p>
                  <p>Stuks:</p>
                </div>
                {props.data ? (
                  <div className="info-values ml1">
                    <p>{props.data.StreetName ? props.data.StreetName : "-"}</p>
                    <p>{props.data.Stops}</p>
                    <p>{props.data.Parcels}</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="modal-footer flex-reverse">
              <Button
                className="normalcase"
                color="primary"
                variant="contained"
                onClick={() => props.closePostcodeInfoModal()}
              >
                Sluiten
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    </Modal>
  );
}
