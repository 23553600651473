import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import AdminMap from "../components/Map/AdminMap";
import TrapInfoContent from "../components/TrapInfoContent/TrapInfoContent";
import { MapProvider } from "../context/MapContext";
import "../styles/app.scss";

const TrapScreen = () => {

  const toggleMapStatus = (fvMapStatus) => {
    setMapStatus(fvMapStatus);
  }

  const [mapStatus, setMapStatus] = useState(0); // start: map on, variables off (TrapInfoContent)

  useEffect(() => {
  }, [mapStatus]);


  return (
    <div id="AdminScreen">
      <div className="flex-column">
        <Header />
        <section className="content-wrapper flex">
          <MapProvider>
            <AdminMap
            mapStatus={mapStatus}
            />
            <TrapInfoContent
            toggleMapStatus={toggleMapStatus}
            />
          </MapProvider>
        </section>
      </div>
    </div>
  );
};

export default TrapScreen;
