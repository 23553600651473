import depotIcon from "../../depot.png";
import retailerIcon from "../../retailerlocation.png";

const ol = window.ol;
const featureStyles = {
  depotMarkerStyle: () => {
    return new ol.style.Style({
      image: new ol.style.Icon({
        opacity: 1,
        src: depotIcon,
        scale: 0.9
      }),
      zIndex: 99
    });
  },
  retailerMarkerStyle: () => {
    return new ol.style.Style({
      image: new ol.style.Icon({
        opacity: 0.9,
        src: retailerIcon,
        scale: 0.1
      }),
      zIndex: 99
    });
  }
}
export default featureStyles;
