const UserRoles = {
    Administrator: "Administrator",
    Beheerder: "Beheerder",
    Depot: "Depot",
    Sourcing: "Sourcing",
    Control: "Control",
    HSAdviseur: "HS Adviseur",
    Hoofdkantoor: "Hoofdkantoor"
};
export default UserRoles;
