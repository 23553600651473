import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import { withRouter } from "react-router-dom";
import { TextField, Select, MenuItem, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import Api from "../../helpers/http";
import configSettings from "../../settings";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import ErrorSnackbar from "../../components/Utils/ErrorSnackbar";

import Subcontractor from "./subcontractor";
import Hours from "./hours";
import Kilometers from "./kilometers";
import Subcharges from "./subcharges";
import ExportFinish from "./export-finish";

import "../../styles/contract-details.scss";
import Icons from "../../components/Icons";
import CurrencyInput from "react-currency-input";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";
import ContractDetailsGridLoader from "../../components/Utils/ContractDetailsGridLoader";
import { format } from "date-fns";
registerLocale("nl", nl);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 700,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const tabIndexBases = {
  contract: 0,
  subcontractorCategory: 5000,
  hoursCategory: 10000,
  kilometersCategory: 20000,
  subchargesCategory: 30000,
  exportFinishCategory: 40000,
};

const ContractDetails = (props) => {
  const classes = useStyles();
  const [AanmaakdatumTiRequest, setAanmaakdatumTiRequest] = useState(null);
  const [ContractId, setContractId] = useState(0);
  const [CurrentTiRequestId, setCurrentTiRequestId] = useState(0);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [ErrorSnackbarMsg, setErrorSnackbarMsg] = useState(
    "Er is iets fout gegaan bij het opslaan van de gegevens"
  );
  const [expanded, setExpanded] = useState([]);
  const [FieldValueOnFocusStart, setFieldValueOnFocusStart] = useState(null);
  const [FocusedInputName, setFocusedInputName] = useState(null);
  const focusedFieldValue = useRef(null);
  //const [LastBlurredInputName, setLastBlurredInputName] = useState(null);
  const lastBlurredInputValue = useRef(null);
  const [HoursCategory, setHoursCategory] = useState({});
  const [IsSnackbarAutoClosing, setIsSnackbarAutoClosing] = useState(true);
  const [KilometersCategory, setKilometersCategory] = useState({});
  const [SubchargesCategory, setSubchargesCategory] = useState({});
  const [SubCoId, setSubCoId] = useState(null);
  const [SubcontractorCategory, setSubcontractorCategory] = useState({
    Naam: "",
    Locatie: "",
    OorspronkelijkTarief: 0,
    HuidigAantalLandelijkeRitten: 0,
    Contractdagen: 0,
    Referentieweken: "",
    Stoptariefindicatie: 0,
    GewogenStoptariefTI: 0,
    Stoptariefafspraak: false,
    TariferingObv: 0,
    RitOtBt: 200,
    Begindatum: null,
    Einddatum: null,
  });

  useEffect(() => {
    const headerTitle = document.getElementById("appHeaderTitle");
    headerTitle.innerHTML = "Tariefmodel";

    const pathArr = props.location.pathname.split("/");
    const TIRequestId = pathArr[2];
    const SubcontractorId = pathArr[3];

    if (!TIRequestId || !SubcontractorId) {
      props.history.push(`/subcontractors-overzicht/`);
    } else {
      setCurrentTiRequestId(TIRequestId);
      setSubCoId(SubcontractorId);
      getContractDetails(TIRequestId, SubcontractorId);
    }

    return () => {};
  }, []);

  const getContractDetails = async (tiRequestId, subCoId) => {
    const subCoParam = subCoId ? `/${subCoId}` : "";
    const response = await Api.get(
      `${configSettings.ContractDetailsHours}/${tiRequestId}${subCoParam}`
    );

    if (response && Object.keys(response).length) {
      setContractId(response.Id);
      setAanmaakdatumTiRequest(response.AanmaakdatumTiRequest);

      // Subcontractor data
      const subcontractorCategory = response.SubcontractorCategory;
      setSubcontractorCategory(subcontractorCategory);

      // Hours data
      const hoursCategory = response.HoursCategory;
      setHoursCategory(hoursCategory);

      // Kilometers data
      const kilometersCategory = response.KilometersCategory;
      setKilometersCategory(kilometersCategory);

      // Subcharges data
      const subchargesCategory = response.SubchargesCategory;
      setSubchargesCategory(subchargesCategory);
    } else {
      setErrorSnackbarMsg(
        "Dit tariefindicatie contract is niet beschikbaar. U wordt herverwezen..."
      );
      setIsSnackbarAutoClosing(false);
      setErrorSnackbarOpen(true);
      setTimeout(() => {
        props.history.push(`/subcontractors-overzicht/`);
      }, 5000);
    }
  };

  const handleExpansionPanelPress = (e) => {
    if (e.keyCode === 32 && e.keyCode === 13) {
      e.target.ExpansionPanel.expanded = !e.target.ExpansionPanel.expanded;
    }
  };

  const handleCurrencyFieldChange = (_, value, name) => {
    const subco = {
      ...SubcontractorCategory,
    };

    switch (name) {
      case "OorspronkelijkTariefField":
        subco.OorspronkelijkTarief = value ? parseFloat(value) : 0;
        focusedFieldValue.current = value;
        break;
      case "StoptariefafspraakField":
        subco.Stoptariefafspraak = value ? parseFloat(value) : 0;
        focusedFieldValue.current = value;
        break;
      default:
        break;
    }

    setSubcontractorCategory(subco);
  };

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const subco = {
      ...SubcontractorCategory,
    };

    switch (name) {
      case "ContractdagenField":
        subco.Contractdagen = value ? parseInt(value, 0) : 0;
        break;
      case "LocatieField":
        subco.Locatie = value ? value : "";
        break;
      default:
        break;
    }

    setSubcontractorCategory(subco);
  };

  const handleNumericFieldChange = (e) => {
    const subco = {
      ...SubcontractorCategory,
    };

    if (e.target.value !== "") {
      if (e.target.value.match(/^\d+$/)) {
        subco.HuidigAantalLandelijkeRitten = parseInt(e.target.value, 10);
        focusedFieldValue.current = parseInt(e.target.value, 10);
      }
    } else {
      if (e.target.value === "") {
        subco.HuidigAantalLandelijkeRitten = 0;
        focusedFieldValue.current = 0;
      }
    }

    setSubcontractorCategory(subco);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const handleSelectChange = (e) => {
    switch (e.target.name) {
      case "ContractdagenField":
        SubcontractorCategory.Contractdagen = e.target.value;
        break;
      case "TariferingObvField":
        SubcontractorCategory.TariferingObv = e.target.value;
        break;
      case "RitOtBtField":
        SubcontractorCategory.RitOtBt = e.target.value;
        break;
      default:
        break;
    }

    const selectedValue = e.target.value ? "true" : "false";

    const locationRowLoaderElem = document.getElementById("locationRowLoader");
    locationRowLoaderElem.classList.add("visible");

    updateSubcontractorCall(e, selectedValue);
  };

  const handleTabChange = (panel) => {
    const expandedPanels = [...expanded];

    if (expandedPanels.includes(panel)) {
      expandedPanels.splice(expandedPanels.indexOf(panel), 1);
    } else {
      expandedPanels.push(panel);
    }

    setExpanded(expandedPanels);
  };

  const onBlurField = (e, name = e.target.name) => {
    let field;

    setFocusedInputName(null);

    switch (name) {
      case "NaamField":
        field = "Naam";
        break;
      case "OorspronkelijkTarief":
        field = "OorspronkelijkTarief";
        break;
      case "HuidigAantalLandelijkeRittenField":
        field = "HuidigAantalLandelijkeRitten";
        break;
      case "LocatieField":
        field = "Locatie";
        break;
      case "ReferentiewekenField":
        field = "Referentieweken";
        break;
      case "GewogenStoptariefTiField":
        field = "GewogenStoptariefTi";
        break;
      default:
        break;
    }

    if (`${FieldValueOnFocusStart}` !== `${focusedFieldValue.current}`) {
      if (
        field === "Naam" ||
        field === "OorspronkelijkTarief" ||
        field === "HuidigAantalLandelijkeRitten"
      ) {
        const uitvoerderRowLoaderElem = document.getElementById(
          "uitvoerderRowLoader"
        );
        uitvoerderRowLoaderElem.classList.add("visible");
      } else {
        const locationRowLoaderElem = document.getElementById(
          "locationRowLoader"
        );
        locationRowLoaderElem.classList.add("visible");
      }

      //setLastBlurredInputName(field);
      lastBlurredInputValue.current = e.target.value;

      if (field === "OorspronkelijkTarief") {
        updateSubcontractorCall(e, focusedFieldValue.current, field);
      } else {
        updateSubcontractorCall(e, e.target.value, field);
      }
    }
  };

  const onFocusField = (e, value = e.target.value) => {
    e.target.select();

    setFocusedInputName(e.target.name);
    focusedFieldValue.current = value;
    setFieldValueOnFocusStart(value);
  };

  const revertValueOnBadRequest = (name, prevFieldValueOnFocusStart) => {
    const subco = {
      ...SubcontractorCategory,
    };

    console.log("reverting value");

    switch (name) {
      case "OorspronkelijkTariefField":
        subco.OorspronkelijkTarief = prevFieldValueOnFocusStart;
        break;
      case "HuidigAantalLandelijkeRittenField":
        subco.HuidigAantalLandelijkeRitten = prevFieldValueOnFocusStart;
        break;
      case "ContractdagenField":
        subco.Contractdagen = prevFieldValueOnFocusStart;
        break;
      case "TariferingObvField":
        subco.TariferingObvdagen = prevFieldValueOnFocusStart;
        break;
      case "RitOtBtField":
        subco.RitOtBt = prevFieldValueOnFocusStart;
        break;
      case "StoptariefafspraakField":
        subco.Stoptariefafspraak = prevFieldValueOnFocusStart;
        break;
      case "Begindatum":
        subco.Begindatum = prevFieldValueOnFocusStart;
        break;
      case "Einddatum":
        subco.Einddatum = prevFieldValueOnFocusStart;
        break;
      default:
        break;
    }

    setSubcontractorCategory(subco);
  };

  const updateSubcontractorCall = async (
    e,
    selectedValue = null,
    field = null
  ) => {
    const prevFieldValueOnFocusStart = selectedValue
      ? selectedValue
      : FieldValueOnFocusStart;
    setFieldValueOnFocusStart(null);

    const name = e.target.name;

    const subco = {
      ...SubcontractorCategory,
    };

    if (field) {
      subco[field] = selectedValue;
      setSubcontractorCategory(subco);
    }

    console.log(subco);

    fetch(configSettings.UpdateSubcontractorUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(subco),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        getContractDetails(CurrentTiRequestId, SubCoId);
      })
      .catch((error) => {
        console.log(error);

        setIsSnackbarAutoClosing(true);
        setErrorSnackbarMsg(
          "Er is iets fout gegaan bij het opslaan van de gegevens"
        );
        setErrorSnackbarOpen(true);

        if (prevFieldValueOnFocusStart) {
          revertValueOnBadRequest(name, prevFieldValueOnFocusStart);
        }
      })
      .finally(() => {
        const uitvoerderRowLoaderElem = document.getElementById(
          "uitvoerderRowLoader"
        );

        uitvoerderRowLoaderElem.classList.remove("visible");
        const locationRowLoaderElem = document.getElementById(
          "locationRowLoader"
        );

        locationRowLoaderElem.classList.remove("visible");
      });
  };

  const onBlurDateField = (e, index) => {
    const name = e.target.name;
    let Field;

    switch (name) {
      case "Begindatum":
        Field = "Begindatum";
        break;
      case "Einddatum":
        Field = "Einddatum";
        break;
      default:
        break;
    }

    let correctedDateFormat;
    let dateSplit;
    let rowField;

    if (SubcontractorCategory[Field]) {
      if (FieldValueOnFocusStart.indexOf("/") > -1) {
        dateSplit = FieldValueOnFocusStart.split("/");
        correctedDateFormat =
          dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2];
        // console.log(correctedDateFormat);
      } else if (FieldValueOnFocusStart.indexOf("T") > -1) {
        dateSplit = FieldValueOnFocusStart.split("T")[0];
        dateSplit = dateSplit.split("-");
        correctedDateFormat =
          dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
        // console.log(correctedDateFormat);
      }

      let rfd = new Date(SubcontractorCategory[Field]);

      rowField = `${rfd.getMonth() + 1}/${rfd.getDate()}/${rfd.getFullYear()}`;

      if (correctedDateFormat && correctedDateFormat[0] === "0") {
        correctedDateFormat = correctedDateFormat.substr(1);
      } else {
        correctedDateFormat = null;
      }
    } else {
      correctedDateFormat = null;
    }
    if (correctedDateFormat !== rowField) {
      const locationRowLoaderElem = document.getElementById(
        "locationRowLoader"
      );

      locationRowLoaderElem.classList.add("visible");
      updateSubcontractorCall(e);
    }
  };

  const handleDateChange = (name, date) => {
    const subcontractorCategory = { ...SubcontractorCategory };

    const newDate = date ? format(date, "yyyy-MM-dd'T'HH:mm:ss") : null;

    switch (name) {
      case "Begindatum":
        subcontractorCategory.Begindatum = newDate;
        break;
      case "Einddatum":
        subcontractorCategory.Einddatum = newDate;
        break;
      default:
        break;
    }

    setSubcontractorCategory(subcontractorCategory);
  };

  return (
    <div>
      <Header />
      <div id="contractDetailsScreen" className="TariffModel-screens">
        <div className="contract-details-header">
          <div className="contract-details-title">
            <div className="flex space-b">
              <h1 className="m0 title">Contractdetails</h1>
            </div>
          </div>

          {Object.keys(SubcontractorCategory).length &&
          SubcontractorCategory.Subcontractor ? (
            <div className="subcontractor-container">
              <div className="flex header-row">
                <p className="field lg">Uitvoerder</p>
                <p className="field sm">
                  <Tooltip
                    title="Als vervoerder reeds verhoging heeft gehad, zorg dan dat het tarief van ná de indexatie in veld huidig stoptarief staat"
                    placement="top-end"
                  >
                    <span>Oorspronkelijk tarief</span>
                  </Tooltip>
                </p>
                <p className="field sm">Huidig aantal landelijke ritten</p>
                <p className="field sm double">Contractdagen</p>
                <p className="field sm double">Tarifering obv</p>
                <div className="field sm double">
                  <Tooltip
                    title="Belangrijk is of de rit na een bepaalde einddatum eindigt. Niet van belang is of het specifieke stoptarief na een bepaalde einddatum eindigt"
                    placement="top-end"
                  >
                    <p>Rit OT/BT</p>
                  </Tooltip>
                </div>
              </div>
              <div className="flex input-row">
                <div className="grid-custom-loader" id="uitvoerderRowLoader">
                  <Icons.Loading />
                </div>
                <TextField
                  className="field lg readonly"
                  name="NaamField"
                  onBlur={(e) => onBlurField(e)}
                  onChange={(e) => handleFieldChange(e)}
                  onFocus={(e) => onFocusField(e)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  value={
                    SubcontractorCategory.Naam ? SubcontractorCategory.Naam : ""
                  }
                  variant="outlined"
                />
                <div className="MuiFormControl-root MuiTextField-root field sm amount">
                  <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                    <CurrencyInput
                      className="MuiInputBase-input MuiOutlinedInput-input field-outline"
                      decimalSeparator=","
                      thousandSeparator="."
                      precision="2"
                      prefix="€   "
                      value={
                        SubcontractorCategory.OorspronkelijkTarief
                          ? SubcontractorCategory.OorspronkelijkTarief
                          : 0
                      }
                      selectAllOnFocus
                      onKeyDown={(e) => handleKeyPress(e)}
                      onFocus={(e) =>
                        onFocusField(
                          e,
                          SubcontractorCategory.OorspronkelijkTarief
                        )
                      }
                      onChange={(_, val) =>
                        handleCurrencyFieldChange(
                          _,
                          val,
                          "OorspronkelijkTariefField"
                        )
                      }
                      onBlur={(e) => onBlurField(e, "OorspronkelijkTarief")}
                    />
                  </div>
                </div>
                <TextField
                  className="field sm"
                  name="HuidigAantalLandelijkeRittenField"
                  onBlur={(e) => onBlurField(e)}
                  onChange={(e) => handleNumericFieldChange(e)}
                  onFocus={(e) => onFocusField(e)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  value={
                    FocusedInputName === "HuidigAantalLandelijkeRittenField"
                      ? focusedFieldValue.current
                      : SubcontractorCategory.HuidigAantalLandelijkeRitten
                  }
                  variant="outlined"
                />
                <Select
                  className="field sm double"
                  name={"ContractdagenField"}
                  onChange={(e) => handleSelectChange(e)}
                  value={SubcontractorCategory.Contractdagen}
                >
                  <MenuItem value={3}>Maandag tm zaterdag</MenuItem>
                  <MenuItem value={2}>Dinsdag tm zaterdag</MenuItem>
                </Select>
                <Select
                  className="field sm double"
                  name={"TariferingObvField"}
                  onChange={(e) => handleSelectChange(e)}
                  value={SubcontractorCategory.TariferingObv}
                >
                  <MenuItem value={1}>TI</MenuItem>
                  <MenuItem value={2} disabled>Vorig contracttarief + verhoging</MenuItem>
                  <MenuItem value={3}>Afbouwregeling</MenuItem>
                </Select>
                <Select
                    className="field sm double"
                    name={"RitOtBtField"}
                    onChange={(e) => handleSelectChange(e)}
                    value={SubcontractorCategory.RitOtBt}
                    disabled={SubcontractorCategory.TariferingObv === 3}
                >
                  <MenuItem value={200}>Onbepaalde tijd</MenuItem>
                  <MenuItem value={100}>Bepaalde tijd</MenuItem>
                </Select>
              </div>
              <div className="flex header-row">
                <p className="field lg">Depot</p>
                <p className="field sm">Referentieweken</p>
                <p className="field sm"></p>
                <p className="field sm">Gewogen stoptarief TI</p>
                <p className="field sm">
                  <Tooltip
                    title="Alleen i.v.m. beleid langzame afbouw tarief mag deze afwijken van indicatie"
                    placement="top-end"
                  >
                    <span>Stoptarief afspraak</span>
                  </Tooltip>
                </p>
                <p className="field sm double">Begindatum</p>
                <p className="field sm double">Einddatum</p>
              </div>
              <div className="flex input-row">
                <div className="grid-custom-loader" id="locationRowLoader">
                  <Icons.Loading />
                </div>
                <TextField
                  className="field lg readonly"
                  name="LocatieField"
                  onBlur={(e) => onBlurField(e)}
                  onChange={(e) => handleFieldChange(e)}
                  onFocus={(e) => onFocusField(e)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    SubcontractorCategory.Locatie
                      ? SubcontractorCategory.Locatie
                      : ""
                  }
                  variant="outlined"
                />
                <TextField
                  className="field sm readonly"
                  name="ReferentiewekenField"
                  onBlur={(e) => onBlurField(e)}
                  onChange={(e) => handleFieldChange(e)}
                  onFocus={(e) => onFocusField(e)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  value={
                    SubcontractorCategory.Referentieweken
                      ? SubcontractorCategory.Referentieweken
                      : ""
                  }
                  variant="outlined"
                />
                <div className="field sm"></div>
                <TextField
                  className="field sm readonly currency"
                  name="GewogenStoptariefTIField"
                  onBlur={(e) => onBlurField(e)}
                  onChange={(e) => handleFieldChange(e)}
                  onFocus={(e) => onFocusField(e)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  readOnly
                  type="text"
                  value={
                    SubcontractorCategory.GewogenStoptariefTi
                      ? SubcontractorCategory.GewogenStoptariefTi
                      : 0
                  }
                  variant="outlined"
                />
                <div className="MuiFormControl-root MuiTextField-root field sm amount">
                  <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                    <CurrencyInput
                      className="MuiInputBase-input MuiOutlinedInput-input field-outline"
                      decimalSeparator=","
                      thousandSeparator="."
                      precision="2"
                      prefix="€   "
                      value={
                        SubcontractorCategory.Stoptariefafspraak
                          ? SubcontractorCategory.Stoptariefafspraak
                          : 0
                      }
                      selectAllOnFocus
                      onKeyDown={(e) => handleKeyPress(e)}
                      onFocus={(e) =>
                        onFocusField(
                          e,
                          SubcontractorCategory.Stoptariefafspraak
                        )
                      }
                      onChange={(_, val) =>
                        handleCurrencyFieldChange(
                          _,
                          val,
                          "StoptariefafspraakField"
                        )
                      }
                      onBlur={(e) => onBlurField(e, "StoptariefafspraakField")}
                    />
                  </div>
                </div>
                <div className="datepicker-field sm double">
                <DatePicker
                    locale="nl"
                    name="Begindatum"
                    dateFormat="dd-MM-yyyy"
                    showPopperArrow={false}
                    onFocus={e => onFocusField(e)}
                    selected={
                    SubcontractorCategory.Begindatum
                        ? new Date(SubcontractorCategory.Begindatum)
                        : null
                    }
                    onChange={date => handleDateChange("Begindatum", date)}
                    onBlur={e => onBlurDateField(e)}
                />
                  </div>
                {SubcontractorCategory.RitOtBt === 100 || SubcontractorCategory.TariferingObv === 3 ? (
                  <div className="datepicker-field sm double">
                    <DatePicker
                      locale="nl"
                      dateFormat="dd-MM-yyyy"
                      name="Einddatum"
                      showPopperArrow={false}
                      selected={
                        SubcontractorCategory.Einddatum
                          ? new Date(SubcontractorCategory.Einddatum)
                          : null
                      }
                      onFocus={(e) => onFocusField(e)}
                      onChange={(date) => handleDateChange("Einddatum", date)}
                      onBlur={(e) => onBlurDateField(e)}
                    />
                  </div>
                ) : (
                  <TextField
                    variant="outlined"
                    className="field sm double readonly"
                    value={""}
                    tabIndex="-1"
                    readOnly
                  />
                )}
              </div>
            </div>
          ) : null}

          {Object.keys(SubcontractorCategory).length &&
          SubcontractorCategory.Subcontractor ? (
            <Subcontractor
              contractId={ContractId}
              subContractorCategory={SubcontractorCategory}
              tabIndexBase={tabIndexBases.subContractorCategory}
              updateCategories={() =>
                getContractDetails(CurrentTiRequestId, SubCoId)
              }
            />
          ) : (
            <ContractDetailsGridLoader />
          )}
        </div>
        <div id="contractDetailsAccordion" className={classes.root}>
          <ExpansionPanel
            expanded={expanded.includes("hours")}
            onChange={() => handleTabChange("hours")}
          >
            <ExpansionPanelSummary
              aria-controls="hoursbh-content"
              id="hoursbh-header"
              onKeyPress={(e) => handleExpansionPanelPress(e)}
              tabIndex={tabIndexBases.hoursCategory}
            >
              <Typography className={classes.heading}>
                {Object.keys(HoursCategory).length === 0 ? (
                  <Icons.Loading />
                ) : expanded.includes("hours") ? (
                  <RemoveCircleOutline />
                ) : (
                  <AddCircleOutline />
                )}
                Uren
                <span className="panel-loader"></span>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {Object.keys(HoursCategory).length &&
              expanded.includes("hours") ? (
                <Hours
                  contractId={ContractId}
                  hoursCategory={HoursCategory}
                  tabIndexBase={tabIndexBases.hoursCategory}
                  updateCategories={() =>
                    getContractDetails(CurrentTiRequestId, SubCoId)
                  }
                />
              ) : (
                <div className="flex-column"></div>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded.includes("kilometers")}
            onChange={() => handleTabChange("kilometers")}
          >
            <ExpansionPanelSummary
              aria-controls="kilometersbh-content"
              id="kilometersbh-header"
              onKeyPress={(e) => handleExpansionPanelPress(e)}
              tabIndex={tabIndexBases.kilometersCategory}
            >
              <Typography className={classes.heading}>
                {Object.keys(KilometersCategory).length === 0 ? (
                  <Icons.Loading />
                ) : expanded.includes("kilometers") ? (
                  <RemoveCircleOutline />
                ) : (
                  <AddCircleOutline />
                )}
                Kilometers
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {Object.keys(KilometersCategory).length &&
              expanded.includes("kilometers") ? (
                <Kilometers
                  contractId={ContractId}
                  kilometersCategory={KilometersCategory}
                  tabIndexBase={tabIndexBases.kilometersCategory}
                  updateCategories={() =>
                    getContractDetails(CurrentTiRequestId, SubCoId)
                  }
                />
              ) : (
                <div className="flex-column"></div>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded.includes("subcharges")}
            onChange={() => handleTabChange("subcharges")}
          >
            <ExpansionPanelSummary
              aria-controls="subchargesbh-content"
              id="subchargesbh-header"
              onKeyPress={(e) => handleExpansionPanelPress(e)}
              tabIndex={tabIndexBases.subchargesCategory}
            >
              <Typography className={classes.heading}>
                {Object.keys(SubchargesCategory).length === 0 ? (
                  <Icons.Loading />
                ) : expanded.includes("subcharges") ? (
                  <RemoveCircleOutline />
                ) : (
                  <AddCircleOutline />
                )}
                Toeslagen
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {Object.keys(SubchargesCategory).length &&
              expanded.includes("subcharges") ? (
                <Subcharges
                  contractId={ContractId}
                  subchargesCategory={SubchargesCategory}
                  tabIndexBase={tabIndexBases.subchargesCategory}
                  tiRequestId={CurrentTiRequestId}
                  updateCategories={() =>
                    getContractDetails(CurrentTiRequestId, SubCoId)
                  }
                />
              ) : (
                <div className="flex-column"></div>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded.includes("exportFinish")}
            onChange={() => handleTabChange("exportFinish")}
          >
            <ExpansionPanelSummary
              aria-controls="exportFinishbh-content"
              id="exportFinishbh-header"
              onKeyPress={(e) => handleExpansionPanelPress(e)}
              tabIndex={tabIndexBases.exportFinishCategory}
            >
              <Typography className={classes.heading}>
                {Object.keys(SubchargesCategory).length === 0 ? (
                  <Icons.Loading />
                ) : expanded.includes("exportFinish") ? (
                  <RemoveCircleOutline />
                ) : (
                  <AddCircleOutline />
                )}
                Exporteren / Afsluiten
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {ContractId && AanmaakdatumTiRequest ? (
                <ExportFinish
                  contractId={ContractId}
                  aanmaakdatumTiRequest={AanmaakdatumTiRequest}
                />
              ) : (
                ""
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
      {errorSnackbarOpen && (
        <ErrorSnackbar
          open={errorSnackbarOpen}
          errorMessage={ErrorSnackbarMsg}
          closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
          autoCloseSeconds={IsSnackbarAutoClosing ? 3 : false}
        />
      )}
    </div>
  );
};
export default withRouter(ContractDetails);
