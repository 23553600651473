import React, { useState, useEffect, useRef } from "react";
import { MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import Icons from "../../components/Icons";
import configSettings from "../../settings";
import ErrorSnackbar from "../../components/Utils/ErrorSnackbar";

export default function Subcharges(props) {
  const editableFieldsPerRow = 4;

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMsg] = useState(
    "Er is iets fout gegaan bij het opslaan van de gegevens"
  );
  const [IsSnackbarAutoClosing, setIsSnackbarAutoClosing] = useState(true);
  const [TabIndexBase, setTabIndexBase] = useState(0);
  const [SubchargesData, setSubchargesData] = useState([]);
  const [Explanation, setExplanation] = useState("");
  const [ExplanationUpdating, setExplanationUpdating] = useState(false);
  const [ExplanationValueOnFocus, setExplanationValueOnFocus] = useState("");

  const [FieldValueOnFocusStart, setFieldValueOnFocusStart] = useState(null);
  const [FocusedIndex, setFocusedIndex] = useState(null);
  const [FocusedInputName, setFocusedInputName] = useState(null);
  const focusedFieldValue = useRef("");
  const lastBlurredInputName = useRef(null);
  const lastBlurredInputValue = useRef(null);
  const lastBlurredInputIndex = useRef(null);

  useEffect(() => {
    setTabIndexBase(props.tabIndexBase);
  }, []);

  useEffect(() => {
    const subchargesCategory = props.subchargesCategory;
    const subchargesData = [...subchargesCategory.Subcharges];

    setExplanation(
      subchargesCategory.ToelichtingOverigeToeslag
        ? subchargesCategory.ToelichtingOverigeToeslag
        : ""
    );

    if (lastBlurredInputValue.current) {
      subchargesData[lastBlurredInputIndex.current][
        lastBlurredInputName.current
      ] = lastBlurredInputValue.current;
    }

    setSubchargesData(subchargesData);
  }, [props.subchargesCategory.Subcharges]);

  const handleNumericFieldChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    const subchargesData = [...SubchargesData];

    let correctedValue;

    if (value !== "") {
      if (value.match(/^\d+$/)) {
        correctedValue = parseInt(value, 10);
      }
    } else {
      if (value === "") {
        correctedValue = 0;
      }
    }

    if (typeof correctedValue === "number" && !isNaN(correctedValue)) {
      focusedFieldValue.current = correctedValue;

      switch (name) {
        case "OverigPerWeekField":
          subchargesData[index].OverigPerWeek = value ? value : 0;
          break;
        default:
          break;
      }

      setSubchargesData(subchargesData);
    }
  };

  const onBlurField = (e, index, row) => {
    const name = e.target.name;
    let field;

    setFocusedInputName(null);
    setFocusedIndex(null);

    switch (name) {
      case "OverigPerWeek":
        field = "OverigPerWeekField";
        break;
      default:
        break;
    }

    if (`${FieldValueOnFocusStart}` !== `${focusedFieldValue.current}`) {
      lastBlurredInputName.current = field;
      lastBlurredInputValue.current = focusedFieldValue.current;
      lastBlurredInputIndex.current = index;
      updateSubchargesCall(e, index, row);
    }
  };

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const handleSelectChange = (e, index, row) => {
    switch (e.target.name) {
      case "ZMPToeslagField":
        row.ZMPToeslag = e.target.value;
        break;
      case "GoedkeuringWASDocumentatieField":
        row.GoedkeuringWASDocumentatie = e.target.value;
        break;
      case "GrotereBusField":
        row.GrotereBus = e.target.value;
        break;
      default:
        break;
    }
    const selectedValue = e.target.value ? "true" : "false";
    updateSubchargesCall(e, index, row, selectedValue);
  };

  const onFocusField = (e, index) => {
    e.target.select();
    setFocusedInputName(e.target.name);
    setFocusedIndex(index);
    focusedFieldValue.current = e.target.value;

    setFieldValueOnFocusStart(parseInt(e.target.value));
  };

  // HTTP Requests
  const updateSubchargesCall = async (e, index, row, selectedValue = null) => {
    const prevFieldValueOnFocus = selectedValue
      ? selectedValue
      : FieldValueOnFocusStart;
    setFieldValueOnFocusStart(null);

    const loaders = document.querySelectorAll(
      "#subchargesFieldsContainer .grid-custom-loader"
    );
    const loader = loaders[index + 1];
    loader.classList.add("visible");

    const name = e.target.name;

    fetch(configSettings.UpdateRouteSubchargesUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Id: row.Id,
        RitId: row.RitId,
        ContractSubchargesId: row.ContractSubchargesId,
        ZMPToeslag: row.ZMPToeslag,
        GoedkeuringWASDocumentatie: row.GoedkeuringWASDocumentatie,
        GrotereBus: row.GrotereBus,
        OverigPerWeek: row.OverigPerWeek
      })
    })
      .then(function(res) {
        return res.json();
      })
      .then(function(data) {
        props.updateCategories();
        // updateGrid(data, index, row);
      })
      .catch(error => {
        console.log(error);
        setIsSnackbarAutoClosing(true);
        setErrorSnackbarOpen(true);
        if (prevFieldValueOnFocus) {
          revertValueOnBadRequest(name, index, prevFieldValueOnFocus);
        }
      })
      .finally(() => {
        loader.classList.remove("visible");
      });
  };

  const revertValueOnBadRequest = (name, _index, prevFieldValueOnFocus) => {
    const _Data = SubchargesData.map((entry, index) => {
      if (index === _index) {
        switch (name) {
          case "ZMPToeslag":
            entry.ZMPToeslag = prevFieldValueOnFocus;

            break;
          case "GoedkeuringWASDocumentatie":
            entry.GoedkeuringWASDocumentatie = prevFieldValueOnFocus;

            break;
          case "GrotereBus":
            entry.GrotereBus = prevFieldValueOnFocus;

            break;
          case "OverigPerWeek":
            entry.OverigPerWeek = prevFieldValueOnFocus;

            break;
          default:
            break;
        }
      }
      return entry;
    });

    setSubchargesData(_Data);
  };

  const updateSubchargesExplanationCall = async e => {
    if (ExplanationValueOnFocus !== e.target.value) {
      setExplanationUpdating(true);

      fetch(configSettings.UpdateSubchargesExplanationUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ContractId: props.contractId,
          ToelichtingOverigeToeslag: Explanation
        })
      })
        .then(function(res) {
          return res.text();
        })
        .then(function(data) {
          const isTrueSet = data === "true";
          if (!isTrueSet) {
            throw new Error(errorSnackbarMsg);
          }
        })
        .catch(error => {
          console.log(error);
          setIsSnackbarAutoClosing(false);
          setErrorSnackbarOpen(true);
        })
        .finally(() => {
          setExplanationUpdating(false);
        });
    }
  };

  return (
    <div id="subchargesSection">
      <div id="subchargesFieldsContainer" className="fields-container">
        <div id="subchargesFieldsHeader" className="fields-header flex">
          <div className="normalinputs flex">
            <div className="grid-custom-loader">
              <Icons.Loading />
            </div>
            <div className="column-header route-field">Ritnaam</div>
            <div className="column-header cdgrid-field">
              <Tooltip
                title="Geldt alleen voor de ritten waar iemand in loondienst rijdt, dus niet direct alle ritten van de ZMP-er"
                placement="top-end"
              >
                <p>ZMP toeslag</p>
              </Tooltip>
            </div>
            <div className="column-header was-field">
              <Tooltip
                title="Goedkeuring documentatie WAS, Bij Ja zal het aangepaste tarief worden meegenomen"
                placement="top-end"
              >
                <p>Goedkeuring documentatie WAS</p>
              </Tooltip>
            </div>
            <div className="column-header bigger-bus-field">
              <Tooltip title="Grotere bus Ja/Nee" placement="top-end">
                <p>Grotere bus</p>
              </Tooltip>
            </div>
            <div className="column-header etc-field">
              <Tooltip
                title="I.p.v. binaire werkwijze van het per rit wel/niet toekennen is er op iedere rit naar rato van het aantal gedekte ritten een toeslag berekend. Bijvoorbeeld alle ritten toeslag  5,60, 1/2 ritten toeslag: 1/2 *  5,60 =  2,80"
                placement="top-end"
              >
                <p>Overig per week</p>
              </Tooltip>
            </div>
          </div>
          <div className="readonly-inputs flex">
            <div className="column-header cdgrid-field">
              <Tooltip title="ZMP Bedrag" placement="top-end">
                <p>ZMP Bedrag</p>
              </Tooltip>
            </div>
            <div className="column-header cdgrid-field">
              <Tooltip title="Grotere bus" placement="top-end">
                <p>Grotere bus</p>
              </Tooltip>
            </div>
            <div className="column-header cdgrid-field">
              <Tooltip title="Toeslag meerdere routes" placement="top-end">
                <p>Toeslag meerdere routes</p>
              </Tooltip>
            </div>
          </div>
        </div>
        <div id="subchargesFieldsBody" className="fields-body">
          {SubchargesData.map((row, index) => {
            return (
              <div key={index} className={`flex contract-details-row row-${0}`}>
                <div className="normalinputs flex">
                  <div className="grid-custom-loader">
                    <Icons.Loading />
                  </div>
                  <p className="cdgrid-field route-field">{row.RitName}</p>

                  <Select
                    value={row.ZMPToeslag}
                    name={"ZMPToeslagField"}
                    className="cdgrid-field"
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 1)}`
                    }}
                    onChange={e => handleSelectChange(e, index, row)}
                  >
                    <MenuItem value={true}>Ja</MenuItem>
                    <MenuItem value={false}>Nee</MenuItem>
                  </Select>
                  <Select
                    value={row.GoedkeuringWASDocumentatie}
                    name={"GoedkeuringWASDocumentatieField"}
                    className="cdgrid-field"
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 1)}`
                    }}
                    onChange={e => handleSelectChange(e, index, row)}
                  >
                    <MenuItem value={true}>Ja</MenuItem>
                    <MenuItem value={false}>Nee</MenuItem>
                  </Select>
                  <Select
                    value={row.GrotereBus}
                    name={"GrotereBusField"}
                    className="cdgrid-field"
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 1)}`
                    }}
                    onChange={e => handleSelectChange(e, index, row)}
                  >
                    <MenuItem value={true}>Ja</MenuItem>
                    <MenuItem value={false}>Nee</MenuItem>
                  </Select>
                  <TextField
                    variant="outlined"
                    className="cdgrid-field"
                    name={"OverigPerWeekField"}
                    value={
                      FocusedInputName === "OverigPerWeekField" &&
                      index === FocusedIndex
                        ? focusedFieldValue.current
                        : row.OverigPerWeek
                    }
                    onChange={e => handleNumericFieldChange(e, index)}
                    onFocus={e => onFocusField(e, index)}
                    onBlur={e => onBlurField(e, index, row)}
                    onKeyDown={e => handleKeyPress(e)}
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 1)}`,
                      min: 0
                    }}
                  />
                </div>
                <div className="readonly-inputs flex">
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced currency"
                    value={row.ZMPBedrag}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced currency"
                    value={row.GrotereBusBedrag}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced currency"
                    value={row.ToeslagMeerdereRoutes}
                    readOnly
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex">
          <div
            className={`grid-custom-loader explanation-loader ${
              ExplanationUpdating ? "visible" : ""
            }`}
          >
            <Icons.Loading />
          </div>
          <div className="flex-column ml05 mt1">
            <label>
              <b>Toelichting overige toeslag</b>
            </label>
            <TextField
              className="big-textbox"
              aria-label="ToelichtingOverigeToeslag"
              variant="outlined"
              name="ToelichtingOverigeToeslag"
              value={Explanation}
              onChange={e => setExplanation(e.target.value)}
              onFocus={e => setExplanationValueOnFocus(e.target.value)}
              onBlur={e => updateSubchargesExplanationCall(e)}
              onKeyDown={e => handleKeyPress(e)}
              tabIndex={9000}
            />
          </div>
        </div>
      </div>
      {errorSnackbarOpen && (
        <ErrorSnackbar
          open={errorSnackbarOpen}
          errorMessage={errorSnackbarMsg}
          closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
          autoCloseSeconds={IsSnackbarAutoClosing ? 3 : false}
        />
      )}
    </div>
  );
}
