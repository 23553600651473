import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import { Button } from "@material-ui/core";

import "../../styles/app.scss";

interface Props {
  model: any[];
  formMode: string;
  onChangeAdvanced?: (event, Object) => void;
  onChangeMultiple?: (event, value: any) => void;
  onChange?: (event, Object) => void;
  onSubmit?: (Object, string) => void;
  addRow: (Object, event) => void;
  editRow: (Object, event) => void;
  closeForm?: () => void;
  showForm?: boolean;
  defaultValues: object;
  errorMessage?: string;
  groupSelectExpanded?: boolean;
  activeGroups?: any[];
  groupSelected?: boolean;
  multiSelectDisabled?: boolean;
  groupSelectText?: string;
  customValidities?: () => void;
  multipleSelectValue?: any[];
}

const ESCAPE_KEY = 27;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class Form extends React.Component<Props, any> {
  public static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.defaultValues &&
      Object.keys(nextProps.defaultValues).length
    ) {
      return {
        ...nextProps.defaultValues
      };
    } else {
      if (Object.getOwnPropertyNames(prevState).length > 0) {
        return null;
      }

      if (nextProps.model) {
        const initialState = nextProps.model.reduce((acc, m) => {
          acc[m.key] = m.value ? m.value : "";
          return acc;
        }, {});
        // // console.log("initialState: ", initialState);
        return {
          ...initialState
        };
      }
    }
  }

  private canSubmit = true;

  constructor(props) {
    super(props);
    this.state = {};
  }

  public handleGroupChange = e => {
    // console.log(this.props)
    const activeGroups = [];
    const id = e.target.id;
    let groupExists = false;
    let gIndex;

    // if at least 1 group selected
    if (activeGroups.length > 0) {
      activeGroups.forEach((group, index) => {
        // if group exists
        if (group.name === e.target.name) {
          gIndex = index;
          groupExists = true;
          return;
        }
      });

      if (groupExists) {
        activeGroups[gIndex] = {
          key: id,
          id,
          name: e.target.name,
          value: e.target.value
        };
      } else {
        activeGroups.push({
          key: id,
          id,
          name: e.target.name,
          value: e.target.value
        });
      }
    } else {
      activeGroups.push({
        key: id,
        id,
        name: e.target.name,
        value: e.target.value
      });
    }

    this.setState({
      activeGroups
    });

    groupExists = false;
  };

  public removeGroup = id => {
    const activeGroups = this.props.activeGroups;

    activeGroups.forEach((group, index) => {
      if (group.key === id) {
        activeGroups.splice(index, 1);
        const removeId = document.getElementById(id) as HTMLInputElement;
        removeId.checked = false;
      }
    });

    this.setState({ activeGroups });
  };

  public showCheckboxes = () => {
    const checkboxes = document.getElementById("checkboxes");

    if (this.state.groupSelectExpanded) {
      checkboxes.style.display = "none";
    } else {
      checkboxes.style.display = "block";
    }

    this.setState({
      groupSelectExpanded: !this.state.groupSelectExpanded
    });
  };

  public componentDidMount() {
    this.canSubmit = true;
    // func.lockScreen();
    // func.setModalHeight('dynamicForm');
  }

  public componentWillUnmount() {
    // func.unlockScreen();
  }

  public onSubmit = e => {
    e.preventDefault();

    if (this.canSubmit) {
      this.canSubmit = false;
      this.props.onSubmit(this.state, this.props.formMode);
    }

    // Setting true if form stays open after submit (http error)
    setTimeout(() => {
      this.canSubmit = true;
    }, 2000);
  };

  public onChange = (e, key, type = "single", onChangeMode = "simple") => {
    // // console.log(typeof (key))
    // // console.log(`${key} changed ${e.target.value} type ${type}`);

    if (type === "single") {
      this.setState({
        [key]: e.target.value
      });

      // added for ms browsers wrong type number message
      if (this.props.customValidities) {
        this.props.customValidities();
      }

      if (onChangeMode === "advanced") {
        this.props.onChangeAdvanced(e, key);
      } else {
        if (this.props.onChange) {
          this.props.onChange(e, key);
        }
      }
    }
    if (type === "multiple") {
      // Array of values (e.g. checkbox): TODO: Optimization needed.
      const found = this.state[key]
        ? this.state[key].find(d => d === e.target.value)
        : false;

      if (found) {
        const data = this.state[key].filter(d => {
          return d !== found;
        });
        this.setState({
          [key]: data
        });
      } else {
        this.setState({
          [key]: [e.target.value, ...this.state[key]]
        });
      }
    }
  };

  public handleChangeMultiple = (e, value) => {
    // const { options } = e.target;
    // const value = [];
    // for (let i = 0, l = options.length; i < l; i += 1) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }

    this.props.onChangeMultiple(e, value);
  };

  public renderForm = () => {
    const model = this.props.model;

    const formUI = model.map(m => {
      const key = m.key;
      const type = m.type || "text";
      const props = m.props || {};
      const name = m.name;
      const disabled = m.disabled;
      let value = m.value;

      const target = key;
      value = this.state[target];

      let input = (
        <input
          {...props}
          className="form-input"
          type={type}
          id={key}
          key={key}
          name={name}
          value={value}
          onChange={e => {
            this.onChange(e, target);
          }}
        />
      );

      if (type === "title" || type === "title-half") {
        input = <p className="elements-title">{value}</p>;
      }

      if (type === "radio") {
        input = m.options.map(o => {
          const checked = o.value === value;
          return (
            <React.Fragment key={"fr" + o.key}>
              <input
                {...props}
                className="form-input"
                type={type}
                key={o.key}
                name={o.name}
                checked={checked}
                value={o.value}
                onChange={e => {
                  this.onChange(e, o.name);
                }}
              />
              <label key={"ll" + o.key}>{o.label}</label>
            </React.Fragment>
          );
        });
        input = <div className="form-group-radio">{input}</div>;
      }

      if (type === "select") {
        input = m.options.map(o => {
          // const checked = o.value === value;

          return (
            <option
              {...props}
              className="form-input"
              key={o.key}
              value={o.value}
            >
              {o.label}
            </option>
          );
        });

        input = (
          <select
            name={m.key}
            value={value}
            disabled={disabled}
            onChange={e => {
              this.onChange(e, m.key);
            }}
          >
            {input}
          </select>
        );
      }

      if (type === "multipleSelect") {
        input = m.options.map(o => {
          // const checked = o.value === value;

          return (
            <MenuItem
              {...props}
              className="form-input"
              key={o.label}
              value={o.label}
            >
              {o.label}
            </MenuItem>
          );
        });

        input = (
          <Select
            multiple
            name={m.key}
            id={m.key}
            disabled={this.props.multiSelectDisabled}
            value={this.props.multipleSelectValue}
            onChange={e => this.handleChangeMultiple(e, value)}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div className="chips">
                {(selected as any).map(value => (
                  <Chip key={value} label={value} className="chip" />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {input}
          </Select>
        );
      }

      if (type === "advancedSelect") {
        input = m.options.map(o => {
          // const checked = o.value === value;

          return (
            <option
              {...props}
              className="form-input"
              key={o.key}
              value={o.value}
            >
              {o.label}
            </option>
          );
        });

        input = (
          <select
            name={m.key}
            id={m.key}
            value={value}
            onChange={e => {
              this.onChange(e, m.key, "single", "advanced");
            }}
          >
            {input}
          </select>
        );
      }
      if (type === "advancedCheckbox") {
        input = (
          <div className="flex relative">
            <input
              {...props}
              className="form-input"
              type="checkbox"
              key={key}
              name={name}
              id={key}
              onChange={e => {
                this.onChange(e, target, "single", "advanced");
              }}
            />
            <label className="form-label" key={"l" + key} htmlFor={key}>
              {m.label}
            </label>
          </div>
        );

        input = <div className="form-group-checkbox">{input}</div>;
      }
      if (type === "checkbox") {
        input = (
          <div className="flex relative">
            <input
              {...props}
              className="form-input"
              type={type}
              key={key}
              name={name}
              id={key}
              onChange={e => {
                this.onChange(e, target, "checkbox");
              }}
            />
            <label className="form-label" key={"l" + key} htmlFor={key}>
              {m.label}
            </label>
            {props["data-checkbox-tooltip"] ? (
              <div className="tooltip">
                {/* <InfoIcon className="blue" /> */}

                {props["data-tooltip-left"] ? (
                  <span className="tooltip-text-left tooltip-text">
                    {props["data-tooltip-text"]}
                  </span>
                ) : (
                  <span className="tooltip-text">
                    {props["data-tooltip-text"]}
                  </span>
                )}
              </div>
            ) : null}
          </div>
        );

        // multiple checkboxes
        // input = m.options.map((o) => {

        //   // let checked = o.value == value;
        //   let checked = false;
        //   if (value && value.length > 0) {
        //     checked = value.indexOf(o.value) > -1 ? true : false;
        //   }

        //   // console.log(o.value);

        //   // // console.log("Checkbox: ", checked);
        //   return (
        //     <React.Fragment key={"cfr" + o.key}>
        //       <label key={"ll" + o.key}>{o.label}</label>
        //       <input {...props}
        //         className="form-input"
        //         type={type}
        //         key={o.key}
        //         name={o.name}
        //         checked={checked}
        //         value={o.value}
        //         onChange={(e) => { this.onChange(e, m.key, "checkbox") }}
        //       />

        //     </React.Fragment>
        //   );
        // });

        input = <div className="form-group-checkbox">{input}</div>;
      }

      if (type === "title") {
        return (
          <div key={"g" + key} className="form-group-title">
            {input}
          </div>
        );
      } else if (type === "title-half") {
        return (
          <div key={"g" + key} className="form-group-title title-half">
            {input}
          </div>
        );
      } else {
        return (
          <div
            key={"g" + key}
            className={`form-group ${type === "hide" ? "hide" : ""}`}
          >
            <div className="flex relative">
              {type === "checkbox" || type === "advancedCheckbox" ? (
                <label
                  className="form-label invisible"
                  key={"l" + key}
                  htmlFor={key}
                >
                  {m.label}
                </label>
              ) : (
                <label
                  className={"form-label " + key}
                  key={"l" + key}
                  htmlFor={key}
                >
                  {m.label}
                </label>
              )}
              {props["data-tooltip"] ? (
                <div className="tooltip">
                  {/* <InfoIcon className="blue" /> */}

                  {props["data-tooltip-left"] ? (
                    <span className="tooltip-text-left tooltip-text">
                      {props["data-tooltip-text"]}
                    </span>
                  ) : (
                    <span className="tooltip-text">
                      {props["data-tooltip-text"]}
                    </span>
                  )}
                </div>
              ) : null}
              {/* for trip planning priority strategy only */
              props["data-custom-tooltip"] ? (
                <div className="tooltip">
                  {/* <InfoIcon className="blue" /> */}

                  {props["data-tooltip-left"] ? (
                    <span className="tooltip-text-left tooltip-text">
                      <div className="custom-tooltip-text">
                        <p className="m0">
                          <b>Zo vroeg mogelijk</b>
                          <br />
                          De ritten worden zoveel mogelijk naar voren geschoven,
                          rekening houdend met het bezorgvenster.
                          <br />
                          <br />
                          <b>Zo laat mogelijk</b>
                          <br />
                          De ritten worden zoveel mogelijk naar achteren
                          geschoven, rekening houdend met het bezorgvenster.
                          <br />
                          <br />
                          <b>Alles tegelijk</b>
                          <br />
                          Ritten worden geschoven naar een gelijke vertrektijd.
                          <br />
                        </p>
                      </div>
                    </span>
                  ) : (
                    <span className="tooltip-text">
                      <div className="custom-tooltip-text">
                        <p>
                          <b>Zo vroeg mogelijk</b>
                          <br />
                          De ritten worden zoveel mogelijk naar voren geschoven,
                          rekening houdend met het bezorgvenster.
                          <br />
                          <br />
                          <b>Zo laat mogelijk</b>
                          <br />
                          De ritten worden zoveel mogelijk naar achteren
                          geschoven, rekening houdend met het bezorgvenster.
                          <br />
                          <br />
                          <b>Alles tegelijk</b>
                          <br />
                          Ritten worden geschoven naar een gelijke vertrektijd.
                          <br />
                        </p>
                      </div>
                    </span>
                  )}
                </div>
              ) : null}
            </div>
            {input}
          </div>
        );
      }
    });

    return formUI;
  };

  public handleKeyDown = e => {
    if (e.keyCode === ESCAPE_KEY) {
      this.props.closeForm();
    }
  };

  public render() {
    return (
      <Modal
        aria-labelledby="form-modal"
        aria-describedby="form-modal"
        open={this.props.showForm}
        onClose={() => this.props.closeForm()}
      >
        <div tabIndex={-1} className="modal-outer">
          <Paper className="formModal .fade-in-slow " id="formModal">
            <form
              className="DynamicForm"
              onSubmit={e => this.onSubmit(e)}
              onKeyDown={e => this.handleKeyDown(e)}
            >
              <div className="form-header no-child-margin p05">
                <h3 className="form-title text-center">
                  {this.props.formMode === "addRowMode" ? "Toevoegen" : null}
                  {this.props.formMode === "editRowMode" ? "Wijzigen" : null}
                </h3>
              </div>
              <Paper
                style={{ maxHeight: 200, overflow: "auto" }}
                className="fields-content p1"
              >
                {this.renderForm()}
              </Paper>
              <div className="flex space-b form-footer">
                <div className="error-message">{this.props.errorMessage}</div>
                <div className="form-buttons">
                  <Button
                    size="small"
                    className="normalcase"
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="formSubmitBtn"
                    // className="btn btn-primary btn-submit"
                  >
                    Opslaan
                  </Button>
                  <Button
                    size="small"
                    className="normalcase"
                    variant="outlined"
                    type="button"
                    onClick={() => this.props.closeForm()}
                    // className="btn btn-primary btn-cancel"
                  >
                    Annuleren
                  </Button>
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </Modal>
    );
  }
}

export default Form;
