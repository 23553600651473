import React from "react";
import Header from "../components/Header/Header";
import AdminMap from "../components/Map/AdminMap";
import AdminInfoContent from "../components/AdminInfoContent/AdminInfoContent";
import { MapProvider } from "../context/MapContext";

import "../styles/app.scss";

const AdminScreen = () => {
  return (
    <div id="AdminScreen">
      <div className="flex-column">
        <Header />
        <section className="content-wrapper flex">
          <MapProvider>
            <AdminMap
            mapStatus={1} />
            <AdminInfoContent />
          </MapProvider>
        </section>
      </div>
    </div>
  );
};

export default AdminScreen;
