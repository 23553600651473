import React, { useEffect, useState } from "react";
import "../../styles/app.scss";

import Api from "../../helpers/http";
import configSettings from "../../settings";

import Button from "@material-ui/core/Button";
import Icons from "../Icons";
import Modal from "@material-ui/core/Modal";
import PostcodesTable from "../Tables/PostcodesTable";
import TripTable from "../Tables/TripTable";
import Select from "@material-ui/core/Select";
import useMap from "../../hooks/useMap";
import useAuth from "../../hooks/useAuth";

import { saveAs } from "file-saver";

import TripDetailsModal from "../Modals/TripDetailsModal";
import ErrorSnackbar from "../Utils/ErrorSnackbar";
import SuccessSnackbar from "../Utils/SuccessSnackbar";
import ImportFileDropzone from "../Modals/ImportFileDropzone";
import LoadingModal from "../Modals/LoadingModal";
import CreateTripModal from "../Modals/CreateTripModal";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ImportQueue from "../Tables/ImportQueue";
import userRoles from "../../enums/UserRoles";
import landCodes from "../../enums/LandCodes";

import {
  addDepotMarker,
  hideMapLoader,
  showMapLoader,
  renderPlannedPostcodeShape,
  renderUnplannedPostcodeShape,
  handleSelectAllTripShapes,
  handleDepotCoords,
  saveUnplannedLayerId,
  unplanPostcodesAfterDrag,
  replanPostcodesAfterDrag,
  resetMap
} from "../Map/AdminMapController";
import functions from "../../helpers/functions";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import DescriptionIcon from "@material-ui/icons/Description";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box className="box-root" p={3}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  indicator: {
    backgroundColor: "rgba(255,255,255,0)"
  }
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    position: "absolute",
    backgroundColor: "white",
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

var _unplannedLayerId;
let IS_MOUNTED;

const AdminInfoContent = props => {
  const [tripModalVisible, setTripModalVisible] = useState(false);
  const [createTripModalVisible, setCreateTripModalVisible] = useState(false);
  const [depots, setDepots] = useState([""]);
  const [allDepotsList, setAllDepotsList] = useState([]);
  const [subContractors, setSubcontractors] = useState(null);
  const [depotSelected, setDepotSelected] = useState(false);
  const [subContractorSelected, setSubcontractorSelected] = useState("");
  const [latestImport, setLatestImport] = useState(null);
  const [postcodes, setPostcodes] = useState([]);
  const [trips, setTrips] = useState([]);
  const [tripsTableLoading, setTripsTableLoading] = useState(true);
  const [tripDetails, setTripDetails] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [snackbarBGcolor, setSnackbarBGcolor] = useState("green");
  const [successSnackbarMsg, setSuccessSnackbarMsg] = useState("");
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [uploadEndpoint, setUploadEndpoint] = useState("");
  const [loadingText, setLoadingText] = useState("Loading...");
  const [refreshImportQueue, setRefreshImportQueue] = useState(false);
  const [UnplannedShapeRendered, setUnplannedShapeRendered] = useState(false);
  const [RangesActionsActive, setRangesActionsActive] = useState(undefined);

  const {
    getCachedTripShapesData,
    getSelectedTripShapes,
    setSelectedTripShapes,
    setPostCodeBlockedTripShape,
    setMapAction,
    setUnplannedLayerId,
    modifiedTripDetails,
    unplannedTripDetails,
    setSelectedPostcodes
  } = useMap();

  const classes = useStyles();
  const {
    currentUser,
    globalSelectedDepot,
    setGlobalSelectedDepotId,
    setGlobalSelectedDepot
  } = useAuth();
  const [importButtonActive, setImportButtonActive] = useState(false);

  const [TabPanelValue, setTabPanelValue] = React.useState(0);
  function handleTabChange(event, newValue) {
    setTabPanelValue(newValue);
  }

  useEffect(() => {
    const headerTitle = document.getElementById("appHeaderTitle");
    headerTitle.innerHTML = "RouteMaker";
    IS_MOUNTED = true;

    return () => {
      IS_MOUNTED = false;
    };
  }, []);

  useEffect(() => {
    if (Object.entries(currentUser).length !== 0) {
      if (
        currentUser.Role.RoleName === userRoles.Beheerder ||
        currentUser.Role.RoleName === userRoles.Administrator
      ) {
        setImportButtonActive(true);
      } else {
        setImportButtonActive(false);
      }
    }

    getDepotsList();
  }, [currentUser]);

  useEffect(() => {
    // Set checked Trips for Trip Table
    if (getSelectedTripShapes().length) {
      const _selectedTripShapes = [...getSelectedTripShapes()];
      const _trips = [...trips];
      _trips.forEach(trip => {
        if (_selectedTripShapes.includes(trip.Id)) {
          trip.isChecked = true;
        } else {
          trip.isChecked = false;
        }
      });
      setTrips(_trips);
    }
  }, [getSelectedTripShapes().length]);

  useEffect(() => {
    if (Object.keys(unplannedTripDetails).length) {
      const tempTrips = [...trips];
      tempTrips[0] = unplannedTripDetails;
      setTrips(tempTrips);
    }
  }, [unplannedTripDetails]);

  useEffect(() => {
    let indexToChange;
    if (Object.keys(modifiedTripDetails).length) {
      // console.log(getSelectedTripShapes());
      trips.forEach((trip, index) => {
        if (trip.Id === modifiedTripDetails.Id) {
          indexToChange = index;
        }
      });
      const tempTrips = [...trips];
      tempTrips[indexToChange] = modifiedTripDetails;
      tempTrips[indexToChange].isSelected = true;
      tempTrips[indexToChange].isChecked = true;
      tempTrips[0] = unplannedTripDetails;
      setTrips(tempTrips);
    }
  }, [modifiedTripDetails]);

  const getDepotsList = async () => {
    const depotsList = await Api.get(`${configSettings.DepotsApiUrl}/false`);

    if (IS_MOUNTED) {
      setAllDepotsList(depotsList);

      if (depotsList.length) {
        const DepotsOptions = [];

        depotsList.forEach(depot => {
          DepotsOptions.push(
            <option
              key={depot.Id}
              value={JSON.stringify({
                Lon: depot.Longitude,
                Lat: depot.Latitude,
                Id: depot.Id
              })}
            >
              {depot.Naam}
            </option>
          );
        });
        setDepots(DepotsOptions);

        if (depotsList.length === 1 || globalSelectedDepot) {
          let depotOptionValues;

          if (depotsList.length === 1) {
            depotOptionValues = JSON.parse(DepotsOptions[0].props.value);
          } else if (globalSelectedDepot) {
            const tempDepotOptionValues = depotsList.find(
              el => el.Id === globalSelectedDepot.Id
            );
            depotOptionValues = {
              Lon: tempDepotOptionValues.Longitude,
              Lat: tempDepotOptionValues.Latitude,
              Id: globalSelectedDepot.Id
            };
            const depotSelectElem = document.getElementById("depotSelect");
            depotSelectElem.value = JSON.stringify(depotOptionValues);
          }
          handleDepotCoords(depotOptionValues);
          getDepotInfo(depotOptionValues.Id);
          getLatestImportStatus(depotOptionValues.Id);
          setPostcodes(null);
          setUnplannedShapeRendered(false);
        }
      }
    }
  };

  const openImportModal = async importType => {
    let _uploadEndpoint;
    if (importType === "retailerImport") {
      _uploadEndpoint = "RL";
    } else if (importType === "adresBe") {
      _uploadEndpoint = "AB";
    } else if (importType === "catchAreas") {
      _uploadEndpoint = "VG";
    } else if (importType === "subcos") {
      _uploadEndpoint = "SC";
    } else {
      _uploadEndpoint = "Normal";
    }

    setUploadEndpoint(_uploadEndpoint);
    setImportDialogOpen(true);
    //}
  };

  const createGuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const savedImportFile = file => {
    const guid = createGuid();

    let url;

    if (uploadEndpoint === "RL") {
      url = `${configSettings.ImportRLUrl}?sessionIdentifier=${guid}`;
    } else if (uploadEndpoint === "AB") {
      url = `${configSettings.ImportABUrl}?sessionIdentifier=${guid}`;
    } else if (uploadEndpoint === "VG") {
      url = `${configSettings.ImportVGUrl}`;
    } else if (uploadEndpoint === "SC") {
      url = `${configSettings.ImportSCUrl}`;
    } else {
      url = `${configSettings.ImportNormalUrl}?depotId=${depotSelected}&sessionIdentifier=${guid}&scenarioId=0`;
    }

    setShowLoadingModal(true);
    setLoadingText("Het bestand wordt verwerkt in de database");

    var data = new FormData();
    data.append("files", file);

    let responseOk;

    fetch(url, {
      method: "POST",
      body: data
    })
      .then(response => {
        if (response.ok) {
          responseOk = true;
        }
        return response.text();
      })
      .then(text => {
        if (responseOk) {
          setShowLoadingModal(false);
          setRefreshImportQueue(true);
          getLatestImportStatus(depotSelected);
          setSuccessSnackbarMsg(
            "Het bestand is succesful opgeslagen, de verwerking wordt gestart."
          );
          setSnackbarBGcolor("orange");
          setSuccessSnackbarOpen(true);
          setImportDialogOpen(false);
        } else {
          throw Error(text);
        }
      })
      .catch(function(error) {
        console.log("Error sending .csv file: ", error);
        setShowLoadingModal(false);

        setErrorSnackbarMsg(error.toString());
        setErrorSnackbarOpen(true);
        setImportDialogOpen(false);
      });
  };

  // Select Filters handler
  const handleFilterChange = e => {
    const selectId = e.target.id;
    const value = e.target.value;

    setSelectedTripShapes([]);
    setSelectedPostcodes([]);

    if (document.querySelector(".route-container .-loading.-active")) {
      document.querySelector(".route-container .-loading").style.display =
        "block";
    }
    setTripsTableLoading(true);

    if (selectId === "depotSelect") {
      // Depot select
      const depotOptionValues = JSON.parse(value);
      handleDepotCoords(depotOptionValues);
      getDepotInfo(depotOptionValues.Id);
      getLatestImportStatus(depotOptionValues.Id);
      setPostcodes(null);

      setUnplannedShapeRendered(false);
    } else if (selectId === "subContractorSelect") {
      // Distributor select
      const _subContractor = value === "" ? null : value;

      if (_subContractor) {
        setSubcontractorSelected(_subContractor);
      } else {
        setSubcontractorSelected(null);
      }

      getTrips(depotSelected, _subContractor);
    }
  };
  const getLatestImportStatus = async id => {
    const latestImportInfo = await Api.get(
      `${configSettings.ImportLatestUrl}/${id}`
    );

    if (IS_MOUNTED) {
      if (latestImportInfo) {
        setLatestImport(latestImportInfo);
      }
    }
  };

  const getDepotInfo = async depotId => {
    setRangesActionsActive(undefined);
    const depotInfo = await Api.get(
      `${configSettings.DepotsByIdUrl}/${depotId}`
    );

    if (IS_MOUNTED) {
      if (depotInfo && Object.entries(depotInfo).length !== 0) {
        toggleDepotRangesActions(currentUser.Role.RoleName, depotInfo.LandCode);

        setDepotSelected(depotId);
        setGlobalSelectedDepotId(depotId);
        setGlobalSelectedDepot(depotInfo);
        getTrips(depotId, null);
        getSubcontractors(depotId);
        addDepotMarker(depotInfo.Longitude, depotInfo.Latitude, depotInfo.Id);
      }
    }
  };

  const toggleDepotRangesActions = (userRole, landCode) => {
    if (
      userRole === userRoles.Beheerder ||
      userRole === userRoles.Administrator ||
      userRole === userRoles.Depot
    ) {
      landCode === landCodes.Netherlands
        ? setRangesActionsActive(true)
        : setRangesActionsActive(false);
    } else {
      setRangesActionsActive(false);
    }
  };

  const getSubcontractors = async depotId => {
    const subContractors = await Api.get(
      `${configSettings.SubcontractorUrl}/${depotId}`
    );

    if (IS_MOUNTED) {
      if (subContractors.length) {
        const subContractorsOptions = [];
        subContractors.forEach(subCo => {
          subContractorsOptions.push(
            <option key={subCo.Id} value={subCo.Id}>
              {subCo.SubCoNaam}
            </option>
          );
        });

        setSubcontractors(subContractorsOptions);
      }
    }
  };

  const getTrips = async (id, subContractorId) => {
    let _trips;

    if (subContractorId === null) {
      _trips = await Api.get(`${configSettings.TripsUrl}/${id}`);
      if (_trips.length) {
        saveUnplannedLayerId(_trips[0].Id);
      }
    } else {
      _trips = await Api.get(
        `${configSettings.TripsUrl}/${id}/${subContractorId}`
      );
    }

    if (IS_MOUNTED) {
      if (_trips.length) {
        setUnplanned(_trips[0].Id);
        setTrips([]);
        setTrips(_trips);
        setTripsTableLoading(false);
      } else {
        setTrips([]);
      }
    }
  };

  const setUnplanned = id => {
    setUnplannedLayerId(id);
    _unplannedLayerId = id;
  };

  const getPostcodes = async id => {
    const postcodes = await Api.get(`${configSettings.PostcodesUrl}/${id}`);

    if (IS_MOUNTED) {
      if (postcodes) {
        setPostcodes(postcodes);
      }
    }
  };

  function postCodesSelected(postcodeIds) {
    setSelectedPostcodes(postcodeIds);
  }

  const showTripDetails = async (e, id) => {
    const tripDetails = await Api.get(`${configSettings.TripDetailsUrl}/${id}`);

    if (IS_MOUNTED) {
      if (tripDetails) {
        setTripDetails(tripDetails);
      }
      setTripModalVisible(true);
    }
  };

  // Handle map and postcode table after postcodes transfer by dragging
  const postCodeTransfer = async (fromTripId, toTripId, postCodeIds) => {
    console.log(toTripId);
    if (fromTripId === _unplannedLayerId) {
      // transfering from unplanning layer

      const _postcodesToReplan = [...postCodeIds];
      replanPostcodesAfterDrag(_postcodesToReplan);
    } else if (toTripId === _unplannedLayerId) {
      // transfering to unplanning layer

      const _postcodesToUnplan = [...postCodeIds];
      unplanPostcodesAfterDrag(_postcodesToUnplan);
    } else {
      // handle from planned to planned condition
    }
  };

  const getPostcodeShapes = async id => {
    if (id !== _unplannedLayerId) {
      const _postcodeShapesData = await Api.get(
        `${configSettings.PostcodeShapesUrl}/${id}`
      );
      if (IS_MOUNTED) {
        renderPlannedPostcodeShape(_postcodeShapesData);
      }
    }

    if (!UnplannedShapeRendered) {
      const unplannedShapeData = await Api.get(
        `${configSettings.PostcodeShapesUrl}/${_unplannedLayerId}`
      );
      if (IS_MOUNTED) {
        if (unplannedShapeData.length === 0) {
          hideMapLoader();
        }

        renderUnplannedPostcodeShape(unplannedShapeData);
        setUnplannedShapeRendered(true);
      }
    }
    hideMapLoader();
  };

  const getAllTripShapes = async () => {
    let alltripShapesData;
    showMapLoader();

    if (subContractorSelected === "") {
      alltripShapesData = await Api.get(
        `${configSettings.AllTripShapesUrl}/${depotSelected}/${0}/${0}`
      );
    } else {
      alltripShapesData = await Api.get(
          `${configSettings.AllTripShapesUrl}/${depotSelected}/${0}/${0}/${subContractorSelected}`
      );
    }

    if (IS_MOUNTED) {
      if (alltripShapesData) {
        handleSelectAllTripShapes(alltripShapesData);
      } else {
        hideMapLoader();
      }
    }
  };

  const createTripDetails = async data => {
    setShowLoadingModal(true);
    setLoadingText("Loading...");

    let responseOk;

    fetch(`${configSettings.TripCreateUrl}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {
          responseOk = true;
        }
        return response.text();
      })
      .then(text => {
        if (responseOk) {
          setShowLoadingModal(false);

          setSuccessSnackbarMsg("Rit aangemaakt.");
          setSnackbarBGcolor("green");

          setSuccessSnackbarOpen(true);

          getTrips(depotSelected, subContractorSelected);
          getSubcontractors(depotSelected);
          setCreateTripModalVisible(false);
        } else {
          setErrorSnackbarMsg("Error creating Rit");
          setErrorSnackbarOpen(true);
          setShowLoadingModal(false);
        }
      })
      .catch(function(error) {
        console.log("Error creating Trip: ", error);
        setErrorSnackbarMsg("Error creating Rit");
        setErrorSnackbarOpen(true);
        setShowLoadingModal(false);
      });
  };

  const updateTripDetails = async data => {
    setShowLoadingModal(true);
    setLoadingText("Loading...");

    let responseOk;

    fetch(`${configSettings.TripDetailsUpdateUrl}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {
          responseOk = true;
        }
        return response.text();
      })
      .then(text => {
        if (responseOk) {
          setShowLoadingModal(false);
          setSuccessSnackbarMsg("Rit bijgewerkt");
          setSnackbarBGcolor("green");
          setSuccessSnackbarOpen(true);
          console.log(trips);
          const _trips = [...trips];
          _trips.forEach(trip => {
            if (trip.Id === data.ritId) {
              trip.Personeelstype = data.personeelsType;
              trip.Ritcode = data.ritCode;
              trip.Ritnaam = data.ritNaam;
              trip.Uitvoerder = data.uitvoerder;
              trip.VolumeRit = data.volumeRit;
            }
          });
          console.log(_trips);
          setTrips(_trips);
          getTrips(depotSelected, subContractorSelected);
          getSubcontractors(depotSelected);
        } else {
          setErrorSnackbarMsg("Error updating Rit");
          setErrorSnackbarOpen(true);
          setShowLoadingModal(false);
        }
      })
      .catch(function(error) {
        console.log("Error updating Trip Details: ", error);
        setErrorSnackbarMsg("Error updating Rit");
        setErrorSnackbarOpen(true);
        setShowLoadingModal(false);
      });
  };

  const csvExport = key => {
    setShowLoadingModal(true);
    setLoadingText("Loading...");

    let url;
    let depotName;
    let filename;

    allDepotsList.forEach(depot => {
      if (depot.Id === depotSelected) {
        depotName = depot.Naam;
      }
    });
    const scenarioId = 0;
    const params = `/${depotSelected}/${scenarioId}/${depotName}`;

    switch (key) {
      case "save":
        url = `${configSettings.ExportSaveUrl}`;
        break;
      case "mutation":
        url = `${configSettings.ExportMutationUrl}`;
        break;
      case "overview":
        url = `${configSettings.ExportOverviewUrl}`;
        break;
      default:
        break;
    }

    fetch(url + params)
      .then(response => {
        response.headers.forEach(function(val, key) {
          if (val && val.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(val);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
        });
        return response.blob();
      })
      .then(blob => {
        if (filename) {
          setShowLoadingModal(false);

          saveAs(blob, filename);
        } else {
          setShowLoadingModal(false);

          setErrorSnackbarOpen(true);
        }
      })
      .catch(function(error) {
        setShowLoadingModal(false);

        // console.log("Error getting CSV: ", error.message);
        setErrorSnackbarMsg("Error getting CSV: ", error.message);
        setErrorSnackbarOpen(true);
      });
  };

  const setAction = action => {
    setMapAction(action);
  };

  // correcting reselect of tripshape when postcodeShape is active in the same region
  const updatePostcodeBlockedTripshape = tripShapeId => {
    if (tripShapeId) {
      getCachedTripShapesData().forEach(tripShape => {
        if (tripShape.RitId === tripShapeId) {
          console.log(tripShape);
          setPostCodeBlockedTripShape(tripShape);
        }
      });
    }
  };

  const onImportFailed = () => {
    setErrorSnackbarOpen(true);
    setErrorSnackbarMsg("Import Failed");
  };

  const onImportSuccess = () => {
    setLoadingText("Import successful! Updating depot...");
    setShowLoadingModal(true);
    resetMap();
    setPostcodes([]);
    setTimeout(() => {
      setShowLoadingModal(false);
    }, 1000);
  };

  const downloadRanges = endpoint => {
    //
    let filename;

    setShowLoadingModal(true);
    setLoadingText("Creating Ranges...");

    fetch(endpoint + "/" + depotSelected)
      .then(response => {
        response.headers.forEach(function(val, key) {
          if (val && val.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(val);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
        });
        return response.blob();
      })
      .then(blob => {
        if (filename) {
          setShowLoadingModal(false);

          saveAs(blob, filename);
        } else {
          throw Error();
        }
      })
      .catch(function(error) {
        setShowLoadingModal(false);

        // console.log("Error getting CSV: ", error.message);
        setErrorSnackbarMsg("Error getting CSV: ", error.message);
        setErrorSnackbarOpen(true);
      });
  };

  return (
    <div id="infoContent">
      <Button
        size="small"
        className="normalcase"
        variant="outlined"
        onClick={() => openImportModal("retailerImport")}
      >
        <Icons.Upload />
        Retailerbestand uploaden
      </Button>
      &nbsp;
      {importButtonActive ? (
        <Button
          size="small"
          className="normalcase"
          variant="outlined"
          onClick={() => openImportModal("adresBe")}
        >
          <Icons.Upload />
          Inlezen huisnummerbestand Belgi&#235;
        </Button>
      ) : null}
      &nbsp;
      {importButtonActive ? (
        <Button
          size="small"
          className="normalcase"
          variant="outlined"
          onClick={() => openImportModal("catchAreas")}
        >
          <Icons.Upload />
          Import Verzorgingsgebieden
        </Button>
      ) : null}
      &nbsp;
      <Button
        size="small"
        className="normalcase"
        variant="outlined"
        onClick={() => openImportModal("subcos")}
      >
        <Icons.Upload />
        Import Uitvoerders
      </Button>
      <div className="bold underlined mt1">Filters</div>
      <div className="flex mb1">
        <div className="options">
          <div className="option flex">
            <label>Depot:</label>
            {allDepotsList.length ? (
              <div>
                {depots.length === 1 ? (
                  <span className="ml05">{allDepotsList[0].Naam}</span>
                ) : (
                  <Select
                    native
                    autoFocus
                    defaultValue="none"
                    onChange={e => handleFilterChange(e)}
                    disabled={depots ? false : true}
                    id="depotSelect"
                  >
                    <option value="none" disabled>
                      Selecteer een depot
                    </option>
                    {depots}
                  </Select>
                )}
              </div>
            ) : null}
          </div>

          <div className="option flex">
            <label>Uitvoerder:</label>
            <div>
              <Select
                native
                value={subContractorSelected}
                onChange={e => handleFilterChange(e)}
                disabled={subContractors ? false : true}
                id="subContractorSelect"
              >
                <option value="none" disabled>
                  Selecteer een uitvoerder
                </option>
                <option
                  key={configSettings.EmptyGuid}
                  value={configSettings.EmptyGuid}
                >
                  Alle uitvoerders
                </option>
                {subContractors}
              </Select>
            </div>
          </div>
        </div>
        {depotSelected ? (
          <div className="filemessage-actions">
            <div id="fileMessage" className="mt05">
              <div className="flex">
                <p className="mt0 nowrap filemessage-left">
                  Laatst ingelezen bestand:{" "}
                </p>
                {latestImport ? (
                  <p
                    className="mt0 ml05 ellipsis filemessage-right"
                    id="fileName"
                    title={latestImport.Filename}
                  >
                    {latestImport.Filename}
                  </p>
                ) : null}
              </div>
            </div>
            <div id="latestImportMessage" className="mt05">
              <div className="flex">
                <p className="mt0 nowrap filemessage-left">Status: </p>
                {latestImport ? (
                  <p
                    className="mt0 ml05 ellipsis filemessage-right"
                    id="latestImport"
                    title={latestImport.Description}
                  >
                    {functions.getImportStatusText(latestImport.Status)}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="actions flex">
              <Button
                size="small"
                className="normalcase"
                variant="outlined"
                onClick={() => openImportModal("normalImport")}
              >
                <Icons.Upload />
                Inlezen
              </Button>
              <Button
                size="small"
                className="normalcase"
                variant="outlined"
                onClick={() => csvExport("save")}
              >
                <Icons.Download />
                Opslaan
              </Button>
              <Button
                size="small"
                className="normalcase"
                variant="outlined"
                onClick={() => csvExport("mutation")}
              >
                <Icons.File />
                Mutaties
              </Button>
              <Button
                size="small"
                className="normalcase"
                variant="outlined"
                onClick={() => csvExport("overview")}
              >
                <Icons.File />
                Ritten overzicht
              </Button>
              {RangesActionsActive ? (
                <Button
                  size="small"
                  className="normalcase"
                  variant="outlined"
                  onClick={() =>
                    downloadRanges(configSettings.ExportDepotRanges)
                  }
                >
                  <DescriptionIcon />
                  Depot ranges
                </Button>
              ) : null}
              {RangesActionsActive ? (
                <Button
                  size="small"
                  className="normalcase"
                  variant="outlined"
                  onClick={() =>
                    downloadRanges(configSettings.ExportDepotRangeMutations)
                  }
                >
                  <SettingsEthernetIcon />
                  Mutaties ranges
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {depotSelected ? (
        <div className={classes.root}>
          <Tabs
            id="infoTabs"
            value={TabPanelValue}
            onChange={handleTabChange}
            classes={{
              indicator: classes.indicator
            }}
            textColor="primary"
            aria-label="info tabs"
          >
            <Tab label="Ritten" {...a11yProps(0)} />
            <Tab label="Import Queue" {...a11yProps(1)} />
          </Tabs>
          <TabPanel className="tabpanel" value={TabPanelValue} index={0}>
            {depotSelected ? (
              <div className="flex">
                <div className="route-container">
                  <div className="flex underlined route-container-header">
                    <p>Ritten</p>
                    <Button
                      size="small"
                      className="normalcase"
                      variant="outlined"
                      onClick={() => setCreateTripModalVisible(true)}
                    >
                      <Icons.Add />
                      Rit toevoegen
                    </Button>
                  </div>
                  <div className="compact-table selecting-table hide-pagination">
                    <TripTable
                      ref={TripsTableComponent => {
                        window.TripsTableComponent = TripsTableComponent;
                      }}
                      data={trips}
                      loading={tripsTableLoading}
                      getPostcodes={getPostcodes}
                      getPostcodeShapes={getPostcodeShapes}
                      showTripDetails={showTripDetails}
                      // sendSelectedForTripshapes={selectedForTripshapes}
                      updatePostcodeBlockedTripshape={
                        updatePostcodeBlockedTripshape
                      }
                      getAllTripShapes={getAllTripShapes}
                    />
                  </div>
                </div>
                {postcodes ? (
                  <div className="postcodes-container">
                    <div className="flex underlined postcodes-container-header">
                      <p>Postcodes</p>
                    </div>
                    <div className="compact-table selecting-table hide-pagination">
                      <PostcodesTable
                        ref={PostcodesTableComponent => {
                          window.PostcodesTableComponent = PostcodesTableComponent;
                        }}
                        data={postcodes}
                        setAction={setAction}
                        postCodeTransfer={postCodeTransfer}
                        postCodesSelected={postCodesSelected}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </TabPanel>
          <TabPanel className="tabpanel" value={TabPanelValue} index={1}>
            <ImportQueue
              depotId={depotSelected}
              refreshImportQueue={refreshImportQueue}
              updateLatestImport={latestImport => setLatestImport(latestImport)}
              refreshDepot={() =>
                getTrips(depotSelected, subContractorSelected)
              }
              clearPostcodes={() => setPostcodes([])}
              onImportFailed={() => onImportFailed()}
              onImportSuccess={() => onImportSuccess()}
              onQueueRefreshed={() => setRefreshImportQueue(false)}
            />
          </TabPanel>
        </div>
      ) : null}
      <Modal
        aria-labelledby="trip-details-modal-title"
        aria-describedby="trip-details-modal-description"
        open={tripModalVisible}
        onClose={() => setTripModalVisible(false)}
      >
        <div style={getModalStyle()} className="SimpleModal-paper-1">
          <TripDetailsModal
            rData={tripDetails}
            subContractors={subContractors}
            closeModal={() => setTripModalVisible(false)}
            submit={data => updateTripDetails(data)}
          />
        </div>
      </Modal>
      <Modal
        aria-labelledby="create-trip-modal-title"
        aria-describedby="create-trip-modal-description"
        open={createTripModalVisible}
        // onClose={() => setCreateTripModalVisible(false)}
      >
        <div style={getModalStyle()}>
          <CreateTripModal
            subContractors={subContractors}
            depotSelected={depotSelected}
            scenarioId={0}
            closeModal={() => setCreateTripModalVisible(false)}
            submit={data => createTripDetails(data)}
          />
        </div>
      </Modal>
      <Modal
        aria-labelledby="import-modal"
        aria-describedby="import-file-modal"
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
      >
              <ImportFileDropzone
                  onClose={() => setImportDialogOpen(false)}
                  savedFiles={file => savedImportFile(file)}
                  acceptedFiles={[".csv"]}
                  uploadExtension="csv"
        />
      </Modal>
      <LoadingModal
        showLoadingModal={showLoadingModal}
        loadingText={loadingText}
      />
      <ErrorSnackbar
        open={errorSnackbarOpen}
        errorMessage={errorSnackbarMsg}
        closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
      />
      <SuccessSnackbar
        open={successSnackbarOpen}
        successMessage={successSnackbarMsg}
        BGcolor={snackbarBGcolor}
        closeSuccessSnackbar={() => setSuccessSnackbarOpen(false)}
      />
      {/* {ShowCreateRangesModal && (
        <CreateRangesModal
          ShowCreateRangesModal={ShowCreateRangesModal}
          downloadRanges={downloadRanges}
        />
      )} */}
    </div>
  );
};
export default AdminInfoContent;
