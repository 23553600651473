import React, { useState } from "react";

const AuthContext = React.createContext();

const AuthProvider = props => {
  const [state, setState] = useState({
    authStatus: "checkingAuthStatus",
    currentUser: {},
    globalSelectedDepotId: null,
    globalSelectedDepot: JSON.parse(
      sessionStorage.getItem("GlobalSelectedDepot")
    )
  });
  return (
    <AuthContext.Provider value={[state, setState]}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
