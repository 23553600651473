import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Icons from "../Icons";

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5)
  },
  root: {
    background: "rgb(76,175,80)"
  }
});

class SuccessSnackbar extends React.Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    // console.log(event);
    this.props.closeSuccessSnackbar();
  };

  render() {
    const { classes } = this.props;
    return (
      <Snackbar
        className={this.props.BGcolor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={this.props.open}
        autoHideDuration={3000}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
          classes: {
            root: classes.root
          }
        }}
        message={
          <span key={1} id="message-id">
            {this.props.successMessage}
          </span>
        }
        action={[
          <div
            key={2}
            className="pointer snackbar-close"
            onClick={this.handleClose}
          >
            <Icons.Close />
          </div>
        ]}
      />
    );
  }
}

SuccessSnackbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SuccessSnackbar);
