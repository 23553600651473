import React, { Component } from "react";
import ReactTable from "react-table";
import Icons from "../Icons";
import "react-table/react-table.css";

export default class TIRequestsTable extends Component {
  state = {
    data: [],
    loading: true
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ selectedRows: [], selectedRowsData: [] });
    }
  }

  render() {
    const data = this.props.data;
    return (
      <div id="TIRequestTable" className="compact-table hide-pagination">
        <ReactTable
          data={data}
          resolveData={data => data.map(row => row)}
          loading={this.props.loading}
          loadingText={
            <div className="grid-custom-loader">
              <Icons.Loading />
              <b>Loading</b>
            </div>
          }
          columns={[
            {
              Header: "Id",
              id: "Id",
              accessor: d => d.Id,
              show: false
            },
            {
              Header: "Date Request",
              id: "CreatedDate",
              accessor: d => d.CreatedDate,
              className: "column-align-left",
              width: 150
            },
            {
              Header: "Ritten",
              id: "Routes",
              accessor: d => d.Routes
            },
            {
              Header: "Status",
              id: "StatusMessage",
              accessor: d => d.StatusMessage,
              width: 300
            },
            {
              Header: "Downloaden",
              id: "downloadTIRequest",
              className: "column-align-center",
              width: 90,
              Cell: row =>
                row.original.StatusMessage &&
                row.original.StatusMessage.indexOf("bereken") > -1 ? (
                  <div
                    className={`download-link`}
                    onClick={() =>
                      this.props.downloadTIRequest(row.original.Id)
                    }
                  >
                    <Icons.Download />
                  </div>
                ) : (
                  ""
                )
            }
          ]}
          defaultSorted={[
            {
              id: "CreatedDate",
              desc: true
            }
          ]}
          pageSize={data.length}
          noDataText="Geen TI-Requests"
          className="-striped -highlight"
        />
      </div>
    );
  }
}
