import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import Main from "./Main";

import { AuthProvider } from "./context/AuthContext";

const ol = window.ol;
// const proj4 = window.proj4;

var olmap = new ol.Map({});

window.olmap = olmap;

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
