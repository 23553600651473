import React, { Component } from "react";
import ReactTable from "react-table";
import Icons from "../Icons";
import "react-table/react-table.css";
import DetailsIcon from "../Icons/details-blue.svg";

export default class SubcontractorsOverviewTable extends Component {
  state = {
    data: []
  };

  render() {
    const data = this.props.data;
    return (
      <div id="subcoOverviewTable" className="compact-table hide-pagination">
        <ReactTable
          data={data}
          resolveData={data => data.map(row => row)}
          loading={this.props.loading}
          loadingText={
            <div className="grid-custom-loader">
              <Icons.Loading />
              <b>Loading</b>
            </div>
          }
          columns={[
            {
              Header: "Datum TI-aanvraag",
              id: "CreatedDate",
              Cell: row => (
                <div className="flex-start">
                  {row.original.CreatedDate.split("T")[0]}
                </div>
              ),
              width: 160
            },
            {
              Header: "Uitvoerder",
              id: "Subcontractor",
              Cell: row => (
                <div className="flex-start">{row.original.Name}</div>
              ),
              width: 270
            },
            {
              Header: "#",
              id: "NumberOfRitten",
              Cell: row => (
                <div className="flex-start">{row.original.NumberOfRitten}</div>
              ),
              width: 50
            },
            {
              Header: "Ritten",
              id: "Ritten",
              accessor: d => d.Ritten.map(route => route.Ritnaam).join("\r\n"),
              sortable: false
            },
            {
              Header: "Contractdetails",
              Cell: row => (
                <div onClick={e => this.props.goToContractDetails(e, row)}>
                  <img
                    className="pointer"
                    id="detailsIcon"
                    src={DetailsIcon}
                    alt="Details"
                  />
                </div>
              ),
              sortable: false,
              width: 130
            },
            {
              Header: "Id",
              id: "Id",
              accessor: d => d.Id,
              show: false
            },
            {
              Header: "RequestGuid",
              id: "RequestGuid",
              accessor: d => d.RequestGuid,
              show: false
            }
          ]}
          pageSize={data.length}
          noDataText="Geen Contracts"
          className="-striped -highlight"
        />
      </div>
    );
  }
}
