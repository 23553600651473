import React from "react";
import Icons from "../Icons";
import ReactTable from "react-table";
import "react-table/react-table.css";
import configSettings from "../../settings";

// drag and drop library
import interact from "interactjs";
import PostcodeInfoModal from "../Modals/PostcodeInfoModal";
import {
  selectPostcodeShapes,
  postcodeTransferByDragHandler
} from "../Map/AdminMapController";
import functions from "../../helpers/functions";

// var startPos = { x: 0, y: 0 };
var shouldCreateClone = true;

class PostcodesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataLength: 0,
      loading: true,
      columns: [],
      selected: [],
      pageSize: this.props.pageSize ? this.props.pageSize : 25,
      pageSizeOptions: this.props.pageSizeOptions
        ? this.props.pageSizeOptions
        : [5, 10, 25, 50, 100, 200],
      paginationEnabled: true,
      postcodeInfoModalVisible: false,
      postcodeInfoData: {},
      selectedIds: []
    };
  }

  componentDidMount() {
    this.renderTable(this.props.data);
    this.initDragAndDrop();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        selected: []
      });
      this.renderTable(this.props.data);
    }
  }

  hideFooter = () => {
    const footerElement = document.querySelector(
      ".postcodes-container .rt-tfoot"
    );
    if (footerElement) {
      document.querySelector(".postcodes-container .rt-tfoot").style.display =
        "none";
    }
  };

  renderTable = data => {
    const dataObject = [];

    data.forEach(entry => {
      dataObject.push({
        Id: entry.Id,
        TripId: entry.RitId,
        Postcodes: entry.Postcode6,
        Stops: entry.Stops,
        Parcels: entry.Stuks,
        Norm: entry.Norm,
        Real: entry.Realisatie,
        Org: entry.Ritcode_Org,
        StreetName: entry.Straatnaam,
        HuisnrVan: entry.HuisnrVan,
        HuisnrTotenMet: entry.HuisnrTotenMet,
      });
    });

    const dataSortedByPostcodes = [
      ...functions.objsArraySortByProp(dataObject, "Postcodes")
    ];

    dataSortedByPostcodes.forEach((entry, index) => {
      entry.Index = index;
    });

    this.setState({
      selectedIds: [],
      data: dataSortedByPostcodes,
      loading: false
    });
  };

  // Called from Admin Map Controller in order
  // to update Postcodes after a postcode transfer
  // unused due to Norm and Real properties have different
  // formats between postcodes and geodatas endpoint
  addTransferedPostcode = postcodeDetails => {
    let postcodesData = [...this.state.data];

    postcodesData.unshift({
      Id: postcodeDetails.PostcodeId,
      Postcodes: postcodeDetails.Postcode,
      Stops: postcodeDetails.NumberOfStops,
      Parcels: postcodeDetails.NumberOfParcels,
      Norm: postcodeDetails.TI,
      Real: postcodeDetails.Real,
      Org: postcodeDetails.Org,
      StreetName: postcodeDetails.StreetName,
      HuisnrTotenMet: postcodeDetails.HuisnrTotenMet,
      HuisnrVan: postcodeDetails.HuisnrVan
    });

    this.setState({ data: postcodesData });
  };

  // Called from Admin Map Controller in order
  // to update Postcodes after a postcode transfer
  deleteTransferedPostcode = postcodeDetails => {
    let postcodesData = [...this.state.data];

    let indexToRemove;
    postcodesData.forEach((postcode, index) => {
      if (postcode.Id === postcodeDetails.Id) {
        indexToRemove = index;
      }
    });

    postcodesData.splice(indexToRemove, 1);

    this.setState({
      data: postcodesData
    });
  };

  calculateMs = timeString => {
    //
    let msTotal = 0;

    let timeArr = timeString;
    timeArr = timeArr.split(":");
    let hour = parseInt(timeArr[0]);
    let min = parseInt(timeArr[1]);

    let secAndMillis = timeArr[2].split(".");
    let sec = parseInt(secAndMillis[0]);

    let ms = parseInt(secAndMillis[1] / 10000);

    msTotal += ms;
    msTotal += sec * 1000;
    msTotal += min * 60 * 1000;
    msTotal += hour * 60 * 60 * 1000;

    return msTotal;
  };

  msToHMS = ms => {
    // 1- Convert to seconds:
    var seconds = ms / 1000;
    // 2- Extract hours:
    var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  onSortedChange = newSorted => {
    const propToSort = newSorted[0].id;
    const sortDirection = newSorted[0].desc ? "descending" : "ascending";
    const data = this.state.data;

    const sortedData = functions.objsArraySortByProp(
      data,
      propToSort,
      sortDirection
    );

    sortedData.forEach((entry, index) => {
      entry.Index = index;
    });

    this.setState({ data: sortedData });
  };


  selectRow = (e, row) => {
    let selectedRows = this.state.selected;

    e.stopPropagation();

    /*
    if (e.ctrlKey) {
      // Ctrl + click
      if (selectedRows.indexOf(row.original.Index) !== -1) {
        selectedRows.splice(selectedRows.indexOf(row.original.Index), 1);
      } else {
        selectedRows.push(row.original.Index);
      }
    } else if (e.shiftKey) {
      // Shift + click
      selectedRows.push(row.original.Index);
      if (selectedRows.length > 1) {
        let max = Math.max.apply(null, selectedRows);
        let min = Math.min.apply(null, selectedRows);
        selectedRows = [];
        for (let i = min; i <= max; i++) {
          selectedRows.push(i);
        }
      }
    } else {
      // Simple click
      selectedRows = [row.original.Index];
    }
    */

   // 14-11-2023 - row.index instead of row.original.Index because of error after moving from unplanned to planned

    if (e.ctrlKey) {
    // Ctrl + click
    if (selectedRows.indexOf(row.index) !== -1) {
        selectedRows.splice(selectedRows.indexOf(row.index), 1);
    } else {
        selectedRows.push(row.index);
    }
  } else if (e.shiftKey) {
    // Shift + click
    selectedRows.push(row.index);
    if (selectedRows.length > 1) {
        let max = Math.max.apply(null, selectedRows);
        let min = Math.min.apply(null, selectedRows);
        selectedRows = [];
        for (let i = min; i <= max; i++) {
            selectedRows.push(i);
        }
    }
  } else {
    // Simple click
    selectedRows = [row.index];
  }


    let totalStops = 0;
    let totalParcels = 0;
    let totalNorm = 0;
    let totalNormHMS = "";
    let totalReal = 0;
    let totalRealHMS = "";

    if (selectedRows.length > 1) {
      document.querySelector(".postcodes-container .rt-tfoot").style.display =
        "block";

      selectedRows.forEach(rowSelected => {
        const rowValues = this.state.data[rowSelected];
        totalStops += rowValues.Stops;
        totalParcels += rowValues.Parcels;
        totalNorm += this.calculateMs(rowValues.Norm);
        totalReal += this.calculateMs(rowValues.Real);
      });
      totalNormHMS = this.msToHMS(totalNorm);
      let totalNormArr = totalNormHMS.split(":");
      totalNorm = totalNormArr[0] + ":" + totalNormArr[1];

      totalRealHMS = this.msToHMS(totalReal);
      let totalRealArr = totalRealHMS.split(":");
      totalReal = totalRealArr[0] + ":" + totalRealArr[1];

      document.getElementById("totalStops").innerHTML =
        Math.round(totalStops * 10) / 10;
      document.getElementById("totalParcels").innerHTML =
        Math.round(totalParcels * 10) / 10;
      document.getElementById("totalNorm").innerHTML = totalNorm;
      document.getElementById("totalReal").innerHTML = totalReal;
    } else {
      this.hideFooter();
    }

    this.setState({ selected: selectedRows });

    const postCodeIds = [];
    const gridData = [...this.state.data];

    selectedRows.forEach(row => {
      postCodeIds.push(gridData[row].Id);
    });

    this.setState({ selectedIds: postCodeIds });

    selectPostcodeShapes(postCodeIds);
  };

  getPostCodeIds = toTripId => {
    const selectedRows = this.state.selected;
    const gridData = [...this.state.data];
    const fromTripId = gridData[0].TripId.toString();

    // console.log(gridData);

    if (fromTripId === toTripId) {
      // same trip region - cant send postcodes
      console.log("Same trip region");
    } else {
      const postCodeIds = [];

      selectedRows.forEach(row => {
        postCodeIds.push(gridData[row].Id);
      });

      postCodeIds.forEach(postCode => {
        gridData.forEach((entry, index) => {
          if (entry.Id === postCode) {
            gridData.splice(index, 1);
          }
        });
      });

      let _fromTripId = parseInt(fromTripId, 10);
      let _toTripId = parseInt(toTripId, 10);

      this.setState({ data: gridData, selected: [] });
      this.transferPostcodes(_fromTripId, _toTripId, postCodeIds);
    }
  };

  transferPostcodes = (fromTripId, toTripId, postCodeIds) => {
    const postData = {
      postcodeIds: postCodeIds,
      fromRitId: fromTripId,
      toRitId: toTripId
    };

    fetch(`${configSettings.PostcodesUrl}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.ok) {
          postcodeTransferByDragHandler(fromTripId, toTripId, postCodeIds);
        } else {
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .catch(console.error);
  };

  initDragAndDrop = () => {
    const thisClass = this;

    interact(".postcode-selected")
      .draggable({
        manualStart: true,
        // onstart: this.dragStartListener,
        onmove: this.dragMoveListener,
        onend: this.dragEndListener,
        inertia: false
      })
      .on("move", function(event) {
        var interaction = event.interaction;

        // if the pointer was moved while being held down
        // and an interaction hasn't started yet
        if (interaction.pointerIsDown && !interaction.interacting()) {
          // var original = event.currentTarget;
          // create a clone of the currentTarget element
          let draggingBlock = document.createElement("div");

          if (shouldCreateClone) {
            shouldCreateClone = false;

            // var clone = event.currentTarget.cloneNode(true);

            // Create dragging div with clone
            let dragTable = document.createElement("table");
            dragTable.classList.add("ReactTable");
            draggingBlock.append(dragTable);
            draggingBlock.classList.add("dragging-block");
            const infoContent = document.getElementById("infoContent");
            infoContent.append(draggingBlock);
            // dragTable.appendChild(clone);

            // Clone selected rows and put them in dragging table
            let rowCollection = document.querySelectorAll(
              ".postcodes-table .postcode-selected"
            );
            for (let i = 0; i < rowCollection.length; i++) {
              let target = rowCollection[i];
              const cln = target.cloneNode(true);
              dragTable.append(cln);
            }

            // insert the clone to the page
            // start a drag interaction targeting the clone
            interaction.start(
              { name: "drag" },
              event.interactable,
              draggingBlock
            );

            draggingBlock.style.top = event.clientY + "px";
            draggingBlock.style.left = event.clientX + "px";
          } else {
            console.log("clone already created");

            interaction.start(
              { name: "drag" },
              event.interactable,
              draggingBlock
            );
          }
        }
      });

    // var dropCenter;
    interact(".trip-row").dropzone({
      // only accept elements matching this CSS selector
      accept: ".dragging-block",
      // Require a 25% element overlap for a drop to be possible
      overlap: 0.25,

      // listen for drop related events:

      ondropactivate: function(event) {
        // add active dropzone feedback
        event.target.classList.add("drop-active");
      },
      ondragenter: function(event) {
        var draggableElement = event.relatedTarget;
        var dropzoneElement = event.target;

        // feedback the possibility of a drop
        dropzoneElement.classList.add("drop-target");
        draggableElement.classList.add("can-drop");
        // draggableElement.textContent = "Dragged in";
      },
      ondragleave: function(event) {
        // remove the drop feedback style
        event.target.classList.remove("drop-target");
        event.relatedTarget.classList.remove("can-drop");
      },
      ondrop: function(event) {
        thisClass.getPostCodeIds(event.target.id);
        thisClass.hideFooter();
      },
      ondropdeactivate: function(event) {
        // remove active dropzone feedback
        event.target.classList.remove("drop-active");
        event.target.classList.remove("drop-target");
      }
    });
  };

  dragStartListener = event => {
    console.log("start listener");
    //   //
  };

  dragMoveListener = event => {
    //
    // console.log("move listener", event);
    let draggingBlock = document.querySelector(".dragging-block");
    draggingBlock.style.top = event.clientY + "px";
    draggingBlock.style.left = event.clientX + "px";
  };

  dragEndListener = event => {
    let draggingBlock = document.querySelector(".dragging-block");
    draggingBlock.parentNode.removeChild(draggingBlock);
    shouldCreateClone = true;
  };

  showPostcodeInfo = (e, row) => {
    this.setState({
      postcodeInfoData: row.original,
      postcodeInfoModalVisible: true
    });
  };

  render() {
    const data = this.state.data;

    return (
      <div>
        <ReactTable
          columns={[
            {
              columns: [
                {
                  Header: "PC",
                  id: "Postcodes",
                  accessor: "Postcodes",
                  width: 58,
                  Footer: (
                    <span>
                      <strong>Totaal Selectie:</strong>
                    </span>
                  )
                },
                {
                  Header: "Hnr van",
                  id: "HuisnrVan",
                  accessor: d => d.HuisnrVan,
                  width: 58,
                  Footer: <span id="HuisnrVan" />
                },
                {
                  Header: "Hnr t/m",
                  id: "HuisnrTotenMet",
                  accessor: d => d.HuisnrTotenMet,
                  width: 58,
                  Footer: <span id="HuisnrTotenMet" />
                },
                {
                  Header: "Stops",
                  id: "Stops",
                  accessor: d => d.Stops,
                  width: 45,
                  Footer: <span id="totalStops" />
                },
                {
                  Header: "Stuks",
                  id: "Parcels",
                  accessor: d => d.Parcels,
                  width: 45,
                  Footer: <span id="totalParcels" />
                },
                {
                  Header: "TI",
                  id: "Norm",
                  accessor: d => d.Norm.substring(3,8),
                  width: 45,
                  Footer: <span id="totalNorm" />
                },
                {
                  Header: "Real.",
                  id: "Real",
                  accessor: d => d.Real.substring(3,8),
                  width: 45,
                  Footer: <span id="totalReal" />
                },
                {
                  Header: "Org.",
                  id: "Org",
                  accessor: d => d.Org,
                  width: 50
                },
                {
                  Header: "StreetName",
                  id: "StreetName",
                  accessor: d => d.StreetName,
                  width: 50,
                  show: false
                },
                {
                  Header: "",
                  id: "ViewMore",
                  className: "column-align-left",
                  Cell: row => (
                    <div
                      onClick={e => {
                        this.showPostcodeInfo(e, row);
                      }}
                    >
                      <Icons.ViewMore className="p2" />
                    </div>
                  ),
                  width: 30,
                  show: true
                }
              ]
            }
          ]}
          className="-striped -highlight postcodes-table"
          data={data}
          resolveData={data => data.map(row => row)}
          defaultPageSize={15}
          pageSize={data ? data.length : 0}
          loading={this.state.loading}
          loadingText={
            <div className="grid-custom-loader">
              <Icons.Loading />
              <b>Loading</b>
            </div>
          }
          noDataText="Geen Postcodes"
          // defaultSorted={[
          //   {
          //     id: "Postcodes",
          //     desc: false
          //   }
          // ]}
          onSortedChange={(newSorted, column, shiftKey) => {
            this.onSortedChange(newSorted);
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: e => this.selectRow(e, rowInfo),
                style: {
                  background: this.state.selectedIds.includes(
                    rowInfo.original.Id
                  )
                    ? "#2196f3"
                    : null,
                  color: this.state.selectedIds.includes(rowInfo.original.Id)
                    ? "white"
                    : null
                },
                className: this.state.selectedIds.includes(rowInfo.original.Id)
                  ? "postcode-selected postcode-row"
                  : "postcode-row"
              };
            } else {
              return {};
            }
          }}
        />
        <PostcodeInfoModal
          data={this.state.postcodeInfoData}
          postcodeInfoModalVisible={
            this.state.postcodeInfoModalVisible &&
            Object.keys(this.state.postcodeInfoData).length > 0
          }
          closePostcodeInfoModal={() =>
            this.setState({ postcodeInfoModalVisible: false })
          }
        />
      </div>
    );
  }
}
export default PostcodesTable;
