import React, { useEffect, useState } from "react";
import "../../styles/app.scss";

import Api from "../../helpers/http";
import configSettings from "../../settings";

import Button from "@material-ui/core/Button";
import Icons from "../Icons";
import Modal from "@material-ui/core/Modal";
import TrapPostcodesTable from "../Tables/TrapPostcodesTable";
import TrapRitTable from "../Tables/TrapRitTable";
import TrapScenario from "./TrapScenario";
import Select from "@material-ui/core/Select";
import useMap from "../../hooks/useMap";
import useAuth from "../../hooks/useAuth";

import { saveAs } from "file-saver";

import TripDetailsModal from "../Modals/TripDetailsModal";
import ErrorSnackbar from "../Utils/ErrorSnackbar";
import SuccessSnackbar from "../Utils/SuccessSnackbar";
import ImportFileDropzone from "../Modals/ImportFileDropzone";
import LoadingModal from "../Modals/LoadingModal";
import CreateTripModal from "../Modals/CreateTripModal";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ImportQueue from "../Tables/ImportQueue";

import {
    addDepotMarker,
    hideMapLoader,
    showMapLoader,
    renderPlannedPostcodeShape,
    renderUnplannedPostcodeShape,
    handleSelectAllTripShapes,
    handleDepotCoords,
    saveUnplannedLayerId,
    unplanPostcodesAfterDrag,
    replanPostcodesAfterDrag,
    resetMap
} from "../Map/AdminMapController";
import functions from "../../helpers/functions";

const trapScenarioStatusses = [0, 1, 2, 3, 4, 5, 6];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box className="box-root" p={3}>
                {children}
            </Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    indicator: {
        backgroundColor: "rgba(255,255,255,0)"
    }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        position: "absolute",
        backgroundColor: "white",
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

var _unplannedLayerId;
let IS_MOUNTED;

const TrapInfoContent = props => {
    const [tripModalVisible, setTripModalVisible] = useState(false);
    const [createTripModalVisible, setCreateTripModalVisible] = useState(false);
    const [depots, setDepots] = useState([""]);
    const [allDepotsList, setAllDepotsList] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [scenarioId, setScenarioId] = useState(0);
    const [validationMessage, setValidationMessage] = useState("");
    const [trapStapStatusCurrent, setTrapStapStatusCurrent] = useState(1); // Scenario.StatusCode
    const [trapStapCurrentDescription, setTrapStapCurrentDescription] = useState("");
    const [scenarioName, setScenarioName] = useState("us init sc name");
    const [scenarioValue, setScenarioValue] = useState('{"id":0}'); // none
    const [uploadExtension, setUploadExtension] = useState("");
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [depotSelected, setDepotSelected] = useState(false);
    const [depotIdCurrent, setDepotIdCurrent] = useState(0);
    const [latestImport, setLatestImport] = useState(null);
    const [postcodes, setPostcodes] = useState([]);
    const [trips, setTrips] = useState([]);
    const [tripsTableLoading, setTripsTableLoading] = useState(true);
    const [tripDetails, setTripDetails] = useState(null);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [snackbarBGcolor, setSnackbarBGcolor] = useState("green");
    const [successSnackbarMsg, setSuccessSnackbarMsg] = useState("");
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [uploadEndpoint, setUploadEndpoint] = useState("");
    const [loadingText, setLoadingText] = useState("Loading...");
    const [refreshImportQueue, setRefreshImportQueue] = useState(false);
    const [UnplannedShapeRendered, setUnplannedShapeRendered] = useState(false);
    const [currentDepotOptionValues, setCurrentDepotOptionValues] = useState(null);
    const [mapStatus, setMapStatus] = useState(0); // start: map on, variables off (TrapScreen)

    const {
        getCachedTripShapesData,
        getSelectedTripShapes,
        setSelectedTripShapes,
        setPostCodeBlockedTripShape,
        setMapAction,
        setUnplannedLayerId,
        modifiedTripDetails,
        unplannedTripDetails,
        setSelectedPostcodes
    } = useMap();

    const classes = useStyles();
    const {
        currentUser,
        globalSelectedDepot,
        setGlobalSelectedDepotId,
        setGlobalSelectedDepot
    } = useAuth();

    const [TabPanelValue, setTabPanelValue] = React.useState(0);
    function handleTabChange(event, newValue) {
        setTabPanelValue(newValue);
    }

    const createScenario = (name) => {
        setShowLoadingModal(false);
        trapSaveVarDepot(depotIdCurrent, scenarioId, 0, name); // todo:RSI add to user input 1 - 150000
    }

    useEffect(() => {
        if (depotIdCurrent === 0 && globalSelectedDepot) {
            // check if regular depot is available as trap depot, current global depot might not be in the trap depot list
            setDepotIdCurrent(globalSelectedDepot.Id);
        }
        setScenarioListForDepot(true);
        IS_MOUNTED = true;

        return () => {
            IS_MOUNTED = false;
        };
    }, []);

    useEffect(() => {
        getDepotsList();
    }, [currentUser]);

    useEffect(() => {
        setTrapStapStatusCurrent(trapScenarioStatusses[1]);
        if (depotIdCurrent !== 0) {
            setScenarioListForDepot(true);
        }
    }, [depotIdCurrent]);

    useEffect(() => {
        if (depotIdCurrent !== 0) {
            setPostcodes(null);
            // get scenario status
            if (scenarioId !== 0) {
                applyScenario(scenarioId);
                setScenarioValue(JSON.stringify({ id: scenarioId }));
                refreshList(depotIdCurrent, scenarioId, 'subco_last');
            }
        }
    }, [scenarioId]);

    useEffect(() => {
        applyStapTitleDescription(trapStapStatusCurrent);
    }, [trapStapStatusCurrent]);

    useEffect(() => {
        // Set checked Trips for Trip Table
        if (getSelectedTripShapes().length) {
            const _selectedTripShapes = [...getSelectedTripShapes()];
            const _trips = [...trips];
            _trips.forEach(trip => {
                if (_selectedTripShapes.includes(trip.Id)) {
                    trip.isChecked = true;
                } else {
                    trip.isChecked = false;
                }
            });
            setTrips(_trips);
        }
    }, [getSelectedTripShapes().length]);

    useEffect(() => {
        if (Object.keys(unplannedTripDetails).length) {
            const tempTrips = [...trips];
            tempTrips[0] = unplannedTripDetails;
            setTrips(tempTrips);
        }
    }, [unplannedTripDetails]);

    useEffect(() => {
        let indexToChange;
        if (Object.keys(modifiedTripDetails).length) {
            // console.log(getSelectedTripShapes());
            trips.forEach((trip, index) => {
                if (trip.Id === modifiedTripDetails.Id) {
                    indexToChange = index;
                }
            });
            const tempTrips = [...trips];
            tempTrips[indexToChange] = modifiedTripDetails;
            tempTrips[indexToChange].isSelected = true;
            tempTrips[indexToChange].isChecked = true;
            tempTrips[0] = unplannedTripDetails;
            setTrips(tempTrips);
        }
    }, [modifiedTripDetails]);

    useEffect(() => {
        props.toggleMapStatus(mapStatus);
        if (mapStatus === 1) {
            handleDepotCoords(currentDepotOptionValues);
        }
      }, [mapStatus]);

    const setScenarioListForDepot = async (setScenarioToNone) => {
        var scenarioList = await Api.get(`${configSettings.TrapGetScenariosByDepot}/${depotIdCurrent}`);

        var scenarioOptions = [];

        if (scenarioList.length === 0) {
        } else {
            scenarioList.forEach(item => {
                scenarioOptions.push(
                    <option
                        key={item.Id}
                        value={JSON.stringify({
                            id: item.Id
                        })}
                    >
                        {item.Naam}
                    </option>
                );
            });
        }

        setScenarios(scenarioOptions);
        if (setScenarioToNone) {
            setScenarioValue('{"id":0}'); // none
        }
    };

    const applyScenario = async (scenarioId) => {
        if (scenarioId > 0) {
            const scenario = await Api.get(`${configSettings.TrapGetScenarioById}/${scenarioId}`);
            setTrapStapStatusCurrent(scenario.StatusCode);
        }
    }

    const applyStapTitleDescription = (status) => {
        let strapStapDescription;

        switch (status) {
            case 1:
                {
                    strapStapDescription = "Stap 1 - Scenario aanmaken";
                    break;
                }
            case 2:
                {
                    strapStapDescription = "Stap 2 - Inputbestand importeren";
                    break;
                }
            case 3:
                {
                    strapStapDescription = "Stap 3 - Parameters invullen";
                    break;
                }
            case 4:
                {
                    strapStapDescription = "Stap 4 - Wacht op resultaat";
                    break;
                }
            case 5:
                {
                    strapStapDescription = "Stap 5 - Controleren";
                    break;
                }
            case 6:
                {
                    strapStapDescription = "Stap 6 - Opslaan";
                    break;
                }
            default:
                {
                    strapStapDescription = "Stap 1 - Scenario aanmaken";
                    break;
                }
        }

        setTrapStapCurrentDescription(strapStapDescription);
    }

    const getDepotsList = async () => {
        const depotsList = await Api.get(`${configSettings.DepotsApiUrl}/true`);

        if (IS_MOUNTED) {
            setAllDepotsList(depotsList);

            if (depotsList.length) {
                const DepotsOptions = [];

                depotsList.forEach(depot => {
                    DepotsOptions.push(
                        <option
                            key={depot.Id}
                            value={JSON.stringify({
                                Lon: depot.Longitude,
                                Lat: depot.Latitude,
                                Id: depot.Id
                            })}
                        >
                            {depot.Naam}
                        </option>
                    );
                });
                setDepots(DepotsOptions);

                if (depotsList.length === 1 || globalSelectedDepot) {
                    let depotOptionValues;

                    if (depotsList.length === 1) {
                        depotOptionValues = JSON.parse(DepotsOptions[0].props.value);
                    } else if (globalSelectedDepot) {
                        const tempDepotOptionValues = depotsList.find(
                            el => el.Id === globalSelectedDepot.Id
                        );
                        depotOptionValues = {
                            Lon: tempDepotOptionValues.Longitude,
                            Lat: tempDepotOptionValues.Latitude,
                            Id: globalSelectedDepot.Id
                        };
                        const depotSelectElem = document.getElementById("depotSelect");
                        depotSelectElem.value = JSON.stringify(depotOptionValues);
                    }
                    setCurrentDepotOptionValues(depotOptionValues);
                    if (mapStatus === 1) {
                        handleDepotCoords(depotOptionValues); // todo:RSI toggle depending if map is there
                    }
                    getDepotInfo(depotOptionValues.Id);
                    getLatestImportStatus(depotOptionValues.Id);
                    setPostcodes(null);
                    setUnplannedShapeRendered(false);
                }
            }
        }
    };

    const openImportModal = async importType => {
        let _uploadEndpoint;

        setAcceptedFiles([".csv"]);
        setUploadExtension("csv");

        if (importType === "retailerImport") {
            _uploadEndpoint = "RL";
        } else if (importType === "adresBe") {
            _uploadEndpoint = "AB";
        } else if (importType === "catchAreas") {
            _uploadEndpoint = "VG";
        } else if (importType === "subcos") {
            _uploadEndpoint = "SC";
        } else {
            _uploadEndpoint = "Normal";
        }

        setUploadEndpoint(_uploadEndpoint);
        setImportDialogOpen(true);
    };

    const createGuid = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    const savedImportFile = file => {
        const guid = createGuid();

        let url;

        if (uploadEndpoint === "RL") {
            url = `${configSettings.ImportRLUrl}?sessionIdentifier=${guid}`;
        } else if (uploadEndpoint === "AB") {
            url = `${configSettings.ImportABUrl}?sessionIdentifier=${guid}`;
        } else if (uploadEndpoint === "VG") {
            url = `${configSettings.ImportVGUrl}`;
        } else if (uploadEndpoint === "SC") {
            url = `${configSettings.ImportSCUrl}`;
        } else {
            url = `${configSettings.ImportNormalUrl}?depotId=${depotSelected}&sessionIdentifier=${guid}&scenarioId=${scenarioId}`;
        }

        setShowLoadingModal(true);
        setLoadingText("Het bestand wordt verwerkt in de database");

        var data = new FormData();
        data.append("files", file);

        let responseOk;

        fetch(url, {
            method: "POST",
            body: data
        })
            .then(response => {
                if (response.ok) {
                    responseOk = true;
                }
                return response.text();
            })
            .then(text => {
                if (responseOk) {
                    setShowLoadingModal(false);
                    setRefreshImportQueue(true);
                    getLatestImportStatus(depotSelected);
                    setSuccessSnackbarMsg("Het bestand is succesful opgeslagen, de verwerking wordt gestart.");
                    setSnackbarBGcolor("orange");
                    setSuccessSnackbarOpen(true);
                    setImportDialogOpen(false);
                } else {
                    throw Error(text);
                }
            })
            .catch(function (error) {
                console.log("Error sending .csv file: ", error);
                setShowLoadingModal(false);
                setErrorSnackbarMsg(error.toString());
                setErrorSnackbarOpen(true);
                setImportDialogOpen(false);
            });
    };

    // Select Filters handler
    const handleFilterChange = e => {
        const selectId = e.target.id;
        const value = e.target.value;

        setSelectedTripShapes([]);
        setSelectedPostcodes([]);

        if (document.querySelector(".route-container .-loading.-active")) {
            document.querySelector(".route-container .-loading").style.display =
                "block";
        }
        //setTripsTableLoading(true);

        if (selectId === "depotSelect") {
            // Depot select
            const depotOptionValues = JSON.parse(value);
            setCurrentDepotOptionValues(depotOptionValues);
            if (mapStatus === 1) {
                handleDepotCoords(depotOptionValues); // todo:RSI toggle depending if map is there
            }
            //alert('setScenarioId(0);')
            setScenarioId(0);
            //alert('setDepotIdCurrent(depotOptionValues.Id); - ' + depotOptionValues.Id);
            setDepotIdCurrent(depotOptionValues.Id);
            getDepotInfo(depotOptionValues.Id);
            getLatestImportStatus(depotOptionValues.Id);
            setPostcodes(null);
            setUnplannedShapeRendered(false);
        } else if (selectId === "scenarioSelect") {
            setTripsTableLoading(true);
            const scenarioOptionValues = JSON.parse(value);
            //alert('manual set');
            setScenarioId(scenarioOptionValues.id);
        }
    };
    const getLatestImportStatus = async id => {
        const latestImportInfo = await Api.get(
            `${configSettings.ImportLatestUrl}/${id}`
        );

        if (IS_MOUNTED) {
            if (latestImportInfo) {
                setLatestImport(latestImportInfo);
            }
        }
    };

    const getDepotInfo = async depotId => {
        const depotInfo = await Api.get(
            `${configSettings.DepotsByIdUrl}/${depotId}`
        );

        if (IS_MOUNTED) {
            if (depotInfo && Object.entries(depotInfo).length !== 0) {
                setDepotSelected(depotId);
                setGlobalSelectedDepotId(depotId);
                setGlobalSelectedDepot(depotInfo);
                //getTrips(depotId, null); // change?
                addDepotMarker(depotInfo.Longitude, depotInfo.Latitude, depotInfo.Id);
            }
        }
    };

    // save 1 - create scenario
    const trapSaveVarDepot = (depotId, bronScenarioId, doelScenarioId, naam) => {
        var request = { depotid: depotId, bronscenarioid: bronScenarioId, scenarioid: doelScenarioId, naam: naam, omschrijving: 'desc 1' }

        fetch(`${configSettings.TrapSaveScenarioUrl}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ validationMsg: 'Fout bij opslaan. - ' + response.statusText });
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then((httpObj) => {
                console.log(httpObj);
                var result = httpObj.Value;
                if (result.Code === 0) {
                    setScenarioName(''); // empty scenario name in input
                    setScenarioListForDepot(false);
                    console.log('Gegevens opgeslagen. to refresh ddl and rit...');
                    // setValidationMessage('Scenario aangemaakt: ' + result.X);
                    // set dropdown value
                    //alert('setScenarioId(result.X);');
                    setScenarioId(result.X);
                }
                else {
                    setValidationMessage(result.Text);
                }
            })
            .catch((err) => {
                setValidationMessage('Fout bij opslaan. - ' + err.toString());
            });
    };

    // save 2 - update scenario status
    const trapUpdateScenario = (scenarioId, statusId) => {

        var request = { scenarioid: scenarioId, statusid: statusId }

        fetch(`${configSettings.TrapUpdateScenarioUrl}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ validationMsg: 'Fout bij opslaan scenario status. - ' + response.statusText });
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then((httpObj) => {
                console.log(httpObj);
                var result = httpObj.Value;
                if (result.Code === 0) {
                    console.log('Scenario geupdate');
                    setTrapStapStatusCurrent(statusId);
                    setValidationMessage('Scenario geupdate: ' + statusId);
                }
                else {
                    setValidationMessage(result.Text);
                }
            })
            .catch((err) => {
                setValidationMessage('Fout bij opslaan. - ' + err.toString());
            });
    };

    const refreshList = (depotId, scenarioId, sortMethod) => {
        getTripsScenario(depotId, scenarioId === 0 ? -1 : scenarioId, sortMethod);
    }

    const biccFileProcessedForDepot = async (id, scenarioId, sortMethod) => {
        getTripsScenario(id, scenarioId, sortMethod);
        if (trapStapStatusCurrent !== trapScenarioStatusses[3]) {
            //alert('trap status change: scenarioId: ' + scenarioId + ' - ' + trapStapStatusCurrent + ' -> ' + trapScenarioStatusses[3]);
            trapUpdateScenario(scenarioId, trapScenarioStatusses[3]); // save status stap 1
        } else {
            console.log('no trap status change');
            //alert('no trap status change');
        }
    }

    const getTripsScenario = async (id, scenarioId, sortMethod) => {
        let _trips;
        let context = 1;
        _trips = await Api.get(`${configSettings.TripsUrl}/${id}/${scenarioId}/${context}/${sortMethod}`);
        if (_trips.length) {
            saveUnplannedLayerId(_trips[0].Id);
        }

        if (IS_MOUNTED) {
            if (_trips.length) {
                setUnplanned(_trips[0].Id);
                setTrips([]);
                setTrips(_trips);
                setTripsTableLoading(false);
            } else {
                setTrips([]);
            }
        }
    };

    const setUnplanned = id => {
        setUnplannedLayerId(id);
        _unplannedLayerId = id;
    };

    const getPostcodes = async id => {
        const postcodes = await Api.get(`${configSettings.PostcodesUrl}/${id}`);

        if (IS_MOUNTED) {
            if (postcodes) {
                setPostcodes(postcodes);
            }
        }
    };

    function postCodesSelected(postcodeIds) {
        setSelectedPostcodes(postcodeIds);
    }

    const showTripDetails = async (e, id) => {
        const tripDetails = await Api.get(`${configSettings.TripDetailsUrl}/${id}`);

        if (IS_MOUNTED) {
            if (tripDetails) {
                setTripDetails(tripDetails);
            }
            alert('setTripModalVisible(true);');
            //setTripModalVisible(true);
        }
    };

    // Handle map and postcode table after postcodes transfer by dragging
    const postCodeTransfer = async (fromTripId, toTripId, postCodeIds) => {
        console.log(toTripId);
        if (fromTripId === _unplannedLayerId) {
            // transfering from unplanning layer

            const _postcodesToReplan = [...postCodeIds];
            replanPostcodesAfterDrag(_postcodesToReplan);
        } else if (toTripId === _unplannedLayerId) {
            // transfering to unplanning layer

            const _postcodesToUnplan = [...postCodeIds];
            unplanPostcodesAfterDrag(_postcodesToUnplan);
        } else {
            // handle from planned to planned condition
        }
    };

    const getPostcodeShapes = async id => {
        if (id !== _unplannedLayerId) {
            const _postcodeShapesData = await Api.get(
                `${configSettings.PostcodeShapesUrl}/${id}`
            );
            if (IS_MOUNTED) {
                renderPlannedPostcodeShape(_postcodeShapesData);
            }
        }

        if (!UnplannedShapeRendered) {
            const unplannedShapeData = await Api.get(
                `${configSettings.PostcodeShapesUrl}/${_unplannedLayerId}`
            );
            if (IS_MOUNTED) {
                if (unplannedShapeData.length === 0) {
                    hideMapLoader();
                }

                renderUnplannedPostcodeShape(unplannedShapeData);
                setUnplannedShapeRendered(true);
            }
        }
        hideMapLoader();
    };

    const getAllTripShapes = async () => {
        let alltripShapesData;
        let context = 1;
        showMapLoader();

        alltripShapesData = await Api.get(
            `${configSettings.AllTripShapesUrl}/${depotSelected}/${scenarioId}/${context}`
        );

        if (IS_MOUNTED) {
            if (alltripShapesData) {
                handleSelectAllTripShapes(alltripShapesData);
            } else {
                hideMapLoader();
            }
        }
    };

    const createTripDetails = async data => {
        setShowLoadingModal(true);
        setLoadingText("Loading...");

        let responseOk;

        fetch(`${configSettings.TripCreateUrl}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    responseOk = true;
                }
                return response.text();
            })
            .then(text => {
                if (responseOk) {
                    setShowLoadingModal(false);

                    setSuccessSnackbarMsg("Rit aangemaakt.");
                    setSnackbarBGcolor("green");

                    setSuccessSnackbarOpen(true);
                    getTripsScenario(depotSelected, scenarioId, 'subco_last');
                    //getTrips(depotSelected);
                    setCreateTripModalVisible(false);
                } else {
                    setErrorSnackbarMsg("Error creating Rit");
                    setErrorSnackbarOpen(true);
                    setShowLoadingModal(false);
                }
            })
            .catch(function (error) {
                console.log("Error creating Trip: ", error);
                setErrorSnackbarMsg("Error creating Rit");
                setErrorSnackbarOpen(true);
                setShowLoadingModal(false);
            });
    };

    const updateTripDetails = async data => {
        setShowLoadingModal(true);
        setLoadingText("Loading...");

        let responseOk;

        fetch(`${configSettings.TripDetailsUpdateUrl}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    responseOk = true;
                }
                return response.text();
            })
            .then(text => {
                if (responseOk) {
                    setShowLoadingModal(false);
                    setSuccessSnackbarMsg("Rit bijgewerkt");
                    setSnackbarBGcolor("green");
                    setSuccessSnackbarOpen(true);
                    console.log(trips);
                    const _trips = [...trips];
                    _trips.forEach(trip => {
                        if (trip.Id === data.ritId) {
                            trip.Personeelstype = data.personeelsType;
                            trip.Ritcode = data.ritCode;
                            trip.Ritnaam = data.ritNaam;
                            trip.Uitvoerder = data.uitvoerder;
                            trip.VolumeRit = data.volumeRit;
                        }
                    });
                    console.log(_trips);
                    setTrips(_trips); // 31-10-2024
                    getTripsScenario(depotSelected, scenarioId, 'subco_last');
                    //getTrips(depotSelected);
                } else {
                    setErrorSnackbarMsg("Error updating Rit");
                    setErrorSnackbarOpen(true);
                    setShowLoadingModal(false);
                }
            })
            .catch(function (error) {
                console.log("Error updating Trip Details: ", error);
                setErrorSnackbarMsg("Error updating Rit");
                setErrorSnackbarOpen(true);
                setShowLoadingModal(false);
            });
    };

    // exportToTrap
    const exportToTrap = (depotId, scenarioId) => {
        setShowLoadingModal(true);

        var request = { depotid: depotId, scenarioid: scenarioId }

        fetch(`${configSettings.ExportTrapUrl}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ validationMsg: 'Fout bij opslaan. - ' + response.statusText });
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then((httpObj) => {
                console.log(httpObj);
                var ldpModel = httpObj.Value;
                setValidationMessage(ldpModel.message);
            })
            .catch((err) => {
                setValidationMessage('Fout bij opslaan. - ' + err.toString());
            });

            setShowLoadingModal(false);

    };

    const csvExport = key => {
        setShowLoadingModal(true);
        setLoadingText("Loading...");

        let url;
        let depotName;
        let filename;

        allDepotsList.forEach(depot => {
            if (depot.Id === depotSelected) {
                depotName = depot.Naam;
            }
        });

        const params = `/${depotSelected}/${scenarioId}/${depotName}`;

        switch (key) {
            case "save":
                url = `${configSettings.ExportSaveUrl}`;
                break;
            case "trap":
                url = `${configSettings.ExportTrapUrl}`;
                break;
            case "mutation":
                url = `${configSettings.TrapExportMutationUrl}`;
                break;
            case "overview":
                url = `${configSettings.ExportOverviewUrl}`;
                break;
            default:
                break;
        }

        fetch(url + params)
            .then(response => {
                response.headers.forEach(function (val, key) {
                    if (val && val.indexOf("attachment") !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(val);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, "");
                        }
                    }
                });
                return response.blob();
            })
            .then(blob => {
                if (filename) {
                    setShowLoadingModal(false);
                    saveAs(blob, filename);
                    //alert('export done - update trap status - current status: ' + trapStapStatusCurrent);
                    if (trapStapStatusCurrent === 3 && key === 'trap') {
                        trapUpdateScenario(scenarioId, trapScenarioStatusses[4]);
                    }
                } else {
                    setShowLoadingModal(false);

                    setErrorSnackbarOpen(true);
                }
            })
            .catch(function (error) {
                setShowLoadingModal(false);

                // console.log("Error getting CSV: ", error.message);
                setErrorSnackbarMsg("Error getting CSV: ", error.message);
                setErrorSnackbarOpen(true);
            });
    };

    const setAction = action => {
        setMapAction(action);
    };

    // correcting reselect of tripshape when postcodeShape is active in the same region
    const updatePostcodeBlockedTripshape = tripShapeId => {
        if (tripShapeId) {
            getCachedTripShapesData().forEach(tripShape => {
                if (tripShape.RitId === tripShapeId) {
                    console.log(tripShape);
                    setPostCodeBlockedTripShape(tripShape);
                }
            });
        }
    };

    const onImportFailed = () => {
        setErrorSnackbarOpen(true);
        setErrorSnackbarMsg("Import Failed");
    };

    const onImportSuccess = () => {
        setLoadingText("Import successful! Updating depot...");
        setShowLoadingModal(true);
        if (mapStatus === 1) {
            resetMap();
        }
        setPostcodes([]);
        setTimeout(() => {
            setShowLoadingModal(false);
        }, 1000);
    };

    const toggleMap = () => {
        if (mapStatus === 0) {
            setMapStatus(1);
        } else {
            setMapStatus(0);
        }
    }

    return (
        <div id="infoContent">

            {depotSelected ? (
                <>
                    <label className="trapStapTitle">{trapStapCurrentDescription}</label>
                    {/*<label className="trapStapTitle">{trapStapCurrentDescription} - {scenarioId} - {scenarioId > 0 ? "true" : "false"}</label>*/}
                    {/* <Icons.Upload onClick={() => openImportModal("normalImport")} /> */}

                    <div className="option flex">
                        <div className="trapStapPanelWidth">
                            <table>
                                <tbody>
                                    <tr><td className="trapStapName">Stap 1</td><td>Scenario aanmaken</td></tr>
                                    <tr><td className="trapStapName">Stap 2</td><td>Inputbestand importeren</td></tr>
                                    <tr><td className="trapStapName">Stap 3</td><td>Parameters invullen</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table>
                                <tbody>
                                    <tr><td className="trapStapName">Stap 4</td><td>Wacht op resultaat</td></tr>
                                    <tr><td className="trapStapName">Stap 5</td><td>Controleren</td></tr>
                                    <tr><td className="trapStapName">Stap 6</td><td>Opslaan</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div></>
            ) : null}
                    <Button
                        disabled={false}
                        size="small"
                        className="normalcase"
                        variant="outlined"
                        onClick={toggleMap}
                    >
                        <Icons.Marker />
                        {mapStatus === 1 ? "Variabelen tonen" : "Kaart tonen"}
                    </Button>
            <div className="bold underlined mt1">Filters</div>
            <div className="flex mb1">
                <div className="options">
                    <div className="option flex">
                        <label>Depot:</label>
                        {allDepotsList.length ? (
                            <div>
                                {depots.length === 1 ? (
                                    <span className="ml05">{allDepotsList[0].Naam}</span>
                                ) : (
                                    <Select
                                        native
                                        autoFocus
                                        defaultValue="none"
                                        onChange={e => handleFilterChange(e)}
                                        disabled={depots ? false : true}
                                        id="depotSelect"
                                    >
                                        <option value="none" disabled>
                                            Selecteer een depot
                                        </option>
                                        {depots}
                                    </Select>
                                )}
                            </div>
                        ) : null}
                    </div>

                    <div className="topMost option flex">
                        <div className="trapPanelWidth"><label>Scenario:</label></div>
                        <div>
                            <TrapScenario
                                createScenario={createScenario}
                                handleFilterChange={handleFilterChange}
                                scenarios={scenarios}
                                validationMessage={validationMessage}
                                scenarioName={scenarioName}
                                scenarioValue={scenarioValue}
                            />
                        </div>
                    </div>

                </div>


                {depotSelected ? (
                    <div className="filemessage-actions">
                        <div id="fileMessage" className="mt05">
                            <div className="flex">
                                <p className="mt0 nowrap filemessage-left">
                                    Laatst ingelezen bestand:{" "}
                                </p>
                                {latestImport ? (
                                    <p
                                        className="mt0 ml05 ellipsis filemessage-right"
                                        id="fileName"
                                        title={latestImport.Filename}
                                    >
                                        {latestImport.Filename}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div id="latestImportMessage" className="mt05">
                            <div className="flex">
                                <p className="mt0 nowrap filemessage-left">Status: </p>
                                {latestImport ? (
                                    <p
                                        className="mt0 ml05 ellipsis filemessage-right"
                                        id="latestImport"
                                        title={latestImport.Description}
                                    >
                                        {functions.getImportStatusText(latestImport.Status)}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="actions flex">
                            <Button
                                disabled={scenarioId <= 0 ? true : false}
                                size="small"
                                className="normalcase"
                                variant="outlined"
                                onClick={() => openImportModal("normalImport")}
                            >
                                <Icons.Upload />
                                Inlezen
                            </Button>
                            {1 > 0 ? // manual download until api is ready
                                (<Button
                                    disabled={scenarioId <= 0 ? true : false}
                                    size="small"
                                    className="normalcase"
                                    variant="outlined"
                                    onClick={() => csvExport("save")}
                                >
                                    <Icons.Download />
                                    Opslaan
                                </Button>) : null}
                            <Button
                                disabled={scenarioId <= 0 ? true : false}
                                size="small"
                                className="normalcase"
                                variant="outlined"
                                onClick={() => exportToTrap(depotIdCurrent, scenarioId)} 
                            >
                                <Icons.Download />
                                Exporteren naar TRAP
                            </Button>
                            <Button
                                size="small"
                                className="normalcase"
                                variant="outlined"
                                onClick={() => csvExport("mutation")}
                            >
                                <Icons.File />
                                Mutaties
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
            {trapStapStatusCurrent > 0 ? (
                <div className={classes.root}>
                    <Tabs
                        id="infoTabs"
                        value={TabPanelValue}
                        onChange={handleTabChange}
                        classes={{
                            indicator: classes.indicator
                        }}
                        textColor="primary"
                        aria-label="info tabs"
                    >
                        <Tab label="Ritten" {...a11yProps(0)} />
                        <Tab label="Import Queue" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel className="tabpanel" value={TabPanelValue} index={0}>
                        {scenarioId > 0 ? (
                            <div className="flex">
                                <div className="route-container">
                                    <div className="flex underlined route-container-header">
                                        <p>Ritten</p>
                                        <Button
                                            size="small"
                                            className="normalcase"
                                            variant="outlined"
                                            onClick={() => setCreateTripModalVisible(true)}
                                        >
                                            <Icons.Add />
                                            Rit toevoegen
                                        </Button>
                                    </div>
                                    <div className="compact-table selecting-table hide-pagination">
                                        <TrapRitTable
                                            ref={TripsTableComponent => {
                                                window.TripsTableComponent = TripsTableComponent;
                                            }}
                                            data={trips}
                                            loading={tripsTableLoading}
                                            validationMsg='parent'
                                            scenarioId={scenarioId}
                                            mapStatus={mapStatus}
                                            getPostcodes={getPostcodes}
                                            getPostcodeShapes={getPostcodeShapes}
                                            showTripDetails={showTripDetails}
                                            // sendSelectedForTripshapes={selectedForTripshapes}
                                            updatePostcodeBlockedTripshape={
                                                updatePostcodeBlockedTripshape
                                            }
                                            getAllTripShapes={getAllTripShapes}
                                        />
                                    </div>
                                </div>
                                {postcodes ? (
                                    <div className="postcodes-container">
                                        <div className="flex underlined postcodes-container-header postcodeMarginBelow">
                                            <p>Postcodes</p>
                                        </div>
                                        <div className="compact-table selecting-table hide-pagination">
                                            <TrapPostcodesTable
                                                ref={PostcodesTableComponent => {
                                                    window.PostcodesTableComponent = PostcodesTableComponent;
                                                }}
                                                data={postcodes}
                                                setAction={setAction}
                                                postCodeTransfer={postCodeTransfer}
                                                postCodesSelected={postCodesSelected}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </TabPanel>
                    <TabPanel className="tabpanel" value={TabPanelValue} index={1}>
                        <ImportQueue
                            depotId={depotSelected}
                            refreshImportQueue={refreshImportQueue}
                            updateLatestImport={latestImport => setLatestImport(latestImport)}
                            refreshDepot={() =>
                                biccFileProcessedForDepot(depotSelected, scenarioId, 'subco_last')
                            }
                            clearPostcodes={() => setPostcodes([])}
                            onImportFailed={() => onImportFailed()}
                            onImportSuccess={() => onImportSuccess()}
                            onQueueRefreshed={() => setRefreshImportQueue(false)}
                        />
                    </TabPanel>
                </div>
            ) : null}
            <Modal
                aria-labelledby="trip-details-modal-title"
                aria-describedby="trip-details-modal-description"
                open={tripModalVisible}
                onClose={() => setTripModalVisible(false)}
            >
                <div style={getModalStyle()} className="SimpleModal-paper-1">
                    <TripDetailsModal
                        rData={tripDetails}
                        subContractors={null}
                        closeModal={() => setTripModalVisible(false)}
                        submit={data => updateTripDetails(data)}
                    />
                </div>
            </Modal>
            <Modal
                aria-labelledby="create-trip-modal-title"
                aria-describedby="create-trip-modal-description"
                open={createTripModalVisible}
            // onClose={() => setCreateTripModalVisible(false)}
            >
                <div style={getModalStyle()}>
                    <CreateTripModal
                        subContractors={null}
                        depotSelected={depotSelected}
                        scenarioId={scenarioId}
                        closeModal={() => setCreateTripModalVisible(false)}
                        submit={data => createTripDetails(data)}
                    />
                </div>
            </Modal>
            <Modal
                aria-labelledby="import-modal"
                aria-describedby="import-file-modal"
                open={importDialogOpen}
                onClose={() => setImportDialogOpen(false)}
            >
                <ImportFileDropzone
                    onClose={() => setImportDialogOpen(false)}
                    savedFiles={file => savedImportFile(file)}
                    acceptedFiles={acceptedFiles}
                    uploadExtension={uploadExtension}
                />
            </Modal>
            <LoadingModal
                showLoadingModal={showLoadingModal}
                loadingText={loadingText}
            />
            <ErrorSnackbar
                open={errorSnackbarOpen}
                errorMessage={errorSnackbarMsg}
                closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
            />
            <SuccessSnackbar
                open={successSnackbarOpen}
                successMessage={successSnackbarMsg}
                BGcolor={snackbarBGcolor}
                closeSuccessSnackbar={() => setSuccessSnackbarOpen(false)}
            />
            {/* {ShowCreateRangesModal && (
        <CreateRangesModal
          ShowCreateRangesModal={ShowCreateRangesModal}
          downloadRanges={downloadRanges}
        />
      )} */}
        </div>
    );
};

export default TrapInfoContent;
