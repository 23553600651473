import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";

import "../../styles/app.scss";

export default function LoadingModal(props) {
  return (
    <Modal
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal"
      open={props.showLoadingModal}
    >
      <div tabIndex={-1} className="modal-outer">
        <Paper className="LoadingModal .fade-in-slow " id="loadingModal">
          <div className="flex">
            <CircularProgress size={30} />
            <p className="loading-text">
              {props.loadingText ? props.loadingText : "Loading..."}
            </p>
          </div>
        </Paper>
      </div>
    </Modal>
  );
}
