import React, { Component } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";

class ImportFileDropzone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            file: [],
            uploadActive: false
        };
    }
    handleChange(files) {
        if (files && files.length) {

            if (files[0].name.slice(-1 * this.props.uploadExtension.length) === this.props.uploadExtension) {
                this.setState({
                    files: files,
                    file: files[0],
                    uploadActive: true
                });
            }
        }
    }
    handleSave() {
        if (this.state.file) {
            this.props.savedFiles(this.state.file);
        }
    }
    handleClose() {
        this.props.onClose();
    }
    render() {
        return (
            <div className="import-modal-outer" tabIndex={-1}>
                <div className="import-modal-inner">
                    <DropzoneArea
                        dropzoneClass={"import-dropzone"}
                        dropzoneParagraphClass={"import-dropzone-paragraph"}
                        onChange={this.handleChange.bind(this)}
                        dropzoneText={
                            "Klik om een bestand te kiezen of drag en drop een bestand."
                        }
                        filesLimit={1}
                        //acceptedFiles={[".csv"]}
                        acceptedFiles={this.props.acceptedFiles}
                        maxFileSize={100000000}
                        showFileNamesInPreview={true}
                    />
                    <div className="mt1 import-modal-controls">
                        <Button
                            className="normalcase"
                            variant="outlined"
                            onClick={this.handleSave.bind(this)}
                            disabled={this.state.uploadActive ? false : true}
                        >
                            Upload
                        </Button>
                        <Button
                            className="normalcase"
                            variant="outlined"
                            onClick={this.handleClose.bind(this)}
                        >
                            Sluiten
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportFileDropzone;
