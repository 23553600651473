import React from "react";
import Icons from "../Icons";
import ReactTable from "react-table";
import "react-table/react-table.css";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import {
  canSelectNewRoute,
  clearMultiLayerShapes,
  getPostcodeShapes,
  handleTripshapeCheckbox,
  removeRenderedTripShape,
  showMapLoader,
  updateSelectedTripShapeIds,
} from "../Map/AdminMapController";

var _selectedAll;

class TripTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [],
      loading: true,
      isAllRowsSelected: false,
      pageSize: this.props.pageSize ? this.props.pageSize : 25,
      pageSizeOptions: this.props.pageSizeOptions
        ? this.props.pageSizeOptions
        : [5, 10, 25, 50, 100, 200],
      paginationEnabled: true,
      selected: null,
      selectedRows: [],
      selectedForTripShapes: [],
      blockedTripshapeIdForPostcode: null,
    };
  }

  componentDidMount() {
    //
    this.makeColumns();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        // selected: [],
        blockedTripshapeIdForPostcode: null,
      });

      if (this.props.data === null) { // was this.props.data === []
        this.deselectCheckboxes();
        // console.log("new depot");

        this.setState({ loading: true });
      } else {
        this.setState({ loading: true });
        this.renderTable(this.props.data);
      }
    }
    if (prevProps.loading !== this.props.loading) {
      if (_selectedAll) {
        // clear select all checkboxes
        this.onSelectAllClick();
      }
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  makeColumns = () => {
    const columns = [
      {
        columns: [
          {
            Header: _selectedAll ? (
              <VisibilityOnIcon
                className="fade-in-fast visibility-icon visibility-on"
                onClick={(e) => {
                  this.onSelectAllClick(e);
                }}
              />
            ) : (
              <VisibilityOffIcon
                className="fade-in-fast visibility-icon visibility-off"
                onClick={(e) => {
                  this.onSelectAllClick(e);
                }}
              />
            ),
            Cell: (row) =>
              row.original.Route.indexOf("000 Ongepland") > -1 ? (
                ""
              ) : (
                <div onClick={this.stopPropagation}>
                  {row.original.isChecked ? (
                    <VisibilityOnIcon
                      className="fade-in-fast visibility-icon visibility-on"
                      onClick={(e) => {
                        this.selectRowCheckbox(e, row);
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="fade-in-fast visibility-icon visibility-off"
                      onClick={(e) => {
                        this.selectRowCheckbox(e, row);
                      }}
                    />
                  )}
                </div>
              ),
            width: 40,
            sortable: false,
          },
          {
            Header: "Rit",
            id: "Route",
            accessor: "Route",
            width: 145,
            className: "column-align-left",
          },
          {
            Header: "m3",
            Cell: (row) =>
              row.original.Volume_m3,
            width: 40,
          },
          {
            Header: "Stops",
            id: "Stops",
            accessor: (d) => d.Stops,
            width: 50,
          },
          {
            Header: "Stuks",
            id: "Parcels",
            accessor: (d) => d.Parcels,
            width: 50,
          },
          {
            Header: "TI",
            id: "Norm",
            accessor: (d) => d.Norm,
            width: 50,
          },
          {
            Header: "Real.",
            id: "Real",
            accessor: (d) => d.Real,
            width: 50,
          },
          {
            Header: "",
            id: "ViewMore",

            Cell: (row) => (
              <div
                className="route-details"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.showTripDetails(e, row.original.Id);
                }}
              >
                <Icons.ViewMore className="p2" />
              </div>
            ),
            width: 30,
            className: "column-align-left",
          },
          {
            Header: "Id",
            id: "Id",
            accessor: (d) => d.Id,
            width: 50,
            show: false,
          },
          {
            Header: "isSelected",
            id: "isSelected",
            accessor: (d) => d.isSelected,
            show: false,
          },
          {
            Header: "isChecked",
            id: "isChecked",
            accessor: (d) => d.isChecked,
            show: false,
          },
        ],
      },
    ];

    this.setState({ columns });
  };

  renderTable = (tripData) => {
    const dataObject = [];

    this.setState({ loading: true });

    tripData.forEach((entry, index) => {
      dataObject.push({
        Id: entry.Id,
        Route: entry.Ritcode + " " + entry.Ritnaam,
        Volume_m3: entry.Volume_m3,
        Stops: entry.Stops,
        Parcels: entry.Stuks,
        Norm: entry.Norm,
        Real: entry.Realisatie,
        isSelected: entry.isSelected ? true : false,
        isChecked: entry.isChecked ? true : false,
      });
    });

    this.setState({
      data: dataObject,
    });

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  clearSelected = () => {
    this.setState({ selected: null });
  };

  // Called from Admin Map Controller in order
  // to update trip Details after a postcode transfer
  updateModifiedTripDetails = (
    modifiedUnplannedTripDetails,
    modifiedTripDetails
  ) => {
    this.setState({ loading: true });

    let tripData = [...this.state.data];

    // Update unplanned entry
    tripData[0].Stops = modifiedUnplannedTripDetails.Stops;
    tripData[0].Parcels = modifiedUnplannedTripDetails.Stuks;
    tripData[0].Norm = modifiedUnplannedTripDetails.Norm;
    tripData[0].Real = modifiedUnplannedTripDetails.Realisatie;

    tripData.forEach((trip, index) => {
      if (trip.Id === modifiedTripDetails.Id) {
        // Update modified trip entry
        tripData[index].Stops = modifiedTripDetails.Stops;
        tripData[index].Parcels = modifiedTripDetails.Stuks;
        tripData[index].Norm = modifiedTripDetails.Norm;
        tripData[index].Real = modifiedTripDetails.Realisatie;
      }
    });

    this.setState({ data: tripData, loading: false });
  };

  deselectCheckboxes = () => {
    const _data = this.state.data;
    let selectedForTripShapes = this.state.selectedForTripShapes;
    let idChanged;
    let action;

    _data.forEach((entry) => {
      entry.isChecked = false;
      selectedForTripShapes = [];
      idChanged = "deselectedAll";
      action = "deselectedAll";
    });

    this.props.sendSelectedForTripshapes(
      selectedForTripShapes,
      idChanged,
      action
    );
    this.setState({ selectedForTripShapes });
  };

  // select to show region on map from checkbox (trip shape on map)
  selectRowCheckbox = (e, row, rowClicked = false) => {
    const _data = this.state.data;
    let selectedForTripShapes = this.state.selectedForTripShapes;
    // let idClicked;

    // let action;
    // const tripShapesToAdd = [];

    if (rowClicked) {
      // Clicked row not checkbox (eye)
      _data.forEach((entry, index) => {
        if (row.index === index) {
          // idClicked = entry.Id;
          // the clicked index
          if (!row.original.isSelected) {
            let tripShapeIdExists = false;
            selectedForTripShapes.forEach((tripShapeId) => {
              if (tripShapeId === entry.Id) {
                tripShapeIdExists = true;
              }
            });
            if (!tripShapeIdExists) {
              selectedForTripShapes.push(entry.Id);
            }

            this.setBlockedTripshapeForPostcode(entry.Id);

            entry.isChecked = true;
            entry.isSelected = true;
          }
        } else {
          entry.isSelected = false;
        }
      });
    } else {
      // Clicked checkbox (eye) not row
      _data.forEach((entry, index) => {
        if (row.index === index) {
          // idClicked = entry.Id;
          // the clicked index
          if (row.original.isChecked && row.original.isSelected) {
            this.setBlockedTripshapeForPostcode({});
          }
          if (row.original.isChecked) {
            // action = "remove";
            entry.isChecked = false;

            selectedForTripShapes.forEach((tripShapeId, index) => {
              if (tripShapeId === entry.Id) {
                selectedForTripShapes.splice(index, 1);
                removeRenderedTripShape(entry.Id);
              }
            });
          } else {
            let tripShapeIdExists = false;
            selectedForTripShapes.forEach((tripShapeId) => {
              if (tripShapeId === entry.Id) {
                tripShapeIdExists = true;
              }
            });
            if (!tripShapeIdExists) {
              selectedForTripShapes.push(entry.Id);
              handleTripshapeCheckbox(entry.Id);
            }
            // action = "add";
            entry.isChecked = true;
          }
        }
      });
    }

    // _data.forEach(entry => {
    //   if (entry.isChecked && !entry.isSelected) {
    //     tripShapesToAdd.push(entry.Id);
    //   }
    // });

    // this.props.sendSelectedForTripshapes(
    //   selectedForTripShapes,
    //   idClicked,
    //   action
    // );

    updateSelectedTripShapeIds(selectedForTripShapes);

    this.setState({ data: _data, selectedForTripShapes });
  };

  setBlockedTripshapeForPostcode = (tripShapeId) => {
    // this.props.updatePostcodeBlockedTripshape(tripShapeId);
    this.setState({
      blockedTripshapeIdForPostcode: tripShapeId,
    });
  };

  // select to show all regions on map from checkbox
  onSelectAllClick = () => {
    let selectedForTripShapes = this.state.selectedForTripShapes;

    this.setState({ isAllRowsSelected: !this.state.isAllRowsSelected });

    _selectedAll = !_selectedAll;
    this.makeColumns();

    const _data = this.state.data;

    _data.forEach((entry) => {
      if (this.state.isAllRowsSelected) {
        entry.isChecked = false;
        selectedForTripShapes = [];
      } else {
        entry.isChecked = true;
        if (entry.Id !== this.state.data[0].Id) {
          selectedForTripShapes.push(entry.Id);
        }
      }
    });

    // this.props.sendSelectedForTripshapes(
    //   selectedForTripShapes,
    //   idChanged,
    //   action
    // );

    updateSelectedTripShapeIds(selectedForTripShapes);
    if (!_selectedAll) {
      clearMultiLayerShapes("TripShapes");
    } else {
      this.props.getAllTripShapes();
    }
    this.setState({ selectedForTripShapes });
  };

  // select row (click row not checkbox) to show postcodes shape on map
  selectRowForPostcodes = (e, row) => {
    if (canSelectNewRoute()) {
      if (row.original.Id !== this.state.blockedTripshapeIdForPostcode) {
        showMapLoader();
        this.props.getPostcodes(row.original.Id);

        let unplannedSelected;
        this.state.data[0].Id === row.original.Id
          ? (unplannedSelected = true)
          : (unplannedSelected = false);
        getPostcodeShapes(
          row.original.Id,
          unplannedSelected,
          this.state.data[0].Id
        );

        this.setState({
          selected: row.index,
        });

        this.selectRowCheckbox(e, row, true);
      }
    }
  };

  getPostcodes = (Id) => {
    this.props.getPostcodes(Id);
  };

  allowDrop = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <ReactTable
        data={this.state.data}
        columns={this.state.columns}
        loading={this.state.loading}
        loadingText={
          <div className="grid-custom-loader">
            <Icons.Loading />
            <b>Loading</b>
          </div>
        }
        pageSize={this.props.data.length}
        className="-striped -highlight"
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => this.selectRowForPostcodes(e, rowInfo),
              id: rowInfo.original.Id,
              style: {
                background:
                  rowInfo.index === this.state.selected ? "#2196f3" : null,
                color: rowInfo.index === this.state.selected ? "white" : null,
              },
              className:
                rowInfo.index === this.state.selected
                  ? "selected trip-row"
                  : "trip-row",

              onDragOver: (e) => this.allowDrop(e),
            };
          } else {
            return {};
          }
        }}
      />
    );
  }
}
export default TripTable;
