import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import WarningIcon from "@material-ui/icons/Warning";

export default function ConfirmationDialog(props) {
  useEffect(() => {
    // console.log(props);
  }, []);
  return (
    <div className="text-center">
      <Dialog
        id="confirmationDialog"
        open={props.open}
        onClose={() => props.cancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.showWarningIcon ? <WarningIcon /> : null}
          {props.dialogTitle ? props.dialogTitle : ""}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="alert-dialog-description"
            className="confirmation-dialog-text"
          >
            {props.dialogText}
          </DialogContentText>
          {props.dialogTextSecondary ? (
            <DialogContentText
              id="alert-dialog-description-2"
              className="confirmation-dialog-text"
            >
              {props.dialogTextSecondary}
            </DialogContentText>
          ) : null}
          {props.dialogTextThird ? (
            <DialogContentText
              id="alert-dialog-description-3"
              className="confirmation-dialog-text"
            >
              {props.dialogTextThird}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.confirm()}
            variant="contained"
            style={{ backgroundColor: "#ec8e00", color: "white" }}
          >
            {props.confirmationText}
          </Button>
          <Button onClick={() => props.cancel()} variant="outlined" autoFocus>
            {props.cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
