import React, { useEffect, useState } from "react";
import Api from "../helpers/http";
import configSettings from "../settings";
import Header from "../components/Header/Header";
import UsersTable from "../components/Tables/UsersTable";
import useAuth from "../hooks/useAuth";

import "../styles/app.scss";

function UserManagement() {
  const { currentUser } = useAuth();

  const [Depots, setDepots] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [Users, setUsers] = useState([]);
  const [_currentUser, _setCurrentUser] = useState({});

  useEffect(() => {
    getUsers();
    getUserRoles();
    getDepots();
  }, []);

  useEffect(() => {
    _setCurrentUser(currentUser);
  }, [currentUser]);

  const getDepots = async () => {
    const _Depots = await Api.get(`${configSettings.DepotsApiUrl}/false`);
    setDepots(_Depots);
  };

  const getUsers = async () => {
    const _Users = await Api.get(`${configSettings.GetAllUsersUrl}`);
    setUsers(_Users);
  };

  const getUserRoles = async () => {
    const _UserRoles = await Api.get(`${configSettings.GetUserRolesUrl}`);
    setUserRoles(_UserRoles);
  };

  return (
    <div id="UserManagementScreen" className="screen">
      <Header />
      <div className="screen-content ml05">
        <h2 className="mb05">Gebruikersbeheer</h2>
        <UsersTable
          currentUser={_currentUser}
          depots={Depots}
          userRoles={userRoles}
          usersData={Users}
          refreshTable={() => getUsers()}
        />
      </div>
    </div>
  );
}
export default UserManagement;
