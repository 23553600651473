import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import FilterBar from "../components/Utils/FilterBar";
import SubcontractorsOverviewTable from "../components/Tables/SubcontractorsOverviewTable";

import configSettings from "../settings";
import Api from "../helpers/http";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../hooks/useAuth";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  editButton: {
    margin: theme.spacing(1),
    paddingLeft: "3rem",
    paddingRight: "3rem",
    textTransform: "none"
  },
  root: {
    flexGrow: 1
  },
  indicator: {
    backgroundColor: "rgba(255,255,255,0)"
  },
  icon: {
    fill: "#212121",
    top: "10px;"
  }
}));

let overviewPollingInterval;
let IS_MOUNTED;

const SubcontractorOverview = withRouter(({ history, ...props }) => {
  const classes = useStyles();

  const [Depots, setDepots] = useState([]);
  const [SelectedDepotId, setSelectedDepotId] = useState(0);
  // const [StatusFilter, setStatusFilter] = useState("Alles");
  const [Overview, setOverview] = useState([]);
  const [OverviewBySubcontractor, setOverviewBySubcontractor] = useState([]);
  const [OverviewLoading, setOverviewLoading] = useState(false);

  const { globalSelectedDepot, setGlobalSelectedDepot } = useAuth();

  useEffect(() => {
    getDepots();

    const headerTitle = document.getElementById("appHeaderTitle");
    headerTitle.innerHTML = "Tariefmodel";
    IS_MOUNTED = true;

    return () => {
      clearInterval(overviewPollingInterval);
      IS_MOUNTED = false;
    };
  }, []);

  useEffect(() => {
    clearInterval(overviewPollingInterval);

    if (Depots.length) {
      getOverview(SelectedDepotId);

      overviewPollingInterval = setInterval(() => {
        getOverview(SelectedDepotId);
      }, 10000);
    }
  }, [SelectedDepotId]);

  useEffect(() => {
    if (Overview.length) {
      mapOverviewBySubcontractor(Overview);
    } else {
      setOverviewBySubcontractor([]);
      setOverviewLoading(false);
    }
  }, [Overview]);

  const getDepots = async () => {
    const _Depots = await Api.get(`${configSettings.SubcoOverviewDepots}`);

    if (IS_MOUNTED) {
      if (_Depots.length) {
        setDepots(_Depots);
        if (_Depots.length === 1) {
          setSelectedDepotId(_Depots[0].Id);
          setGlobalSelectedDepot(_Depots[0]);
        } else if (globalSelectedDepot) {
          setSelectedDepotId(globalSelectedDepot.Id);
          // setGlobalSelectedDepotId(globalSelectedDepotId);
        }
      }
    }
  };

  const getOverview = async depotId => {
    setOverviewLoading(true);
    const _Overview = await Api.get(
      `${configSettings.SubcoOverviewByDepotId}/${depotId}`
    );
    if (IS_MOUNTED) {
      if (_Overview.length) {
        setOverview(_Overview);
      } else {
        clearInterval(overviewPollingInterval);
        setOverview([]);
      }
    }
  };

  const handleFilterChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "depotSelect") {
      setSelectedDepotId(value);
    }
    // if (name === "statusFilter") {
    //   setStatusFilter(value);
    // }

    const _depotSelected = Depots.find(depot => depot.Id === value);
    setGlobalSelectedDepot(_depotSelected);
  };

  const mapOverviewBySubcontractor = overviewArr => {
    let overviewBySub = [];

    overviewArr.forEach(o => {
      const subCoArr = o.Subcontractors;

      subCoArr.forEach(subco => {
        const subcoEntry = Object.assign({}, subco, {
          Id: o.Id, // TiRequestId
          SubCoId: subco.Id, // SubCoId
          RequestGuid: o.RequestGuid,
          CreatedDate: o.CreatedDate,
          StatusId: o.StatusId
        });

        overviewBySub.push(subcoEntry);
      });
    });

    setOverviewBySubcontractor(overviewBySub);
    setOverviewLoading(false);
  };

  const goToContractDetails = (e, row) => {
    history.push(
      `/contract-details/${row.original.Id}/${row.original.SubCoId}`
    );
  };

  return (
    <div>
      <Header />

      <div id="SubcoOverviewScreen" className="TariffModel">
        <FilterBar>
          <div className="flex-column">
            <label htmlFor="">Selecteer Depot</label>

            <Select
              onChange={handleFilterChange}
              name="depotSelect"
              value={SelectedDepotId}
              disabled={Depots ? false : true}
              inputProps={{
                classes: {
                  icon: classes.icon
                }
              }}
              autoFocus
            >
              {SelectedDepotId === null ? (
                <MenuItem key={"noDepotSelected"} value={"noDepotSelected"}>
                  <ListItemText primary="" />
                </MenuItem>
              ) : null}

              {Depots.map(depot => (
                <MenuItem key={depot.Id} value={depot.Id}>
                  <ListItemText primary={depot.Naam} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </FilterBar>

        <div className="screen-titles mt3 plr2">
          <div className="flex space-b">
            <div>
              <h2 className="table-title m0">
                Overzicht tariefindicatie aanvragen uitvoerders
              </h2>
            </div>
          </div>
        </div>

        <div className="plr2">
          <SubcontractorsOverviewTable
            data={OverviewBySubcontractor}
            loading={OverviewLoading}
            goToContractDetails={goToContractDetails}
          />
        </div>
      </div>
    </div>
  );
});
export default SubcontractorOverview;
