import React, { useState, useEffect } from "react";
import useMap from "../../hooks/useMap";

/*** map init ***/
const ol = window.ol;
const proj4 = window.proj4;

const GEODAN_MAPS_LAYERS = {
  BASE: {
    Topographical: "/topo/tms/1.0.0/topo/EPSG%3A28992/{z}/{x}/{-y}.png"
  }
};

// const GEODAN_MAPS_NLD_BASE_URL =
//   "https://services.geodan.nl/data/geodan/gws/nld";
// const GEODAN_BATCHROUTE = "https://services.geodan.nl/routing/batchroute";

// Initialize OpenLayers
proj4.defs(
  "EPSG:28992",
  "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs"
);
var EPSG_28992 = new ol.proj.Projection({
  code: "EPSG:28992",
  extent: [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999],
  units: "m"
});
ol.proj.addProjection(EPSG_28992);

let BaseVectorSource = new ol.source.XYZ({});
BaseVectorSource.set("name", "Default");

let TripsShapesVectorSource = new ol.source.Vector();
let TripsShapesVectorLayer = new ol.layer.Vector({
  source: TripsShapesVectorSource
});

var modalMap = new ol.Map({});
// ************** End Map Settings ************** //

const ModalMap = props => {
  const { cachedTripShapesData } = useMap();

  const [mapCoords, setMapCoords] = useState({});
  let [tilesStartedLoading, setTilesStartedLoading] = useState(0);
  let [tilesFinishedLoading, setTilesFinishedLoading] = useState(0);
  let [imageCreated, setImageCreated] = useState(false);

  useEffect(() => {
    setImageCreated(false);
    const modalMapImg = document.querySelector(".modal-map-image");
    if (modalMapImg && modalMapImg.parentNode) {
      modalMapImg.parentNode.removeChild(modalMapImg);
    }
    const modalElem = document.querySelector(".SimpleModal-paper-1");
    modalElem.style.overflowY = "hidden";
    initMap();
  }, []);

  useEffect(() => {
    //
    return () => {
      BaseVectorSource.unByKey("tileloadstart");
      BaseVectorSource.unByKey("tileloadend");
    };
  }, []);

  useEffect(() => {
    setMap();
  }, [props.coords]);

  const initMap = async () => {
    BaseVectorSource = new ol.source.XYZ({
      projection: EPSG_28992,
      url: "/Api/Export/GetTile/{z}/{x}/{-y}.png"
    });

    BaseVectorSource.on("tileloadstart", function(event) {
      setTilesStartedLoading(tilesStartedLoading++);
    });

    BaseVectorSource.on("tileloadend", function(event) {
      setTilesFinishedLoading(tilesFinishedLoading++);
      if (tilesFinishedLoading === tilesStartedLoading) {
        setTimeout(() => {
          props.mapRendered();
        }, 1000);
      }
    });

    modalMap = new ol.Map({
      target: "modalMap",
      interactions: ol.interaction.defaults({
        doubleClickZoom: false,
        dragAndDrop: false,
        dragPan: false,
        keyboardPan: false,
        keyboardZoom: false,
        mouseWheelZoom: false,
        pointer: false,
        select: false
      }),
      view: new ol.View({
        center: ol.proj.fromLonLat([4.91142800421774, 52.3837847076631]),
        zoom: 7
      }),
      layers: Object.keys(GEODAN_MAPS_LAYERS.BASE).map(function() {
        return new ol.layer.Tile({
          source: BaseVectorSource,
          type: "base"
        });
      }),
      logo: false
    });

    addTripShape(props.shapeData.RitId);
  };

  const setMap = () => {
    // Listen to map changes
    modalMap.on("moveend", () => {
      // let _center = modalMap.getView().getCenter();
      // let _zoom = modalMap.getView().getZoom();
      // console.log(_zoom, _center);
    });

    if (props.coords !== null && Object.entries(mapCoords).length === 0) {
      // INITIAL DEPOT
      setMapCoords(props.coords);
    } else if (
      props.coords !== null &&
      Object.entries(mapCoords).length !== 0 &&
      props.coords !== mapCoords
    ) {
      // SELECT NEW DEPOT
      setMapCoords(props.coords);
    }
  };

  // *************************************** Add new TripShape
  //
  //
  const addTripShape = tripShapeId => {
    let shapeArr;

    TripsShapesVectorSource.clear();

    TripsShapesVectorLayer.set("name", "TripShapes");

    for (let i = 0; i < cachedTripShapesData.length; i++) {
      if (cachedTripShapesData[i].RitId === tripShapeId) {
        shapeArr = cachedTripShapesData[i].Shape;
      }
    }

    const tripFeatureStyle = new ol.style.Style({
      zIndex: 15,
      stroke: new ol.style.Stroke({
        color: `rgba(255,0,0, 1)`,
        width: 1
      }),
      fill: new ol.style.Fill({
        color: `rgba(255,0,0, 0.2)`
      })
    });

    const allCoords = [];

    for (let j = 0; j < shapeArr.length; j++) {
      let coords = shapeArr[j].Coordinates;
      var points = [];

      for (let k = 0; k < coords.length; k++) {
        let latlng = coords[k];
        points.push(
          ol.proj.transform([latlng.Lng, latlng.Lat], "EPSG:4326", "EPSG:3857")
        );
      }

      allCoords.push(points);
    }

    let geometryObjects = [];
    let usedIndexes = [];

    for (let i = 0; i < allCoords.length; i++) {
      const geometry = new ol.geom.Polygon([allCoords[i]]);

      for (let j = 0; j < allCoords.length; j++) {
        if (j !== i) {
          // check the intersecting coordinates and setProperties for the Polygons
          const intersectCheckGeom = new ol.geom.Polygon([allCoords[j]]);
          const firstCoord = intersectCheckGeom.getFirstCoordinate();

          if (geometry.intersectsCoordinate(firstCoord)) {
            intersectCheckGeom.setProperties({
              Type: "InnerShape",
              ChildOf: i,
              index: j
            });
            geometryObjects.push(intersectCheckGeom);
            if (!usedIndexes.includes(j)) {
              usedIndexes.push(j);
            }

            if (!usedIndexes.includes(i)) {
              usedIndexes.push(i);
              geometry.setProperties({
                Type: "OuterShape",
                Parent: i,
                index: i
              });
              geometryObjects.push(geometry);
            }
          }
        }
      }
    }

    // declare non intersecting geometries as 'StandaloneShape'
    if (geometryObjects.length === 0) {
      for (let i = 0; i < allCoords.length; i++) {
        const geometry = new ol.geom.Polygon([allCoords[i]]);
        geometry.setProperties({
          Type: "StandaloneShape"
        });
        geometryObjects.push(geometry);
      }
    } else {
      if (geometryObjects.length < allCoords.length) {
        for (let i = 0; i < allCoords.length; i++) {
          if (usedIndexes.includes(i)) {
            // console.log("don't make standalone shape");
          } else {
            const geometry = new ol.geom.Polygon([allCoords[i]]);
            geometry.setProperties({
              Type: "StandaloneShape"
            });
            geometryObjects.push(geometry);
          }
        }
      }
    }

    const featureArr = [];

    // create features
    geometryObjects.forEach(geometry => {
      if (geometry.getProperties().Type === "StandaloneShape") {
        let feature = new ol.Feature({
          geometry: geometry
        });
        feature.setStyle(tripFeatureStyle);
        feature.set("geomType", 1); // 1 = standalone shape
        featureArr.push(feature);
        // TripsShapesVectorSource.addFeature(feature);
      } else if (geometry.getProperties().Type === "OuterShape") {
        // if shape has InnerShapes append them to it's geometry
        geometryObjects.forEach(geometryInner => {
          if (geometryInner.getProperties().Type === "InnerShape") {
            if (
              geometry.getProperties().Parent ===
              geometryInner.getProperties().ChildOf
            ) {
              const aHole = geometryInner.getLinearRing();
              geometry.appendLinearRing(aHole);
            }
          }
        });
        let feature = new ol.Feature({
          geometry: geometry
        });
        feature.setStyle(tripFeatureStyle);
        feature.set("geomType", 2); // 2 = outershape
        featureArr.push(feature);
        // TripsShapesVectorSource.addFeature(feature);
      }
    });

    featureArr.sort(function(a, b) {
      return a.get("geomType") - b.get("geomType");
    });

    featureArr.forEach(feature => {
      TripsShapesVectorSource.addFeature(feature);
    });

    modalMap.addLayer(TripsShapesVectorLayer);

    // Center map to TripShape
    modalMap
      .getView()
      .fit(TripsShapesVectorSource.getExtent(), modalMap.getSize());
  };

  return (
    <>
      <div id="modalMapContainer" />
      {imageCreated ? null : <div id="modalMap" />}
    </>
  );
};

export default ModalMap;
