import React, { useState, useEffect, useRef } from "react";
import { TextField, Tooltip } from "@material-ui/core";
import Icons from "../../components/Icons";
import configSettings from "../../settings";
import ErrorSnackbar from "../../components/Utils/ErrorSnackbar";

export default function Kilometers(props) {
  const editableFieldsPerRow = 2;

  const [ContractId, setContractId] = useState(0);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMsg] = useState(
    "Er is iets fout gegaan bij het opslaan van de gegevens"
  );
  const [Explanation, setExplanation] = useState("");
  const [IsSnackbarAutoClosing, setIsSnackbarAutoClosing] = useState(true);
  const [KilometersData, setKilometersData] = useState([]);
  const [TabIndexBase, setTabIndexBase] = useState(0);
  const [TextareaValueOnFocus, setTextareaValueOnFocus] = useState("");

  const [FieldValueOnFocusStart, setFieldValueOnFocusStart] = useState(null);
  const [FocusedIndex, setFocusedIndex] = useState(null);
  const [FocusedInputName, setFocusedInputName] = useState(null);
  const focusedFieldValue = useRef("");
  const lastBlurredInputName = useRef(null);
  const lastBlurredInputValue = useRef(null);
  const lastBlurredInputIndex = useRef(null);

  useEffect(() => {
    setContractId(props.contractId);
    setTabIndexBase(props.tabIndexBase);
  }, []);

  useEffect(() => {
    const kmCategory = props.kilometersCategory;
    const kmData = [...kmCategory.Kilometers];
    const toelichtingExtraKmToegekend = kmCategory.ToelichtingExtraKmToegekend
      ? kmCategory.ToelichtingExtraKmToegekend
      : "";
    setExplanation(toelichtingExtraKmToegekend);

    if (lastBlurredInputValue.current) {
      kmData[lastBlurredInputIndex.current][lastBlurredInputName.current] =
        lastBlurredInputValue.current;
    }

    setKilometersData(kmData);
  }, [props.kilometersCategory]);

  const handleNumericFieldChange = (e, index, row) => {
    const name = e.target.name;
    const value = e.target.value;
    const kmData = [...KilometersData];
    let correctedValue;

    if (value !== "") {
      // eslint-disable-next-line
      if (value.match(/^\s*-?(\d+(\,\d{1,2})?|\,\d{1,2})\s*$/)) {
        correctedValue = value;
        focusedFieldValue.current = correctedValue;
      } else {
        correctedValue = focusedFieldValue.current;
      }
    } else if (value === "") {
      correctedValue = 0;
      focusedFieldValue.current = correctedValue;
    }

    if (value[value.length - 1] === ",") {
      correctedValue = value;
      focusedFieldValue.current = value;
    }

    switch (name) {
      case "ExtraOnOff":
        kmData[index].ExtraAanAfrijKilometers = correctedValue;
        break;
      case "ExtraOrderMileage":
        kmData[index].ExtraBestelKilometers = correctedValue;
        break;
      default:
        break;
    }

    setKilometersData(kmData);
  };

  const handleKeyPress = e => {
    // Pressing Enter
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const onBlurField = (e, index, row) => {
    const name = e.target.name;
    let field;

    setFocusedInputName(null);
    setFocusedIndex(null);

    switch (name) {
      case "ExtraOnOff":
        field = "ExtraAanAfrijKilometers";
        break;
      case "ExtraOrderMileage":
        field = "ExtraBestelKilometers";
        break;
      default:
        break;
    }

    if (`${FieldValueOnFocusStart}` !== `${focusedFieldValue.current}`) {
      lastBlurredInputName.current = field;
      lastBlurredInputValue.current = focusedFieldValue.current;
      lastBlurredInputIndex.current = index;
      updateKilometersCall(e, index, row);
    }
  };

  const onFocusField = (e, index) => {
    // select all text
    e.target.select();
    setFocusedInputName(e.target.name);
    setFocusedIndex(index);
    focusedFieldValue.current = e.target.value;
    // Storing the FieldValue before any changes
    // to prevent unnecessary POSTs
    setFieldValueOnFocusStart(e.target.value);
  };

  const onFocusTextarea = e => {
    // Storing the FieldValue before any changes
    // to prevent unnecessary POSTs
    setTextareaValueOnFocus(e.target.value);
  };

  const revertValueOnBadRequest = (name, _index, prevFieldValueOnFocus) => {
    const revertedData = KilometersData.map((entry, index) => {
      if (index === _index) {
        switch (name) {
          case "ExtraOnOff":
            entry.ExtraAanAfrijKilometers = prevFieldValueOnFocus;

            break;
          case "ExtraOrderMileage":
            entry.ExtraBestelKilometers = prevFieldValueOnFocus;

            break;
          default:
            break;
        }
      }
      return entry;
    });

    setKilometersData(revertedData);
  };

  // HTTP Requests
  const updateKilometersCall = async (e, index, row, selectedValue = null) => {
    const prevFieldValueOnFocus = selectedValue
      ? selectedValue
      : FieldValueOnFocusStart;
    setFieldValueOnFocusStart(null);

    const name = e.target.name;

    const loaders = document.querySelectorAll(
      "#kmsFieldsContainer .grid-custom-loader"
    );
    const loader = loaders[index + 1];
    loader.classList.add("visible");

    fetch(configSettings.UpdateRouteKilometersUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Id: row.Id,
        RitId: row.RitId,
        ContractKilometersId: row.ContractKilometersId,
        ExtraAanAfrijKilometers:
          lastBlurredInputName.current === "ExtraAanAfrijKilometers"
            ? parseFloat(
                lastBlurredInputValue.current.toString().replace(",", "."),
                10
              )
            : parseFloat(
                row.ExtraAanAfrijKilometers.toString().replace(",", "."),
                10
              ),
        ExtraBestelKilometers:
          lastBlurredInputName.current === "ExtraBestelKilometers"
            ? parseFloat(
                lastBlurredInputValue.current.toString().replace(",", "."),
                10
              )
            : parseFloat(
                row.ExtraBestelKilometers.toString().replace(",", "."),
                10
              )
      })
    })
      .then(function(res) {
        return res.json();
      })
      .then(function(data) {
        props.updateCategories();
        // updateGrid(data, index, row);
      })
      .catch(error => {
        console.log(error);
        setIsSnackbarAutoClosing(true);
        setErrorSnackbarOpen(true);
        if (prevFieldValueOnFocus) {
          revertValueOnBadRequest(name, index, prevFieldValueOnFocus);
        }
      })
      .finally(() => {
        loader.classList.remove("visible");
      });
  };

  const updateKilometersExplanationCall = async e => {
    if (TextareaValueOnFocus !== e.target.value) {
      const loaderElem = document.getElementById("textareaKmsLoader");
      loaderElem.classList.add("visible");

      fetch(configSettings.UpdateKilometersExplanationUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ContractId: ContractId,
          ToelichtingExtraKmToegekend: Explanation
        })
      })
        .then(function(res) {
          return res.text();
        })
        .then(function(data) {
          const isTrueSet = data === "true";
          if (!isTrueSet) {
            throw new Error(errorSnackbarMsg);
          }
        })
        .catch(error => {
          console.log(error);
          setIsSnackbarAutoClosing(false);
          setErrorSnackbarOpen(true);
        })
        .finally(() => {
          loaderElem.classList.remove("visible");
        });
    }
  };

  return (
    <div id="kilometersSection">
      <div id="kmsFieldsContainer" className="fields-container">
        <div id="kmsFieldsHeader" className="fields-header flex">
          <div className="normalinputs flex">
            <div className="grid-custom-loader">
              <Icons.Loading />
            </div>
            <div className="column-header route-field">Ritnaam</div>

            <div className="column-header lg-field">
              <Tooltip title="Extra km aan/afrij per week" placement="top-end">
                <p>Extra km Aan/Afrij</p>
              </Tooltip>
            </div>
            <div className="column-header lg-field">
              <Tooltip title="Extra bestelkm per week" placement="top-end">
                <p>Extra bestelkm</p>
              </Tooltip>
            </div>
          </div>
          <div className="readonly-inputs flex">
            <div className="column-header">
              <Tooltip title="Basis aan-afrijkm per week" placement="top-end">
                <p>Basis aan-afrijkm per week</p>
              </Tooltip>
            </div>
            <div className="column-header">
              <Tooltip title="Basis bestel km per week​​" placement="top-end">
                <p>Basis bestel km per week​​</p>
              </Tooltip>
            </div>
            <div className="column-header">
              <Tooltip title="Km toeslag > 30.000 week" placement="top-end">
                <p>Km toeslag</p>
              </Tooltip>
            </div>
            <div className="column-header">
              <Tooltip
                title="Gevolg totaal km per jaar incl. extra km"
                placement="top-end"
              >
                <p>Gevolg totaal km per jaar</p>
              </Tooltip>
            </div>
          </div>
        </div>

        <div id="kmsFieldsBody" className="fields-body">
          {KilometersData.map((row, index) => {
            return (
              <div key={index} className={`flex contract-details-row row-${0}`}>
                <div className="normalinputs flex">
                  <div className="grid-custom-loader">
                    <Icons.Loading />
                  </div>
                  <p className="cdgrid-field route-field">{row.RitName}</p>

                  <TextField
                    variant="outlined"
                    className="cdgrid-field lg-field"
                    name={"ExtraOnOff"}
                    value={
                      FocusedInputName === "ExtraOnOff" &&
                      index === FocusedIndex
                        ? focusedFieldValue.current
                        : row.ExtraAanAfrijKilometers
                    }
                    onChange={e => handleNumericFieldChange(e, index, row)}
                    onFocus={e => onFocusField(e, index)}
                    onBlur={e => onBlurField(e, index, row)}
                    onKeyDown={e => handleKeyPress(e)}
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 1)}`,
                      min: 0
                    }}
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field lg-field"
                    name={"ExtraOrderMileage"}
                    value={
                      FocusedInputName === "ExtraOrderMileage" &&
                      index === FocusedIndex
                        ? focusedFieldValue.current
                        : row.ExtraBestelKilometers
                    }
                    onChange={e => handleNumericFieldChange(e, index, row)}
                    onFocus={e => onFocusField(e, index)}
                    onBlur={e => onBlurField(e, index, row)}
                    onKeyDown={e => handleKeyPress(e)}
                    inputProps={{
                      tabIndex: `${TabIndexBase +
                        (index * editableFieldsPerRow + 2)}`,
                      min: 0
                    }}
                  />
                </div>
                <div className="readonly-inputs flex">
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced"
                    value={row.AanAfrijKmPerWeek}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced"
                    value={row.BestelKmPerWeek}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced currency"
                    value={row.KmToeslag}
                    readOnly
                  />
                  <TextField
                    variant="outlined"
                    className="cdgrid-field calced"
                    value={row.TotaalKmPerJaar}
                    readOnly
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex textarea-container" id="kmsTextareaContainer">
          <div id="textareaKmsLoader" className="textarea-loader">
            <Icons.Loading />
          </div>

          <div>
            <label className="textarea-title">
              Toelichting extra kilometers toegekend.
            </label>
            <TextField
              className="big-textbox"
              aria-label="Toelichting extra kilometers toegekend"
              variant="outlined"
              name="ExplanationExtraTextarea"
              value={Explanation}
              onChange={e => setExplanation(e.target.value)}
              onFocus={e => onFocusTextarea(e)}
              onBlur={e => updateKilometersExplanationCall(e)}
              tabIndex={TabIndexBase + (KilometersData.length + 1 + 2 * 1)}
            />
          </div>
        </div>
      </div>

      {errorSnackbarOpen && (
        <ErrorSnackbar
          open={errorSnackbarOpen}
          errorMessage={errorSnackbarMsg}
          closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
          autoCloseSeconds={IsSnackbarAutoClosing ? 3 : false}
        />
      )}
    </div>
  );
}
