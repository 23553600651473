import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import FilterBar from "../components/Utils/FilterBar";
import TMRoutesTable from "../components/Tables/TMRoutesTable";
import TIRequestsTable from "../components/Tables/TIRequestsTable";
import configSettings from "../settings";
import Api from "../helpers/http";
import PropTypes from "prop-types";

import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ErrorSnackbar from "../components/Utils/ErrorSnackbar";
import SuccessSnackbar from "../components/Utils/SuccessSnackbar";

import { makeStyles } from "@material-ui/core/styles";
import LoadingModal from "../components/Modals/LoadingModal";

import { saveAs } from "file-saver";
import useAuth from "../hooks/useAuth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box className="box-root" p={3}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  indicator: {
    backgroundColor: "rgba(255,255,255,0)"
  },
  icon: {
    fill: "#212121",
    top: "10px;"
  }
}));

let TIRequestInterval;
let pollingIntervalms = 10000;
let IS_MOUNTED;

export default function TariffMaker() {
  const classes = useStyles();

  const [RoutesLoading, setRoutesLoading] = useState(false);
  const [RouteData, setRouteData] = useState([]);
  const [SelectedRoutes, setSelectedRoutes] = useState([]);

  const [Depots, setDepots] = useState([]);
  const [SelectedDepotId, setSelectedDepotId] = useState(0);
  const [SelectedSubcontractor, setSelectedSubcontractor] = useState([]);
  const [Subcontractors, setSubcontractors] = useState([]);

  const [SelectedDownloadDepotId, setSelectedDownloadDepotId] = useState(0);

  const [TIRequests, setTIRequests] = useState([]);
  const [TIRequestsLoading, setTIRequestsLoading] = useState(false);

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [snackbarBGcolor] = useState("green");
  const [successSnackbarMsg, setSuccessSnackbarMsg] = useState("");
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const [TabPanelValue, setTabPanelValue] = React.useState(0);
  const { globalSelectedDepot, setGlobalSelectedDepot } = useAuth();

  function handleTabChange(event, newValue) {
    setTabPanelValue(newValue);
    if (TabPanelValue === 1) {
      // change to 1st tab
      clearInterval(TIRequestInterval);
      setSelectedDepotId(SelectedDownloadDepotId);
    }
    if (TabPanelValue === 0) {
      // change to 2nd tab
      clearInterval(TIRequestInterval);
      if (Depots.length === 1) {
        TIRequestsPolling(SelectedDownloadDepotId);
      }
      if (SelectedDepotId) {
        setSelectedDownloadDepotId(SelectedDepotId);
        if (SelectedDownloadDepotId === SelectedDepotId) {
          TIRequestsPolling(SelectedDepotId);
        }
      }
    }
  }

  useEffect(() => {
    getDepots();

    const headerTitle = document.getElementById("appHeaderTitle");
    headerTitle.innerHTML = "Tariefmodel";
    IS_MOUNTED = true;

    return () => {
      clearInterval(TIRequestInterval);
      IS_MOUNTED = false;
    };
  }, []);

  useEffect(() => {
    if (Depots.length) {
      getSubcontractors(SelectedDepotId);
      setSelectedSubcontractor([]);
      setRouteData([]);
    }
  }, [SelectedDepotId]);

  useEffect(() => {
    if (SelectedDownloadDepotId) {
      if (TIRequestInterval) {
        clearInterval(TIRequestInterval);
      }
      TIRequestsPolling(SelectedDownloadDepotId);
    }
  }, [SelectedDownloadDepotId]);

  const TIRequestsPolling = depotId => {
    getTiRequests(depotId);

    TIRequestInterval = setInterval(() => {
      getTiRequests(depotId);
    }, pollingIntervalms);
  };

  const getTiRequests = async depotId => {
    setTIRequestsLoading(true);
    let _TIRequests = await Api.get(
      `${configSettings.GetTIRequests}/${depotId}`
    );
    if (IS_MOUNTED) {
      if (_TIRequests.length) {
        _TIRequests.forEach(element => {
          element.CreatedDate =
            element.CreatedDate.split("T")[0] +
            " " +
            element.CreatedDate.split("T")[1].split(".")[0];

          // get Ritcodes and Ritnames from TIRequests
          let TIRequestRoutes = [];
          element.TirequestRitten.forEach(route => {
            TIRequestRoutes.push(" " + route.Ritcode + " " + route.Ritnaam);
          });
          // TIReqRoutesString = TIRequestRoutes.join();
          element.Routes = TIRequestRoutes.join();
        });
      } else {
        clearInterval(TIRequestInterval);
        _TIRequests = [];
      }
      setTIRequestsLoading(false);
      setTIRequests(_TIRequests);
    }
  };

  useEffect(() => {
      if (SelectedSubcontractor.length) {
          getRoutes(SelectedDepotId, 0, 0, SelectedSubcontractor);
      } else if (SelectedSubcontractor.length === 0) {
          // 230207 12:53 clear when nothing checked
          setRoutesEmpty(SelectedDepotId);
      }
  }, [SelectedSubcontractor]);

  const getDepots = async () => {
    const _Depots = await Api.get(`${configSettings.TM_GetDepots}`);
    if (IS_MOUNTED) {
      if (_Depots.length) {
        setDepots(_Depots);
        if (_Depots.length === 1) {
          setSelectedDepotId(_Depots[0].Id);
          setSelectedDownloadDepotId(_Depots[0].Id);
          setGlobalSelectedDepot(_Depots[0]);
        } else if (globalSelectedDepot) {
          setSelectedDepotId(globalSelectedDepot.Id);
          setSelectedDownloadDepotId(globalSelectedDepot.Id);
          setGlobalSelectedDepot(globalSelectedDepot);
        }
      }
    }
  };

  const getSubcontractors = async depotId => {
    const _Subcontractors = await Api.get(
      `${configSettings.TM_GetSubCos}/${depotId}`
    );
    if (IS_MOUNTED) {
      if (_Subcontractors.length >= 0) {
        setSubcontractors(_Subcontractors);
      }
    }
  };

  const getRoutes = async (depotId, scenarioId, context, subContractors) => {
    if (depotId) {
      setRoutesLoading(true);
      let routes;

      // Onbekend = empty (routes without subcos) and Alles = all routes
      if (subContractors) {
        if (firstSelectedSubCoName() === "Onbekend") {
          routes = await Api.get(
            `${configSettings.TM_GetRoutes}/${depotId}/${scenarioId}/${context}?sc=${configSettings.EmptyGuid}`
          );
        } else if (firstSelectedSubCoName() === "Alles") {
          routes = await Api.get(`${configSettings.TM_GetRoutes}/${depotId}/${scenarioId}/${context}`);
        } else {
          // All or more than one routes
          let subCoString = "";
          subContractors.forEach((subCo, index) => {
            subCoString = `${subCoString}${index ? "&" : ""}sc=${subCo.Id}`;
          });

          routes = await Api.get(
            `${configSettings.TM_GetRoutes}/${depotId}/${scenarioId}/${context}?${subCoString}`
          );
        }
      } else {
        routes = await Api.get(`${configSettings.TM_GetRoutes}/${depotId}/${scenarioId}/${context}`);
      }

      if (IS_MOUNTED) {
        if (routes.length) {
          setRouteData(routes);

          let _selectedRoutes = [];

          routes.forEach((route, index) => {
            if (subContractors && subContractors[0].SubcoNaam === "Onbekend") {
              // select Onbekend SubCo
              if (
                route.Id === undefined ||
                route.Id === null ||
                route.Id === ""
              ) {
                _selectedRoutes.push(index);
              }
            } else if (
              subContractors &&
              subContractors[0].SubcoNaam === "Alles"
            ) {
              _selectedRoutes.push(index);
            } else {
              // select depot at the start
              _selectedRoutes.push(index);
            }
          });

          setSelectedRoutes(_selectedRoutes);
        } else {
          setSelectedRoutes([]);
          setRouteData([]);
        }
        setRoutesLoading(false);
      }
    }
  };

    const setRoutesEmpty = async (id) => {
        if (id) {
            setRoutesLoading(true);
            if (IS_MOUNTED) {
                setSelectedRoutes([]);
                setRouteData([]);
            }
            setRoutesLoading(false);
        }
    };

    const handleFilterChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "depotTIselect") {
      setSelectedDepotId(value);
    } else if (name === "depotTIDownloadSelect") {
      setSelectedDownloadDepotId(value);
    }

    const _depotSelected = Depots.find(depot => depot.Id === value);

    setGlobalSelectedDepot(_depotSelected);
  };

  const handleSubcosChange = event => {
    const value = event.target.value;
    let subcoSelection;

    // Onbekend = empty (routes without subcos) and Alles = all routes
    if (
      value.filter(v => {
        return v.SubCoNaam === "Onbekend";
      }).length > 0 ||
      value.filter(v => {
        return v.SubCoNaam === "Alles";
      }).length > 0
    ) {
      subcoSelection = [];
      if (value.length === 1) {
        subcoSelection = value;
      } else if (value.length > 1) {
        subcoSelection = [value[value.length - 1]];
      }
    } else {
      if (value.length > SelectedSubcontractor.length) {
        subcoSelection = [...SelectedSubcontractor];
        subcoSelection.push(value[value.length - 1]);
      } else {
        subcoSelection = value;
      }
    }

    setSelectedSubcontractor(subcoSelection);
  };

  const onTIRequest = ritIds => {
    setLoadingText("Creating TI Request...");
    setShowLoadingModal(true);

    fetch(`${configSettings.TarrifCreate}`, {
      method: "POST",
      body: JSON.stringify(ritIds),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.ok) {
          setShowLoadingModal(false);
          setSuccessSnackbarMsg("TI Request created successfuly");
          setSuccessSnackbarOpen(true);

          handleTabChange({}, 1);
        } else {
          setShowLoadingModal(false);
          setErrorSnackbarMsg("Error creating TI Request");
          setErrorSnackbarOpen(true);
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .catch(error => {
        console.error(error);
        setShowLoadingModal(false);
        setErrorSnackbarMsg("Error creating TI Request");
        setErrorSnackbarOpen(true);
      });
  };

    const onExportPostcodeRangeRequest = ritIds => {
        setLoadingText("Creating export...");
        setShowLoadingModal(true);
        let filename = "download.xlsx";
        let url = `${configSettings.ExportPostcodeRangeAanvraag}`;
        let fd = new FormData();
        fd.append("depot", globalSelectedDepot.Naam);
        fd.append("ritIds", ritIds);

        fetch(`${url}`, {
                    method: "POST",
                    body: fd
                })
                    .then(response => {
                        response.headers.forEach(function (val, key) {
                            if (key === 'content-disposition') {
                                filename = val.replace('inline; filename=', '');
                            }
                        });
						setShowLoadingModal(false);
                        return response.blob();
                    })
            .then(blob => {
                saveAs(blob, filename);
                    })
                    .catch(function (error) {
                        console.log("Error getting file: ", error.message);
                        setShowLoadingModal(false);
                    });
    };

    const downloadTIRequest = requestId => {
    setLoadingText("Downloading TI Request...");
    setShowLoadingModal(true);

    let filename;

    fetch(configSettings.DownloadRequestUrl + "/" + requestId)
      .then(response => {
        response.headers.forEach(function(val, key) {
          if (val && val.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(val);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
        });
        return response.blob();
      })
      .then(blob => {
        if (filename) {
          saveAs(blob, filename);
          setShowLoadingModal(false);
        } else {
          setShowLoadingModal(false);
          setErrorSnackbarOpen(true);
        }
      })
      .catch(function(error) {
        setShowLoadingModal(false);
        setErrorSnackbarMsg("Error getting CSV: ", error.message);
        setErrorSnackbarOpen(true);
      });
  };

  const firstSelectedSubCoName = () => {
    return SelectedSubcontractor && SelectedSubcontractor.length > 0
      ? SelectedSubcontractor[0].SubCoNaam
      : "";
  };

  const isSelectedSubCo = subCoId => {
    return (
      SelectedSubcontractor.filter(s => {
        return s.Id === subCoId;
      }).length > 0
    );
  };

  return (
    <div>
      <Header />

      <div id="TarifMakerScreen" className=" mt2">
        <div id="RequestTIScreen" className="TariffModel">
          <div className={classes.root}>
            <Tabs
              id="infoTabs"
              value={TabPanelValue}
              onChange={handleTabChange}
              classes={{
                indicator: classes.indicator
              }}
              textColor="primary"
              aria-label="info tabs"
            >
              <Tab label="Nieuwe aanvraag" {...a11yProps(0)} />
              <Tab label="Download aanvraag" {...a11yProps(1)} />
            </Tabs>
            <TabPanel className="tabpanel" value={TabPanelValue} index={0}>
              <FilterBar>
                <div className="flex-column">
                  <label htmlFor="">Selecteer Depot</label>

                  <Select
                    onChange={handleFilterChange}
                    name="depotTIselect"
                    value={SelectedDepotId}
                    disabled={Depots ? false : true}
                    inputProps={{
                      classes: {
                        icon: classes.icon
                      }
                    }}
                    autoFocus
                  >
                    {SelectedDepotId === null ? (
                      <MenuItem
                        key={"noDepotSelected"}
                        value={"noDepotSelected"}
                      >
                        <ListItemText primary="" />
                      </MenuItem>
                    ) : null}

                    {Depots.map(depot => (
                      <MenuItem key={depot.Id} value={depot.Id}>
                        <ListItemText primary={depot.Naam} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  className={`flex-column ${
                    Subcontractors.length > 0 ? "" : "disabled-select"
                  }`}
                >
                  <label>Selecteer uitvoerder</label>
                  <Select
                    multiple
                    value={SelectedSubcontractor}
                    onChange={handleSubcosChange}
                    input={<Input id="subcoTIselect" />}
                    renderValue={selected =>
                      firstSelectedSubCoName() === "Onbekend"
                        ? "Onbekend"
                        : selected.map(s => s.SubCoNaam).join(", ")
                    }
                    // MenuProps={MenuProps}
                    inputProps={{
                      classes: {
                        icon: Subcontractors.length > 0 ? classes.icon : "white"
                      }
                    }}
                    disabled={Subcontractors.length > 0 ? false : true}
                  >
                    <MenuItem key="-1000" value={{ SubCoNaam: "Onbekend" }}>
                      <Checkbox
                        checked={
                          (firstSelectedSubCoName() === "Onbekend" ||
                            firstSelectedSubCoName() === "Alles") &&
                          SelectedSubcontractor.length === 1
                        }
                      />
                      <ListItemText primary={"Onbekend"} />
                    </MenuItem>
                    <MenuItem key="-999" value={{ SubCoNaam: "Alles" }}>
                      <Checkbox
                        checked={
                          SelectedSubcontractor.length ===
                            Subcontractors.length ||
                          firstSelectedSubCoName() === "Alles"
                        }
                      />
                      <ListItemText primary={"Alles"} />
                    </MenuItem>

                    {Subcontractors.map((subco, index) => {
                      return (
                        <MenuItem key={index} value={subco}>
                          <Checkbox
                            checked={
                              isSelectedSubCo(subco.Id) ||
                              firstSelectedSubCoName() === "Alles"
                            }
                          />
                          <ListItemText primary={subco.SubCoNaam} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </FilterBar>

              <div className="screen-titles mt3 plr2">
                <h2 className="table-title m0">Vraag tariefindicatie aan</h2>
                <p className="table-subtitle m0">Selecteer rit(ten):</p>
              </div>
              <div className="plr2">
                <TMRoutesTable
                data={RouteData}
                loading={RoutesLoading}
                onTIRequest={onTIRequest}
                selectedRoutes={SelectedRoutes}
                onExportPostcodeRangeRequest={onExportPostcodeRangeRequest}
                />
              </div>
            </TabPanel>
            <TabPanel className="tabpanel" value={TabPanelValue} index={1}>
              <FilterBar>
                <div className="flex-column">
                  <label htmlFor="">Selecteer Depot</label>

                  <Select
                    onChange={handleFilterChange}
                    name="depotTIDownloadSelect"
                    value={SelectedDownloadDepotId}
                    disabled={Depots ? false : true}
                    inputProps={{
                      classes: {
                        icon: classes.icon
                      }
                    }}
                    autoFocus
                  >
                    {Depots.length > 1 || SelectedDownloadDepotId === null ? (
                      <MenuItem
                        key={"noDownloadDepotSelected"}
                        value={"noDownloadDepotSelected"}
                      >
                        <ListItemText primary="" />
                      </MenuItem>
                    ) : null}
                    {Depots.map(depot => (
                      <MenuItem key={depot.Id} value={depot.Id}>
                        <ListItemText primary={depot.Naam} />
                      </MenuItem>
                    ))}
                    }
                  </Select>
                </div>
              </FilterBar>
              <div className={`screen-titles st-${TabPanelValue} mt3 plr2`}>
                <h2 className="table-title m0">
                  Download tariefmodel aanvraag
                </h2>
              </div>
              <div className="plr2">
                <TIRequestsTable
                  data={TIRequests}
                  downloadTIRequest={downloadTIRequest}
                  loading={TIRequestsLoading}
                />
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
      <ErrorSnackbar
        open={errorSnackbarOpen}
        errorMessage={errorSnackbarMsg}
        closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
      />
      <SuccessSnackbar
        open={successSnackbarOpen}
        successMessage={successSnackbarMsg}
        BGcolor={snackbarBGcolor}
        closeSuccessSnackbar={() => setSuccessSnackbarOpen(false)}
      />
      <LoadingModal
        showLoadingModal={showLoadingModal}
        loadingText={loadingText}
      />
    </div>
  );
}
