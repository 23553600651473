const BaseUrl = "/Api";
//const BaseUrl = "https://localhost:44326/Api";

const configSettings = {
  // Depots
  DepotsApiUrl: BaseUrl + "/Depots/Get",
  DepotsByIdUrl: BaseUrl + "/Depots/GetDepotById",
  SubcontractorUrl: BaseUrl + "/Subcontractors/GetSubcontractorsByDepotId",
  // Map Shapes
  PostcodeShapesUrl: BaseUrl + "/Geodatas",
  PostcodesUrl: BaseUrl + "/Postcodes",
  TripDetailsUrl: BaseUrl + "/Ritten/GetRitDetails",
  TripShapesUrl: BaseUrl + "/Ritten/GetRitShape",
  AllTripShapesUrl: BaseUrl + "/Ritten/GetAllRitShapes",
  TripsUrl: BaseUrl + "/Ritten/Get", // TRAP: added scenarioId, context, sortMethod
  TripsTableUrl: BaseUrl + "/Ritten/GetRit",
  TripDetailsUpdateUrl: BaseUrl + "/Ritten/UpdateRit",
  TripCreateUrl: BaseUrl + "/Ritten/CreateRit",
  RetailerLocations: BaseUrl + "/RetailerLocaties/GetRetailerLocaties",

  // Trap
  TrapSaveScenarioUrl: BaseUrl + "/Ritten/TrapSaveScenario",
  TrapSaveVarInputUrl: BaseUrl + "/Ritten/TrapSaveVarInput",
  TrapUpdateScenarioUrl: BaseUrl + "/Ritten/TrapUpdateScenario",
  TrapGetScenariosByDepot: BaseUrl + "/Ritten/GetScenariosByDepot",
  TrapGetScenarioById: BaseUrl + "/Ritten/GetScenarioById",
  TrapGetScenarioStatusByIdUrl: BaseUrl + "/Scenario/GetScenarioStatusById",
  TrapToggleSelectedUrl: BaseUrl + "/Scenario/ToggleSelected",
  TrapUpdateDagVolumeUrl: BaseUrl + "/Scenario/UpdateDagVolume",

  // Imports
  ImportRLUrl: BaseUrl + "/Import/SubmitRL",
  ImportABUrl: BaseUrl + "/Import/SubmitAB",
  ImportVGUrl: BaseUrl + "/Import/ImportVG",
  ImportSCUrl: BaseUrl + "/Import/ImportSC",
  ImportNormalUrl: BaseUrl + "/Import/Submit",
  ImportQueueUrl: BaseUrl + "/Import/GetImportQueue",
  ImportLatestUrl: BaseUrl + "/Import/GetLatestImportStatus",
  ImportsActiveUrl: BaseUrl + "/Import/GetActiveImportsStatus",
  CheckActiveImportUrl: BaseUrl + "/Import/CheckBiccImportInProgress",
  // Exports
  ExportPDFUrl: BaseUrl + "/Export/CreateRitPDF",
  ExportSaveUrl: BaseUrl + "/Export/DepotBicc",
  ExportTrapUrl: BaseUrl + "/Export/DepotTrap",
  ExportMutationUrl: BaseUrl + "/Export/DepotMutatieOverzicht",
  TrapExportMutationUrl: BaseUrl + "/Export/TrapDepotMutatieOverzicht",
  ExportOverviewUrl: BaseUrl + "/Export/DepotRitSamenvatting",
  ExportDepotRanges: BaseUrl + "/Export/DepotRanges",
  ExportPostcodeRangeAanvraag: BaseUrl + "/Export/PostcodeRangeAanvraag",
  ExportDepotRangeMutations: BaseUrl + "/Export/DepotRangeMutations",
  DownloadRequestUrl: BaseUrl + "/Export/TIRequest",
  DownloadInterneVerantwoordingTIUrl:
    BaseUrl + "/Export/InterneVerantwoordingTI",
  // User Management
  GetUserRolesUrl: BaseUrl + "/Gebruiker/GetUserRoles",
  GetAllUsersUrl: BaseUrl + "/Gebruiker/GetAllUsers",
  GetCurrentUserUrl: BaseUrl + "/Gebruiker/GetCurrentUser",
  AddUserUrl: BaseUrl + "/Gebruiker/AddUser",
  DeleteUserUrl: BaseUrl + "/Gebruiker/DeleteUser",
  UpdateUserUrl: BaseUrl + "/Gebruiker/UpdateUser",
  //TariefModel
  TM_GetDepots: BaseUrl + "/Tarief/GetDepots",
  TM_GetSubCos: BaseUrl + "/Tarief/GetSubCos",
  TM_GetRoutes: BaseUrl + "/Tarief/GetRitten", // TRAP: added scenarioId, context
  TarrifCreate: BaseUrl + "/Tarief/Create",
  ExportPostcodeRange: BaseUrl + "/Tarief/ExportPostcodeRange",
  GetTIRequests: BaseUrl + "/Tarief/GetTIRequests",
  SubcoOverviewDepots: BaseUrl + "/TariefOverview/GetDepots",
  SubcoOverviewByDepotId: BaseUrl + "/TariefOverview/Get",
  ContractDetailsHours: BaseUrl + "/ContractDetails/GetContractDetails",
  UpdateRouteHoursUrl: BaseUrl + "/ContractDetails/UpdateRouteHours",
  UpdateHoursExplanationUrl:
    BaseUrl + "/ContractDetails/UpdateHoursExplanation",
  UpdateRouteKilometersUrl: BaseUrl + "/ContractDetails/UpdateRouteKilometers",
  UpdateKilometersExplanationUrl:
    BaseUrl + "/ContractDetails/UpdateKilometersExplanation",
  UpdateRouteSubchargesUrl: BaseUrl + "/ContractDetails/UpdateRouteSubcharges",
  UpdateSubcontractorUrl: BaseUrl + "/ContractDetails/UpdateSubcontractor",
  UpdateSubcontractorExplanationUrl:
    BaseUrl + "/ContractDetails/UpdateSubcontractorExplanation",
  UpdateSubchargesExplanationUrl:
    BaseUrl + "/ContractDetails/UpdateSubchargesExplanation",
  UpdateRouteSubcontractorUrl:
    BaseUrl + "/ContractDetails/UpdateRouteSubcontractor",
  ExportInterneVerantwoording:
    BaseUrl + "/ContractDetails/ExportInterneVerantwoording",
  ExportExterneVerantwoording:
    BaseUrl + "/ContractDetails/ExportExterneVerantwoording",
  ExportSpringBoss: BaseUrl + "/ContractDetails/ExportSpringBoss",
  Finish: BaseUrl + "/ContractDetails/Finish",

  // Other
  ServiceKeyUrl: BaseUrl + "/Geodatas/GeodanKey",
  VersionUrl: "/Account/Version",
  ShowRangeMutatiesButton: true,
  ShowDownloadAllDepotRangesButton: true,
  EmptyGuid: "{00000000-0000-0000-0000-000000000000}"
};
export default configSettings;
