import React from "react";

import Icons from "../Icons";
import ReactTable from "react-table";
import Api from "../../helpers/http";
import configSettings from "../../settings";
import functions from "../../helpers/functions";

import "../../styles/app.scss";

const columns = [
  {
    Header: "Bestandsnaam",
    id: "Filename",
    accessor: "Filename",
    className: "column-align-left"
  },
  {
    Header: "Geupload om",
    id: "CreatedOn",
    accessor: d => d.CreatedOn,
    width: 150
  },
  {
    Header: "Importeren gestart om",
    id: "ImportStartOn",
    accessor: d => d.ImportStartOn,
    width: 150
  },
  {
    Header: "Status datum",
    id: "ModifiedOn",
    accessor: d => d.ModifiedOn,
    width: 150
  },
  {
    Header: "StatusCol",
    id: "Status",
    accessor: d => d.Status,
    show: false
  },
  {
    Header: "Status verwerking",
    Cell: row => {
      return functions.getImportStatusText(row.original.Status);
    },
    width: 150
  }
];

let importQueueInterval;

class ImportQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      importQueue: [],
      importsLoading: true
    };
  }

  componentDidMount() {
    this.updateImportQueue(this.props.depotId);

    importQueueInterval = setInterval(() => {
      this.updateImportQueue(this.props.depotId);
    }, 10000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.depotId !== this.props.depotId) {
      if (this.props.depotId) {
        this.updateImportQueue(this.props.depotId);
      }
    }
    if (prevProps.refreshImportQueue !== this.props.refreshImportQueue) {
      if (this.props.refreshImportQueue) {
        this.updateImportQueue(this.props.depotId);
        this.props.onQueueRefreshed();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(importQueueInterval);
  }

  // getActiveImports = async depotId => {
  //   const ActiveImports = await Api.get(
  //     `${configSettings.ImportsActiveUrl}/${depotId}`
  //   );

  //   console.log(ActiveImports);
  // };

  updateImportQueue = async depotId => {
    this.setState({ importsLoading: true });

    const importQueue = await Api.get(
      `${configSettings.ImportQueueUrl}/${depotId}`
    );

    if (this.state.importQueue.length) {
      let previousImportQueue = this.state.importQueue;
      this.refreshDepotOnImportComplete(importQueue, previousImportQueue);
      this.updateLatestImportStatus(importQueue, previousImportQueue);
    }

    this.setState({ importQueue });
    this.tableDataProcessing(importQueue);
  };

  updateLatestImportStatus = (importQueue, previousImportQueue) => {
    if (
      importQueue.length > 0 &&
      importQueue[0].Status !== previousImportQueue[0].Status
    ) {
      this.props.updateLatestImport(importQueue[0]);
    }
  };

  refreshDepotOnImportComplete = (currentImportQueue, previousImportQueue) => {
    // get not completed Ids
    const nonCompletedIds = previousImportQueue
      .filter(file => file.Status < 6)
      .map(file => file.Id);

    nonCompletedIds.forEach(ncId => {
      currentImportQueue.forEach(file => {
        if (ncId === file.Id) {
          if (file.Status >= 6) {
            this.props.refreshDepot();
          }
          if (file.Status === 6) {
            this.props.onImportSuccess();
          }
          if (file.Status === 9) {
            this.props.onImportFailed();
          }
        }
      });
    });
  };

  tableDataProcessing = data => {
    const dataArr = [];

    if (data && data.length) {
      data.forEach(entry => {
        const ModifiedOn =
          entry.ModifiedOn.split("T")[0] +
          " " +
          entry.ModifiedOn.split("T")[1].split(".")[0];

        const CreatedOn =
          entry.CreatedOn.split("T")[0] +
          " " +
          entry.CreatedOn.split("T")[1].split(".")[0];

        var ImportStartOn = null;

        if (entry.ImportStartOn) {
          ImportStartOn =
            entry.ImportStartOn.split("T")[0] +
            " " +
            entry.ImportStartOn.split("T")[1].split(".")[0];
        }

        dataArr.push({
          Filename: entry.Filename,
          CreatedOn,
          ImportStartOn,
          ModifiedOn,
          Status: entry.Status
        });
      });
    }

    this.setState({
      data: dataArr,
      importsLoading: false
    });
  };

  render() {
    const data = this.state.data;
    return (
      <div
        className=".fade-in-slow compact-table hide-pagination"
        id="importQueue"
      >
        <ReactTable
          data={data}
          columns={columns}
          loading={this.state.importsLoading}
          loadingText={
            <div className="grid-custom-loader">
              <Icons.Loading />
              <b>Loading</b>
            </div>
          }
          pageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
export default ImportQueue;
