import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "../Icons/menu.svg";
import DetailsIcon from "../Icons/details.svg";
import RouteMakerIcon from "../Icons/routemaker.svg";
import TarrifMakerIcon from "../Icons/tariff.svg";
import UserMaintenanceIcon from "../Icons/users.svg";
import { withRouter, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

function UserMenu() {
  const { currentUser } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      {Object.entries(currentUser).length !== 0 ? (
        <>
          <div className="user-menu-button" onClick={handleClick}>
            <img src={MenuIcon} alt="Menu" />
          </div>
          <StyledMenu
            id="UserMenu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {currentUser.Role.RoleName === "Beheerder" ||
            currentUser.Role.RoleName === "Administrator" ||
            currentUser.Role.RoleName === "Hoofdkantoor" ||
            currentUser.Role.RoleName === "Depot" ? (
              <Link to="/admin">
                <StyledMenuItem>
                  <ListItemIcon>
                    <img
                      style={{ marginLeft: "2px" }}
                      src={RouteMakerIcon}
                      alt="RM"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Routemaker" />
                </StyledMenuItem>
              </Link>
            ) : null}

            {currentUser.Role.RoleName === "Beheerder" ||
            currentUser.Role.RoleName === "Administrator" ||
            currentUser.Role.RoleName === "Hoofdkantoor" ||
            currentUser.Role.RoleName === "Depot" ? (
              <Link to="/trap1">
                <StyledMenuItem>
                  <ListItemIcon>
                    <img
                      style={{ marginLeft: "2px" }}
                      src={RouteMakerIcon}
                      alt="TRAP"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Trap" />
                </StyledMenuItem>
              </Link>
            ) : null}

            {currentUser.Role.RoleName === "Beheerder" ||
            currentUser.Role.RoleName === "Depot" ||
            currentUser.Role.RoleName === "Sourcing" ||
            currentUser.Role.RoleName === "Control" ||
            currentUser.Role.RoleName === "Administrator" ? (
              <Link to="/tariefmodel">
                <StyledMenuItem>
                  <ListItemIcon>
                    <img src={DetailsIcon} alt="Details" />
                  </ListItemIcon>
                  <ListItemText primary="Tariefmodel" />
                </StyledMenuItem>
              </Link>
            ) : null}
            {currentUser.Role.RoleName === "Beheerder" ||
            currentUser.Role.RoleName === "HS Adviseur" ||
            currentUser.Role.RoleName === "Administrator" ? (
              <Link to="/subcontractors-overzicht/">
                <StyledMenuItem>
                  <ListItemIcon>
                    <img src={TarrifMakerIcon} alt="Tarief" />
                  </ListItemIcon>
                  <ListItemText primary="Contracten" />
                </StyledMenuItem>
              </Link>
            ) : null}
            {currentUser.Role.RoleName === "Beheerder" ||
            currentUser.Role.RoleName === "Administrator" ? (
              <Link to="/user-management/">
                <StyledMenuItem>
                  <ListItemIcon>
                    <img src={UserMaintenanceIcon} alt="Gebruikers" />
                  </ListItemIcon>
                  <ListItemText primary="Gebruikersbeheer" />
                </StyledMenuItem>
              </Link>
            ) : null}
          </StyledMenu>
        </>
      ) : null}
    </div>
  );
}
export default withRouter(UserMenu);
