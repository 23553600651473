const Api = {
  get: async url => {
    let res;
    await fetch(url)
      .then(results => results.json())
      .then(data => {
        res = data;
      })
      .catch(err => {
        console.log(err + " for " + url);
        res = err;
      });
    return res;
  },
  getText: async url => {
    let res;
    await fetch(url)
      .then(results => results.text())
      .then(data => {
        res = data;
      })
      .catch(err => {
        console.log(err + " for " + url);
        res = err;
      });
    return res;
  },
  getFile: async url => {
    let res;
    let filename;
    await fetch(url)
      .then(response => {
        response.headers.forEach(function(val, key) {
          if (
            val &&
            (val.indexOf("attachment") !== -1 || val.indexOf("inline") !== -1)
          ) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(val);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
        });
        return response.blob();
      })
      .then(blob => {
        if (filename) {
          res = { blob, filename };
        } else {
          throw Error();
        }
      })
      .catch(function(err) {
        console.log(err + " for " + url);
        res = "Error";
      });
    return res;
  }
};
export default Api;
