import React from "react";
// eslint-disable-next-line
import { Redirect, Route, Switch } from "react-router-dom";
// eslint-disable-next-line
import AdminScreen from "./screens/AdminScreen";
// eslint-disable-next-line
import TrapScreen from "./screens/TrapScreen";
// eslint-disable-next-line
import UserManagement from "./screens/UserManagement";
// eslint-disable-next-line
import TariffMakerScreen from "./screens/TariffMaker";
// eslint-disable-next-line
import SubcontractorOverview from "./screens/SubcontractorOverview";
import ContractDetails from "./screens/contract-details/contract-details";
// eslint-disable-next-line
import LandCodes from "./enums/LandCodes";
import useAuth from "./hooks/useAuth";

const App = () => {
  const { currentUser } = useAuth();

  return (
    <div className="fade-in-fast relative">
      {Object.entries(currentUser).length !== 0 ? (
        <>
          {/* Admin/Beheerder Routes */}
          {currentUser.Role.RoleName === "Administrator" ||
          currentUser.Role.RoleName === "Beheerder" ? (
            <Switch>
              <Redirect exact from="/" to="/admin/" />
              {/*<Redirect exact from="/" to="/trap/" />*/}
               <Redirect exact from="/" to="/home/" /> 
              <Route path="/admin/" component={AdminScreen} />
              <Route path="/trap1/" component={TrapScreen} />
              <Route path="/tariefmodel/" component={TariffMakerScreen} />
              <Route
                path="/subcontractors-overzicht/"
                component={SubcontractorOverview}
              />
              <Redirect
                exact
                from="/contract-details/"
                to="/subcontractors-overzicht/"
              />
              <Route
                path="/contract-details/:TIRequestId/:SubcontractorId/"
                component={ContractDetails}
              />
              <Route path="/user-management/" component={UserManagement} />
            </Switch>
          ) : null}

          {/* Hs Adviseur Routes Warning: roleid is wrong in the GetCurrentUser endpoint (should be 8)*/}
          {currentUser.Role.RoleName === "HS Adviseur" ? (
            <Switch>
              <Redirect exact from="/" to="/subcontractors-overzicht/" />
              <Route
                path="/subcontractors-overzicht/"
                component={SubcontractorOverview}
              />
              <Redirect
                exact
                from="/contract-details/"
                to="/subcontractors-overzicht/"
              />
              <Route
                path="/contract-details/:TIRequestId/:SubcontractorId/"
                component={ContractDetails}
              />
            </Switch>
          ) : null}

          {/* Depot/Sourcing/Control User Routes */}
          {currentUser.Role.RoleName === "Depot" ||
           currentUser.Role.RoleName === "Sourcing" ||
           currentUser.Role.RoleName === "Hoofdkantoor" ||
           currentUser.Role.RoleName === "Control" ? (
            <Switch>
              <Redirect exact from="/" to="/admin/" />
              <Route path="/admin/" component={AdminScreen} />
              <Route path="/trap1/" component={TrapScreen} />
              <Route path="/tariefmodel/" component={TariffMakerScreen} />
            </Switch>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default App;
