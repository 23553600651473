import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Icons from "../Icons";

const styles = theme => ({
    close: {
        padding: theme.spacing(0.5)
    },
    root: {
        background: "rgb(244, 67, 54)"
    }
});

class ErrorSnackbar extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        open: false
    };

    componentDidMount() {
        if (this.props.autoCloseSeconds) {
            setTimeout(() => {
                this.handleClose();
            }, this.props.autoCloseSeconds * 1000);
        }
    }

    handleClick = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.props.closeErrorSnackbar();
    };

    render() {
        const  { classes } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                open={this.props.open}
                onClose={this.handleClose}
                ContentProps={{
                    "aria-describedby": "message-id",
                    classes: {
                        root: classes.root
                    }
                }}
                message={
                    <span key={1} id="message-id">
                        {this.props.errorMessage}
                    </span>
                }
                action={[
                    <div
                        key={2}
                        className="pointer snackbar-close"
                        onClick={this.handleClose}
                    >
                        <Icons.Close />
                    </div>
                ]}
            />
        );
    }
}

export default withStyles(styles)(ErrorSnackbar);
