import React, { useState, useEffect } from "react";
import "../../styles/app.scss";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircleOutlined";
import Icons from "../Icons";
import { withRouter } from "react-router-dom";
import UserMenu from "./UserMenu";
import functions from "../../helpers/functions";
import useAuth from "../../hooks/useAuth";
import {
  markRetailers,
  isMapLoading,
  removeRetailerMarkers
} from "../Map/AdminMapController";

const Header = ({ history }) => {
  const [version, setVersion] = useState("");
  const [RetailerMarkersActive, setRetailerMarkersActive] = useState(false);
  const { globalSelectedDepot } = useAuth();

  useEffect(() => {
    getVersion();
  }, []);

  useEffect(() => {
    if (globalSelectedDepot) {
      setRetailerMarkersActive(false);
    }
  }, [globalSelectedDepot]);

  const getVersion = async () => {
    const _version = await functions.getVersion();
    setVersion(_version);
  };

  const setFullScreen = () => {
    const fullscreenBtn = document.querySelector("button.ol-full-screen-false");
    fullscreenBtn.click();
  };

  const toggleRetailerMarkers = () => {
    if (!isMapLoading()) {
      if (globalSelectedDepot) {
        RetailerMarkersActive
          ? removeRetailerMarkers()
          : markRetailers(globalSelectedDepot.Id);

        setRetailerMarkersActive(!RetailerMarkersActive);
      }
    }
  };

  return (
    <div className="Header">
      <div className="header-left flex">
        <div className="header-logo flex">
          <h2 className="m0" id="appHeaderTitle" name={version}>RouteMaker v3.0.0.001</h2>
          {/* <h2 className="m0" id="appHeaderTitle">
            RouteMakerTempTitle
          </h2> */}
          {/* <p className="version"> v{version}</p> */}
        </div>
        {history.location.pathname === "/admin/" ? (
          <div className="header-map-buttons">
            <Button
              size="small"
              className="normalcase ml1"
              variant="outlined"
              onClick={() => setFullScreen()}
            >
              <AddIcon />
              Kaart fullscreen
            </Button>

            <Button
              size="small"
              className="normalcase"
              variant="outlined"
              onClick={() => toggleRetailerMarkers()}
            // disabled={() => isMapLoading()}
            >
              <Icons.Marker />
              {RetailerMarkersActive
                ? "Retailers verbergen"
                : "Retailers tonen"}
            </Button>
          </div>
        ) : null}
      </div>
      <div className="header-right flex-reverse">
        <UserMenu />
      </div>
    </div>
  );
};

export default withRouter(Header);
