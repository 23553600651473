import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Button, TextField, Tooltip } from "@material-ui/core";
import Icons from "../../components/Icons";
import ErrorSnackbar from "../../components/Utils/ErrorSnackbar";
import { MoreHoriz } from "@material-ui/icons";
import configSettings from "../../settings";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";

export default function Subcontractor(props) {
  const UIText = {
    COPY_TOOLTIP1: "Click to copy to clipboard",
    COPY_TOOLTIP2: "Postcode ranges copied!",
  };
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMsg] = useState(
    "Er is iets fout gegaan bij het opslaan van de gegevens"
  );
  const [IsSnackbarAutoClosing, setIsSnackbarAutoClosing] = useState(true);
  const [SubcontractorData, setSubcontractorData] = useState([]);
  const [ExplanationStopTarief, setExplanationStopTarief] = useState("");
  const [ExplanationHitrate, setExplanationHitrate] = useState("");
  const [
    ExplanationStopTariefUpdating,
    setExplanationStopTariefUpdating,
  ] = useState(false);
  const [
    ExplanationHitrateUpdating,
    setExplanationHitrateUpdating,
  ] = useState(false);
  const [ExplanationValueOnFocus, setExplanationValueOnFocus] = useState("");
  const [ShowOptionalFields, setShowOptionalFields] = useState(false);
  const focusedFieldValue = useRef(null);
  const [FieldValueOnFocusStart, setFieldValueOnFocusStart] = useState(null);
  const [FocusedIndex, setFocusedIndex] = useState(null);

  const [FocusedInputName, setFocusedInputName] = useState(null);
  const lastBlurredInputName = useRef(null);
  const lastBlurredInputValue = useRef(null);
  const lastBlurredInputIndex = useRef(null);
  const [CopyTooltipMessage, setCopyTooltipMessage] = useState(
    UIText.COPY_TOOLTIP1
  );
  const tooltipMessageLeaveDelay = 200;

  useEffect(() => {
    setIsSnackbarAutoClosing(true);
    const subContractorCategory = props.subContractorCategory;
    const subcontractorData = [...subContractorCategory.Subcontractor];

    const cloned = JSON.parse(JSON.stringify(subcontractorData));
    const multipliedData = modifyPercentages(cloned, "multiply");

    setSubcontractorData(multipliedData);
    setExplanationStopTarief(
      subContractorCategory.ToelichtingAfwijkingStoptarief
        ? subContractorCategory.ToelichtingAfwijkingStoptarief
        : ""
    );
    setExplanationHitrate(
      subContractorCategory.ToelichtingAndereHitrateToegekend
        ? subContractorCategory.ToelichtingAndereHitrateToegekend
        : ""
    );
  }, [props.subContractorCategory]);

  const modifyPercentages = (arr, mod) => {
    if (arr.length) {
      arr.forEach((el) => {
        if (el.StopHitrate) {
          el.StopHitrate =
            mod === "multiply" ? el.StopHitrate * 100 : el.StopHitrate / 100;
        }
        if (el.StukHitrate) {
          el.StukHitrate =
            mod === "multiply" ? el.StukHitrate * 100 : el.StukHitrate / 100;
        }
        if (el.AangepastStopHitrate) {
          el.AangepastStopHitrate =
            mod === "multiply"
              ? el.AangepastStopHitrate * 100
              : el.AangepastStopHitrate / 100;
          if (mod === "multiply") {
            el.AangepastStopHitrate = el.AangepastStopHitrate.toFixed(1);
            el.AangepastStopHitrate = parseFloat(el.AangepastStopHitrate, 10);
          }
        }
        if (el.AangepastStukHitrate) {
          el.AangepastStukHitrate =
            mod === "multiply"
              ? el.AangepastStukHitrate * 100
              : el.AangepastStukHitrate / 100;
          if (mod === "multiply") {
            el.AangepastStukHitrate = el.AangepastStukHitrate.toFixed(1);
            el.AangepastStukHitrate = parseFloat(el.AangepastStukHitrate, 10);
          }
        }
      });

      return arr;
    } else {
      return [];
    }
  };

  const postcodeRangesClickHandler = (e) => {
    const node = e.target;

    if (document.selection) {
      let range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      let range = document.createRange();
      range.selectNodeContents(node);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }

    document.execCommand("copy");

    setCopyTooltipMessage(UIText.COPY_TOOLTIP2);
  };

  const updateRouteSubcontractor = async (
    e,
    index,
    row,
    selectedValue = null
  ) => {
    const name = e.target.name;
    const prevFieldValueOnFocus = selectedValue
      ? selectedValue
      : FieldValueOnFocusStart;
    setFieldValueOnFocusStart(null);

    const loaders = document.querySelectorAll(
      "#subContractorFieldsContainer .grid-custom-loader"
    );
    const loader = loaders[index + 2];
    loader.classList.add("visible");

    let AangepastStopHitrate = row.AangepastStopHitrate
      ? row.AangepastStopHitrate.toString()
      : null;
    if (AangepastStopHitrate) {
      AangepastStopHitrate =
        AangepastStopHitrate.indexOf(",") > -1
          ? AangepastStopHitrate.replace(",", ".")
          : AangepastStopHitrate;
      AangepastStopHitrate =
        typeof AangepastStopHitrate === "string"
          ? parseFloat(AangepastStopHitrate, 10)
          : null;
    }

    let AangepastStukHitrate = row.AangepastStukHitrate
      ? row.AangepastStukHitrate.toString()
      : null;

    if (AangepastStukHitrate) {
      AangepastStukHitrate =
        AangepastStukHitrate.indexOf(",") > -1
          ? AangepastStukHitrate.replace(",", ".")
          : AangepastStukHitrate;
      AangepastStukHitrate =
        typeof AangepastStukHitrate === "string"
          ? parseFloat(AangepastStukHitrate, 10)
          : null;
    }

    row.AangepastStopHitrate = AangepastStopHitrate;
    row.AangepastStukHitrate = AangepastStukHitrate;

    const rowToModify = new Array(row);
    const cloned = JSON.parse(JSON.stringify(rowToModify));
    const dividedRow = modifyPercentages(cloned, "divide");
    const r = dividedRow[0];

    fetch(configSettings.UpdateRouteSubcontractorUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: r.Id,
        RitId: r.RitId,
        ContractSubcontractorId: r.ContractSubcontractorId,
        RitName: r.RitName,
        Sortorder: r.Sortorder,
        PostcodeRanges: r.PostcodeRanges,
        Stopkosten: r.Stopkosten,
        Stoptariefindicatie: r.Stoptariefindicatie,
        StopHitrate: r.StopHitrate,
        StukHitrate: r.StukHitrate,
        AangepastStopHitrate: r.AangepastStopHitrate,
        AangepastStukHitrate: r.AangepastStukHitrate,
      }),
    })
      .then(function (response) {
        if (response.status !== 200) {
          // make the promise be rejected if we didn't get a 200 response
          throw new Error("Not 200 response");
        } else {
          // go the desired response
          props.updateCategories();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSnackbarAutoClosing(true);
        setErrorSnackbarOpen(true);
        revertValueOnBadRequest(name, index, prevFieldValueOnFocus);
      })
      .finally(() => {
        loader.classList.remove("visible");
      });
  };

  const revertValueOnBadRequest = (name, _index, prevFieldValueOnFocus) => {
    const revertedData = SubcontractorData.map((entry, index) => {
      if (index === _index) {
        switch (name) {
          case "AangepastStopHitrate":
            entry.AangepastStopHitrate = prevFieldValueOnFocus;
            break;
          case "AangepastStukHitrate":
            entry.AangepastStukHitrate = prevFieldValueOnFocus;
            break;
          default:
            break;
        }
      }
      return entry;
    });

    setSubcontractorData(revertedData);
  };

  const updateSubcontractorExplanationCall = async (e, name) => {
    if (ExplanationValueOnFocus !== e.target.value) {
      switch (name) {
        case "ToelichtingAfwijkingStoptariefField":
          setExplanationStopTariefUpdating(true);
          break;
        case "ToelichtingAndereHitrateToegekendField":
          setExplanationHitrateUpdating(true);
          break;
        default:
          break;
      }

      fetch(configSettings.UpdateSubcontractorExplanationUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContractId: props.contractId,
          ToelichtingAfwijkingStoptarief: ExplanationStopTarief,
          ToelichtingAndereHitrateToegekend: ExplanationHitrate
        }),
      })
        .then(function (res) {
          return res.text();
        })
        .then(function (data) {
          const isTrueSet = data === "true";
          if (!isTrueSet) {
            throw new Error(errorSnackbarMsg);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSnackbarAutoClosing(false);
          setErrorSnackbarOpen(true);
        })
        .finally(() => {
          setExplanationStopTariefUpdating(false);
          setExplanationHitrateUpdating(false);
        });
    }
  };

  const handleKeyPress = (e) => {
    // Pressing Enter
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const handleNumericFieldChange = (e, index, row) => {
    const name = e.target.name;
    let value = e.target.value;
    const subcontractorData = [...SubcontractorData];
    let correctedValue;

    if (value !== "") {
      // eslint-disable-next-line
      if (value.match(/^\d+(\,\d{1})?$/)) {
        correctedValue = value;
        focusedFieldValue.current = correctedValue;
      } else {
        correctedValue = focusedFieldValue.current;
      }
    } else if (value === "") {
      correctedValue = "";
      focusedFieldValue.current = correctedValue;
    }

    if (value[value.length - 1] === ",") {
      correctedValue = value;
      focusedFieldValue.current = value;
    }
    subcontractorData[index][name] = correctedValue;

    setSubcontractorData(subcontractorData);
  };

  const onFocusField = (e, index) => {
    e.target.select();
    const name = e.target.name;
    const value = e.target.value;
    //
    setFocusedInputName(name);
    setFocusedIndex(index);
    focusedFieldValue.current = value;
    // Storing the FieldValue before any changes
    // to prevent unnecessary POSTs
    setFieldValueOnFocusStart(value);
  };

  const onBlurField = (e, index, row) => {
    let field;

    setFocusedInputName(null);
    setFocusedIndex(null);

    if (`${FieldValueOnFocusStart}` !== `${focusedFieldValue.current}`) {
      lastBlurredInputName.current = field;
      lastBlurredInputValue.current = focusedFieldValue.current;
      lastBlurredInputIndex.current = index;

      updateRouteSubcontractor(e, index, row);
    }
  };

  return (
    <div id="subContractorSection">
      <div id="subContractorFieldsContainer" className="fields-container">
        <div className="flex">
          <div
            className={`grid-custom-loader explanation-loader ${
              ExplanationStopTariefUpdating ? "visible" : ""
            }`}
          >
            <Icons.Loading />
          </div>

          <div className="flex-column ml05 mt1">
            <label>
              <b>Toelichting afwijking stoptarief</b>
            </label>
            <TextField
              className="big-textbox"
              aria-label="ToelichtingAfwijkingStoptarief"
              variant="outlined"
              name="ToelichtingAfwijkingStoptarief"
              value={ExplanationStopTarief}
              onChange={(e) => setExplanationStopTarief(e.target.value)}
              onFocus={(e) => setExplanationValueOnFocus(e.target.value)}
              onBlur={(e) => updateSubcontractorExplanationCall(e, "ToelichtingAfwijkingStoptariefField")}
              onKeyDown={(e) => handleKeyPress(e)}
              tabIndex={9000}
            />
          </div>
        </div>
        <div
          id="subContractorFieldsHeader"
          className="fields-header flex"
          style={{ marginTop: 0 }}
        >
          <div className="normalinputs flex">
            <div className="grid-custom-loader">
              <Icons.Loading />
            </div>
            <div className="column-header route-field">Ritnaam</div>
            <div className="column-header ranges-field">Postcode ranges</div>
            <div className="column-header stoptarief-indicatie-field">
              <Tooltip title="" placement="top-end">
                <p>Stoptarief indicatie</p>
              </Tooltip>
            </div>
            {ShowOptionalFields ? (
              <div className="flex">
                <div className="column-header">Stophitrate</div>
                <div className="column-header">Aangepaste stophitrate</div>

                <div className="column-header">Stukhitrate</div>
                <div className="column-header">Aangepaste stukhitrate</div>
              </div>
            ) : null}
          </div>
        </div>

        <div id="subContractorFieldsBody" className="fields-body">
          {SubcontractorData.map((row, index) => {
            return (
              <div key={index} className={`flex contract-details-row row-${0}`}>
                <div className="normalinputs subcontractor flex">
                  <div className="grid-custom-loader">
                    <Icons.Loading />
                  </div>
                  <p className="cdgrid-field route-field">
                    {typeof row.RitName === "string" ? (
                      row.RitName
                    ) : (
                      <Icons.Loading />
                    )}
                  </p>

                  <div className="view-all-field-container field-outline">
                    <Tooltip
                      title={CopyTooltipMessage}
                      placement="top"
                      enterDelay={tooltipMessageLeaveDelay * 2}
                      leaveDelay={tooltipMessageLeaveDelay}
                    >
                      <p
                        className="ranges-field"
                        onMouseLeave={() =>
                          setTimeout(() => {
                            setCopyTooltipMessage(UIText.COPY_TOOLTIP1);
                          }, tooltipMessageLeaveDelay + 200)
                        }
                        onClick={(e) => postcodeRangesClickHandler(e, index)}
                      >
                        {row.PostcodeRanges ? row.PostcodeRanges : ""}
                      </p>
                    </Tooltip>
                    <div
                      className="ranges-field-tooltip"
                      onClick={(e) => postcodeRangesClickHandler(e, index)}
                    >
                      {row.PostcodeRanges && (
                        <Tooltip title={row.PostcodeRanges} placement="top-end">
                          <MoreHoriz />
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <TextField
                    variant="outlined"
                    className="cdgrid-field readonly currency"
                    value={row.Stoptariefindicatie}
                    tabIndex="-1"
                    readOnly
                  />
                  {ShowOptionalFields ? (
                    <div className="flex">
                      <TextField
                        variant="outlined"
                        className="cdgrid-field readonly percentage"
                        value={
                          row.StopHitrate
                            ? row.StopHitrate.toFixed(1)
                                .toString()
                                .replace(".", ",")
                            : null
                        }
                        tabIndex="-1"
                        readOnly
                      />

                      <TextField
                        variant="outlined"
                        className="cdgrid-field percentage"
                        name="AangepastStopHitrate"
                        onBlur={(e) => onBlurField(e, index, row)}
                        onChange={(e) => handleNumericFieldChange(e, index)}
                        onFocus={(e) => onFocusField(e, index)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        value={
                          FocusedInputName === "AangepastStopHitrate" &&
                          index === FocusedIndex
                            ? focusedFieldValue.current
                            : row.AangepastStopHitrate
                            ? row.AangepastStopHitrate.toString().replace(
                                ".",
                                ","
                              )
                            : ""
                        }
                        tabIndex="-1"
                      />

                      <TextField
                        variant="outlined"
                        className="cdgrid-field readonly percentage"
                        value={
                          row.StukHitrate
                            ? row.StukHitrate.toFixed(1)
                                .toString()
                                .replace(".", ",")
                            : null
                        }
                        tabIndex="-1"
                        readOnly
                      />
                      <TextField
                        variant="outlined"
                        className="cdgrid-field percentage"
                        name="AangepastStukHitrate"
                        onBlur={(e) => onBlurField(e, index, row)}
                        onChange={(e) => handleNumericFieldChange(e, index)}
                        onFocus={(e) => onFocusField(e, index)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        value={
                          FocusedInputName === "AangepastStukHitrate" &&
                          index === FocusedIndex
                            ? focusedFieldValue.current
                            : row.AangepastStukHitrate
                            ? row.AangepastStukHitrate.toString().replace(
                                ".",
                                ","
                              )
                            : ""
                        }
                        tabIndex="-1"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        {ShowOptionalFields ? (
          <div className="flex">
            <div
              className={`grid-custom-loader explanation-loader ${
                ExplanationHitrateUpdating ? "visible" : ""
                }`}
            >
              <Icons.Loading />
            </div>
            <div className="flex-column ml05 mt1">
              <label className="textarea-title">
                <b>Toelichting andere hitrate toegekend</b>
              </label>
              <TextField
                className="big-textbox"
                aria-label="ToelichtingAndereHitrateToegekend"
                variant="outlined"
                name="ToelichtingAndereHitrateToegekend"
                value={ExplanationHitrate}
                onChange={(e) => setExplanationHitrate(e.target.value)}
                onFocus={(e) => setExplanationValueOnFocus(e.target.value)}
                onBlur={(e) => updateSubcontractorExplanationCall(e, "ToelichtingAndereHitrateToegekendField")}
                onKeyDown={(e) => handleKeyPress(e)}
                tabIndex={9000}
              />
            </div>
          </div>
        ) : null}
        <Button
          style={{ width: "240px" }}
          className="mt1 primary-btn"
          variant="contained"
          onClick={() => setShowOptionalFields(!ShowOptionalFields)}
        >
          {ShowOptionalFields ? <RemoveCircleOutline /> : <AddCircleOutline />}
          <span className="toggler-text">
            {ShowOptionalFields ? "Verberg" : "Toon"} stop-/stukhitrate
          </span>
        </Button>
      </div>
      {errorSnackbarOpen && (
        <ErrorSnackbar
          open={errorSnackbarOpen}
          errorMessage={errorSnackbarMsg}
          closeErrorSnackbar={() => setErrorSnackbarOpen(false)}
          autoCloseSeconds={IsSnackbarAutoClosing ? 3 : false}
        />
      )}
    </div>
  );
}
